import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import isEmpty from "../../utils/isEmpty";
import LoadingOverlay from "../../Loader";
import { setEmailTemplate } from "../../actions/email";
import { fetchEmailTemplate } from "../../api/email/email";
import config from "../../config/index";
import QRCode from "react-qr-code";
import YesOrNoModal from "../Layout/YesOrNoModal";
import { removeTemplateListAPI } from "../../api/general/general";
import { updateTemplateListAPI } from "../../api/general/general";
import { toastMessageFailure, toastMessageSuccess } from "../../utils/toastMessage";

class TemplateList extends Component {

    state = {
        isLoading: true,
        disable: "",
        yesOrNoModalShow: false,
        isDataLoading: true
    }

    componentDidMount = async () => {

        this.getTemplate()

    }

    componentWillReceiveProps(nextProps) {

        if (this.props.projectChange !== nextProps.projectChange) {
            this.getTemplate()
        }
    }
    getTemplate = () => {
        this.setState({ isDataLoading: true });
        fetchEmailTemplate().then(({ data: { data } }) => {
            this.props.setEmailTemplate(data);
            this.setState({ ...this.state, isDataLoading: false });
        }).catch(e => {
            this.setState({ ...this.state, isDataLoading: false });
        });
    }

    updateTemplateList = async () => {

        // try {
        this.setState((state) => ({ ...state, isLoading: true, disable: "none" }));
        const { data } = await updateTemplateListAPI(this.state.templateID);
        // this.setState({ isLoading: false, disable: "" });
        //#TODO:later we will directly update the redux instead of calling on delete
        fetchEmailTemplate(this.state.emailTemplateID).then(({ data: { data } }) => {
            this.props.setEmailTemplate(data);
        }).catch(e => {
        });

        // toastMessageSuccess("Notification deleted!")
        // }
        // catch (err) {
        //     console.log(err)
        //     if (err?.response?.status === 404) {
        //         localStorage.removeItem("jwtToken")
        //         window.location.replace("/login")
        //     }
        //     this.setState({ isLoading: false, disable: "" });
        //     // toastMessageFailure("Unable to delete notification!")
        // }
    }


    deleteTemplateList = async (templateID) => {

        try {
            this.setState({ isLoading: true, disable: true });
            const { data } = await removeTemplateListAPI(templateID);
            this.props.setEmailTemplate(this.props.allTemplate.filter((item) => item.id !== templateID));
            toastMessageSuccess("Email template deleted successfully")
            this.setState({ isLoading: false, disable: false, yesOrNoModalShow: false });

        } catch (err) {

            if (err?.response?.status === 404) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
                return
            }
            toastMessageFailure("Fail to delete email template")
            this.setState({ isLoading: false, disable: false });
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Template List (This is our Template List Page)</h4>
                                </div>
                            </div>
                        </div>
                        {!isEmpty(this.props.allTemplate) ? (
                            this.props.allTemplate.map((item) => (
                                <div className="row">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                    <dl className="row mb-0">
                                                        <dt className="col-sm-3">Template Name</dt>
                                                        <dd className="col-sm-9">{item.name}</dd>
                                                        <dt className="col-sm-3">Creation Date</dt>
                                                        <dd className="col-sm-9">{moment(item.create_time).format("MMM Do YY")}</dd>
                                                        <dt className="col-sm-3">Scan QR - Mobile View </dt>
                                                        <dd className="col-sm-9">
                                                            <QRCode style={{ color: 'black' }} className="qr-code" fill="#fff" size={100} value={`${config.notificationUrl}/v2/emails/template/html/${item.id}/`}></QRCode>
                                                        </dd>
                                                        <dt className="col-sm-3">Preview on Tab</dt>
                                                        <dd className="col-sm-9">
                                                            <a href={`${config.notificationUrl}/v2/emails/template/html/${item.id}/`} target="_blank">Click here to open in new tab</a>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="col-6">
                                                    <iframe width="500" height="400" src={`${config.notificationUrl}/v2/emails/template/html/${item.id}/`} title="Test"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <button style={{ bottom: "20px", left: "80px" }}
                                            onClick={() => {
                                                this.setState({ ...this.state, yesOrNoModalShow: true, templateId: item.id });
                                            }}
                                            type="button" class={`btn pt-3 position-absolute btn-danger`}>
                                            <i className="bx bx-trash-alt text-white font-size-24" />
                                        </button>
                                        <button
                                            onClick={() => { this.props.history.push(`update-template/${item.id}`) }}
                                            style={{ bottom: "20px", left: "20px" }}
                                            type="button"
                                            class={`btn pt-3 position-absolute btn-success`}>
                                            <i className="bx bxs-edit text-white font-size-24" />
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : this.state.isDataLoading === true ?


                            <LoadingOverlay></LoadingOverlay>
                            : <label className="text-center text-secondary">No template found!</label>}


                    </div>
                </div>
                <YesOrNoModal title="Deactivate User" loading={this.state.isLoading} disable={this.state.disable} msg={'Are you sure you want to remove this Template?'} showYesOrNoModal={this.state.yesOrNoModalShow}
                    onCloseYesOrNoModal={(value) => {
                        if (value === true) {

                            this.deleteTemplateList(this.state.templateId)

                        } else {
                            this.setState({ yesOrNoModalShow: false })
                        }
                    }}
                >
                </YesOrNoModal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({

    auth: state.authReducer,
    allTemplate: state.emailReducer.allTemplate,
    waitingFor: state.commonReducer.waitingFor,
    projectChange: state.projectReducer.projectChange
})

export default connect(mapStateToProps, { fetchEmailTemplate, setEmailTemplate })(TemplateList)
