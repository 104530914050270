import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment"
import isEmpty from "../../../utils/isEmpty"
import LoadingOverlay from "../../../Loader";
import { removeProject } from "../../../api/general/general"
import { fetchAllProjects } from "../../../api/task/task"
import { setAllProjects } from "../../../actions/project"
import { toastMessageFailure, toastMessageSuccess } from "../../../utils/toastMessage";

class ProjectListing extends Component {

    state = {
        isLoading: "",
        disable: ""
    }

    deleteProject = async (projectID) => {

        try {
            this.setState({ isLoading: projectID, disable: "none" });
            const { data } = await removeProject(projectID);
            this.setState({ isLoading: false, disable: "" });
            toastMessageSuccess("Project deleted successfully")
        } catch (err) {
            if (err?.response?.status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            this.setState({ isLoading: false, disable: "" });
            toastMessageFailure("Fail to delete project")
        }
    }
    getProjectList = ()=>{
        this.setState({ isLoading: true });
        fetchAllProjects().then(({ data: { projects } }) => {
            this.props.setAllProjects(projects);
            this.setState({ isLoading: false });
        }).catch(e => {
            this.setState({ isLoading: false });
        });

    }

    componentDidMount = async () => {
        this.getProjectList()
       
    }

    // componentWillReceiveProps(nextProps){
    //     if (this.props.projectChange !== nextProps.projectChange) {

         
    //         this.getProjectList()
    //     }
    // }



    render() {

        return (
            <>
                {this.state.isLoading === true ? <LoadingOverlay /> : null}
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Project List (This is our Project List Page)</h4>
                                </div>
                            </div>
                        </div>
                        {!isEmpty(this.props.allProjects) ? (
                            this.props.allProjects.map((item) => (
                                <div className="row">
                                    <div className="col-xl-12 col-sm-12">
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-xl-3">
                                                    <div className="text-center p-4 border-end">
                                                        <div className="avatar-sm mx-auto mb-3 mt-1">
                                                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                                                                {item.platform_subscriber_entity.substring(0, 2).toUpperCase()}
                                                            </span>
                                                        </div>
                                                        <h5 className="text-truncate pb-1">{item.platform_subscriber_entity}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="p-4 text-center text-xl-start">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div>
                                                                    <p className="text-muted mb-2 text-truncate">Project Key</p>
                                                                    <h5 style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push(`project-notification?projectKey=${item.platform_subscriber_id}`) }}>{item.platform_subscriber_id}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div>
                                                                    <p className="text-muted mb-2 text-truncate">Date</p>
                                                                    <h5>{moment.utc(item.create_time).format("MMM Do YY")}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-40 col-xl-3" style={{ marginTop: '55px' }}>
                                                    
                                                    <button style={{ pointerEvents: this.state.disable, alignSelf: 'center' }} onClick={() => { this.props.history.push(`project-task?projectKey=${item.platform_subscriber_id}`, {projectInfo:item}) }} type="button" class="btn btn-success">View Project Threads</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                {this.props.waitingFor.includes('fetch-project-loader') ? (
                                    <LoadingOverlay></LoadingOverlay>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    allProjects: state.projectReducer.allProjects,
    waitingFor: state.commonReducer.waitingFor,
    projectChange: state.projectReducer.projectChange
})

export default connect(mapStateToProps, { setAllProjects })(ProjectListing)
