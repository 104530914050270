import React, { Component } from "react";

import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { createEmailTemplate } from "../../api/email/email"
import { connect } from 'react-redux';
import { toastMessageSuccess } from "../../utils/toastMessage";

class CreatEmailTemplate extends Component {

    state = {
        templateName: "",
        message: "",
        isLoading: false,
        disable: "",
        selectedFile: null,
        resetDisable: false,
        repeatDisable: true,
        isError: false
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });

    };

    onChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value, [e.target.name.concat('error')]: '' });
    }

    setUpAgain = async () => {
        this.setState({
            templateName: "",
            message: "",
            isLoading: false,
            disable: "",
            selectedFile: null,
            resetDisable: false,
            repeatDisable: true,
            isError: false
        })
    }

    fileData = () => {

        if (this.state.selectedFile) {

            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {this.state.selectedFile.name}</p>
                    <p>File Type: {this.state.selectedFile.type}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4></h4>
                </div>
            );
        }
    };


    createNotification = async () => {
        if (this.state.templateName === '' || this.state.templateName === null) {
            return this.setState({ isError: true, message: 'Template name is required!' });
        }
        if (this.state.selectedFile === '' || this.state.selectedFile === null || this.state.selectedFile === undefined) {
            return this.setState({ isError: true, message: 'Template file is required!' });
        }
        this.setState({ isError: null, message: null, isLoading: true, disable: true, resetDisable: true });

        try {

            var formData = new FormData();

            // Update the formData object
            formData.append("html_template", this.state.selectedFile);
            formData.append("name", this.state.templateName);
            formData.append("user", this.props.auth.user.user_id);
            console.log("form data ->", this.state.selectedFile)
            await createEmailTemplate(formData);//{"name":this.state.templateName,"html_template": this.state.selectedFile,"user": this.props.auth.user.user_id});


            this.setState({ isError: false, isLoading: false, message: "", resetDisable: false, repeatDisable: false, fieldDisable: true });
            toastMessageSuccess("Email template created successfully")

        }
        catch (err) {
            if (err?.response?.status === 401) {

                //
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            else {
                this.setState({ isError: true, isLoading: false, disable: false, resetDisable: false, message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });

            }


        }

    }

    render() {

        return (
        <div className="mb-5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Email (This is our Create Email Template Page)</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Create Email Template</h4>
                                <form>
                                    <div className="row mb-4">
                                        <label htmlFor="notificationname" className="col-form-label col-lg-2">Template Name *</label>
                                        <div className="col-lg-10">
                                            <input disabled={this.state.fieldDisable} value={this.state.templateName} onChange={this.onChange} id="notificationname" name="templateName" type="text" className="form-control" placeholder="Enter template name" />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label htmlFor="notificationname" className="col-form-label col-lg-2">Select Template File *</label>
                                        <div className="col-lg-10">
                                            <input disabled={this.state.fieldDisable} required type="file" className="form-control" onChange={this.onFileChange} />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                        <div className="col-lg-10">
                                            {this.fileData()}
                                        </div>
                                    </div>
                                    {
                                        this.state.isError ?
                                            <div className="row mb-4">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                                <div className="col-lg-10 alert alert-danger">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                            : null
                                    }
                                </form>
                                <div className="row justify-content-end">
                                    <div className="col-lg-10">
                                        <button disabled={this.state.disable} onClick={this.createNotification} type="submit" className="btn btn-primary">

                                            {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                        Create Template</button>

                                        <button disabled={this.state.resetDisable} style={{ marginLeft: '20px' }} onClick={this.setUpAgain} type="submit" className="btn btn-primary">
                                            Reset</button>
                                        <button disabled={this.state.repeatDisable} style={{ marginLeft: '20px' }} onClick={() => {
                                            this.setState({ fieldDisable: true, disable: false, repeatDisable: true })
                                        }} type="submit" className="btn btn-primary">
                                            Repeat</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

}



const mapStateToProps = (state) => ({
    auth: state.authReducer,
    allProjects: state.projectReducer.allProjects,

})

export default connect(mapStateToProps)(CreatEmailTemplate)