
import { toastMessageSuccess, toastMessageFailure } from "../../utils/toastMessage"
import { useState } from "react"


export default function Footer() {
    const [email, setEmail] = useState("")
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        © Nugenesis.
            </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            Design &amp; Develop by Nugenesis
              </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}