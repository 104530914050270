import React, { Component } from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import { fetchNotificationLog } from "../../api/task/task";
import LoadingOverlay from "../../Loader";
import _ from "lodash";



class DonutChartProjectTask extends Component {



    constructor(props) {
        super(props);

        this.state = {

            // series: [],
            // labels: [],
            projects: [],
            options: {
                series: [],
                labels: [],
                chart: {
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }

    componentDidMount() {
        // Fetch Notification Log
        this.setState({ isLoading: true });
        fetchNotificationLog().then(({ data: { data } }) => {
            // this.setState((state) => ({...state, Projects: data}));
            const series = data.projects.map((obj) => {
                return obj.standard_notification_count;
            })
            const labels = data.projects.map((obj) => {
                return obj.platform_subscriber_entity;
            })
            this.setState({ ...this.state, dataArray: data.projects, options: { ...this.state.options, series, labels } })
            this.setState({ isLoading: false });
        }).catch(e => {
            this.setState({ isLoading: false });
        });
    }


    getSeries = (item) => {

        const options = [];
        Object.keys(item).map(key => {
            if (key !== 'platform_subscriber_entity')
                options.push(item[key])
        })
        // options.push(item.notification_count)
        // options.push(item.delete_notification_count)
        // options.push(item.standard_notification_count)


        return options
    }

    getOptions = (item) => {
        const series = [];
        Object.keys(item).map(key => {
            series.push(_.startCase(_.camelCase(key)))
        })
        return series
    }
    render() {
        console.log("XCHart Da",this.state.dataArray,this.props.projectInfo.platform_subscriber_entity)
       
        return (
            <>
                {this.state.isLoading === true ? <LoadingOverlay /> : null}
                <div id="chart">
                    {this.state.dataArray?.map(item => {
                        if(item.platform_subscriber_entity === this.props.projectInfo.platform_subscriber_entity)
                        return <Chart options={{ labels: this.getOptions(item) }} series={this.getSeries(item)} width={600} type="donut" />
                    })}
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    allProjects: state.projectReducer.allProjects,
})

export default connect(mapStateToProps)(DonutChartProjectTask)

// export default DonutChartProjectTask;