import React, { Component } from "react";
import { toastMessageSuccess } from "../../../utils/toastMessage"
import { addProject } from "../../../api/general/general"
import { addNewProject } from "../../../actions/project"
import { connect } from "react-redux";


class CreateProject extends Component {
    
    state = {
        projectName: "",
        isError: false,
        message: "",
        isLoading: false,
        disable: "",
        projectKey: "",
        showKey: false,
        resetDisable: false,
        fieldDisable: false
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }

    submitCreateProject = async (e) => {
        e.preventDefault();
        this.creaProject()
    };

    setUpAgain = async () => {
        this.setState({
            projectName: "",
            isError: false,
            message: "",
            isLoading: false,
            disable: "",
            projectKey: "",
            showKey: false,
            resetDisable: false,
            fieldDisable: false
        })
    }

    creaProject = async () => {

        if (this.state.projectName === '' || this.state.projectName === null) {
            return this.setState({ isError: true, message: 'Project entity required!' });
        }

        this.setState({ isError: null, message: null, isLoading: true, disable: true, resetDisable: true });

        try {
            let { data } = await addProject({ platform_subscriber_entity: this.state.projectName });
            console.log("objectttt",data)
            
            this.setState({ isError: false, isLoading: false, resetDisable: false,repeatDisable: false, message: "", showKey: true, projectKey: data.platform_subscriber_id, fieldDisable: true });
            toastMessageSuccess("Project created successfully")
            this.props.addNewProject(data)
        } catch (err) {
            console.log("Error",err)

            if (err?.response?.status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            } else {
                this.setState({ isError: true, isLoading: false, disable: false, resetDisable: false, message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });
            }
        }
    }

    render() {
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Project Create (This is our Create Project Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Create New Project</h4>
                                    <form>
                                        <div className="row mb-4">
                                            <label className="col-form-label col-lg-2">Project Entity *</label>
                                            <div className="col-lg-10">
                                                <input value={this.state.projectName} disabled={this.state.fieldDisable} onChange={this.onChange} name="projectName" type="text" className="form-control" placeholder="Enter Project Name..." />
                                            </div>
                                        </div>
                                        {
                                            this.state.isError ?
                                                <div className="row mb-4">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-10">
                                                        <div style={{ marginTop: '10px' }} className="alert alert-danger" role="alert">{this.state.message}</div>
                                                    </div>
                                                </div> : null
                                        }
                                        {
                                            this.state.showKey === true ? <div style={{ marginTop: '10px' }} className="row mb-4">
                                                <div className="col-lg-2"></div>
                                                <div className="col-lg-10">
                                                    <div className="alert alert-success" role="alert">Your Project Key:{this.state.projectKey}</div>
                                                </div>
                                            </div>
                                                : null
                                        }
                                    </form>
                                    <div className="row justify-content-end">
                                        <div className="col-lg-10">
                                            <button disabled={this.state.disable} onClick={this.submitCreateProject} type="submit" className="btn btn-primary">
                                                {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Create Project</button>

                                            <button disabled={this.state.resetDisable} style={{ marginLeft: '10px' }} onClick={this.setUpAgain} type="submit" className="btn btn-primary">

                                                Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}






export default connect(null, { addNewProject })(CreateProject);

