
import { GET_ALL_PROJECTS, GET_PROJECT_NOTIFICATIONS, GET_PROJECT_TASK,PORJECT_CHANGE,ADD_NEW_PROJECT ,SET_ROLE_LIST} from '../types/project'


export const setProjectNotificationsByID = notifications => dispatch => {
    dispatch({
        type    :   GET_PROJECT_NOTIFICATIONS,
        payload :   notifications
    })
}


export const setRoleList = roles => dispatch => {
    dispatch({
        type    :   SET_ROLE_LIST,
        payload :   roles
    })
}
export const setAllProjects = projects => dispatch => {
    dispatch({
        type    :   GET_ALL_PROJECTS,
        payload :   projects
    })
}

export const addNewProject = project => dispatch => {
    dispatch({
        type    :   ADD_NEW_PROJECT,
        payload :   project
    })
}

export const setProjectTaskByID = tasks => dispatch => {
    dispatch({
        type    :   GET_PROJECT_TASK,
        payload :   tasks
    })
}

export const selectedProject = value => dispatch => {
   
    dispatch({
        type    :   PORJECT_CHANGE,
        payload :   value
    })
}




