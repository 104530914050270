

import invoke from '../../utils/invoke';
import config from '../../config';


export const createTask = (data) => {

    // data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'api/tasks/create/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}

export const fetchAllProjects = () => {

    // data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: 'v2/projects/',
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}

export const fetchProjectNotificationsByID = (taskId) => {

    // data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `api/tasks/${taskId}/notifications/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
}

export const fetchProjectTaskByID = (projectId, type, page = 1) => {
    // data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/projects/notifications/${type}/?page=${page}`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
}



export const fetchNotificationPreferenceByID = ( id, page = 1) => {
    console.log("fetchNotificationPreferenceByID")
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/notifications/recepient-list/${id}/?page=${page}`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")

        }
    });
}


// Fetch Notification Detail
export const fetchNotificationDetailkByID = ( id => {
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/notifications/detail/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")

        }
    });
})

// Fetch Template Detail
export const fetchTemplateDetailkByID = ( id => {
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/emails/template/get/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
})

// Fetch Notification Log
export const fetchNotificationLog = ( id => {
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/platform-analytics/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
        }
    });
})

// Fetch Alarm List

export const fetchAlarmList = ( projectId => {
    // debugger
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/alarms/list/subs/`,
        // route: `v2/alarms/list/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
})



export const fetchPlatformUserList = ( () => {
    // debugger
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/accounts/users/`,
        // route: `v2/alarms/list/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
})


export const deactivateUser = ( user_id => {
    // debugger
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        route: `v2/accounts/status/${user_id}/inactive/`,
        // route: `v2/alarms/list/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
})



export const changePasswordByAdmin = ( (user_id,data )=> {
    // debugger
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        route: `v2/accounts/change-pass/${user_id}/`,
        data:data,
        // route: `v2/alarms/list/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
})



