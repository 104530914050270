import { createTask } from "../../../api/task/task"
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastMessageSuccess } from "../../../utils/toastMessage";
import { setAllProjects } from "../../../actions/project"
import { fetchAllProjects } from "../../../api/task/task"

class CreateTask extends Component {
    state = {
        taskName: "",
        key: "",
        message: "",
        isLoading: false,
        disable: "",

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })

    }
    componentDidMount = async () => {

        fetchAllProjects().then(({ data:{projects} }) => {
            this.props.setAllProjects(projects);
         }).catch(e => {
        });

    }

    setUpAgain = async () => {
        this.setState({
            taskName: "",
            key: "",
            message: "",
            isLoading: false,
            disable: "",

        })
    }

    createNotification = async () => {
        if (this.state.taskName === '' || this.state.taskName === null) {
            return this.setState({ isError: true, message: 'task name is required!' });
        } else if (this.state.key === '' || this.state.key === null || this.state.key === "select publisher") {
            return this.setState({ isError: true, message: 'publisher key is required!' });
        }

        this.setState({ isError: null, message: null, isLoading: true, disable: "none" });

        try {


            await createTask({
                "platform_subscriber": this.state.key,
                "name": this.state.taskName
            });


            this.setState({ isError: false, isLoading: false, disable: "", message: "" });
            toastMessageSuccess("Task created successfully")

        }
        catch (err) {

            this.setState({ isError: true, isLoading: false, disable: "", message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });
        }

    }

    render() {

        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Notification (This is our Create Task Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Create New Task</h4>
                                    <form>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Task Name</label>
                                            <div className="col-lg-10">
                                                <input value={this.state.taskName} onChange={this.onChange} id="notificationname" name="taskName" type="text" className="form-control" placeholder="Enter task name" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Publisher key</label>
                                            <div className="col-lg-10">
                                                <select value={this.state.key} onChange={this.onChange} name="key" className="form-control">
                                                    <option>select publisher</option>
                                                    {
                                                        this?.props?.allProjects?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            this.state.isError ?
                                                <div className="row mb-4">
                                                    <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                                    <div className="col-lg-10 alert alert-danger">
                                                        {this.state.message}
                                                    </div>
                                                </div>: null
                                        }
                                    </form>
                                    <div className="row justify-content-end">
                                        <div className="col-lg-10">
                                            <button style={{ pointerEvents: this.state.disable }} onClick={this.createNotification} type="submit" className="btn btn-primary">
                                                {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                            Create Task</button>
                                            <button style={{ pointerEvents: this.state.disable, marginLeft: '20px' }} onClick={this.setUpAgain} type="submit" className="btn btn-primary">
                                                Refresh</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}



const mapStateToProps = (state) => ({
    auth: state.authReducer,
    allProjects: state.projectReducer.allProjects,

})

export default connect(mapStateToProps, { setAllProjects })(CreateTask)