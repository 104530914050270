import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastMessageFailure, toastMessageInfo, toastMessageSuccess } from "../../utils/toastMessage";
import { isEmpty } from 'lodash'
import { hideProjectListComponent } from "../../actions/auth"
import Multiselect from 'multiselect-react-dropdown';

import { grantPermission } from "../../api/permission/permission";

class CreateRole extends Component {
    constructor(props) {
        super(props);

        this.state = {
            role_name: "",
            isLoading: false,
            disable: false,
            projectReadPartialAccess: [],
            projectWritePartialAccess: [],
            notificationReadPartialAccess: [],
            notificationWritePartialAccess: [],
            emailReadPartialAccess: [],
            emailWritePartialAccess: [],

            newsReadPartialAccess: [],
            newsWritePartialAccess: [],

            alarmReadPartialAccess: [],
            alarmWritePartialAccess: [],


            displayProjectList1: "",
            displayProjectList2: "",
            displayProjectList3: "",
            displayProjectList4: "",
            displayProjectList5: "",
            displayProjectList6: "",
            displayProjectList7: "",
            displayProjectList8: "",
            displayProjectList9: "",

            displayProjectList10: "",
            displayProjectList11: "",
            displayProjectList12: "",


            displayProjectList13: "",
            displayProjectList14: "",
            displayProjectList15: "",

            showProject: true,
            showNotification: false,
            showEmail: false,
            isError: false,
            message: "",
            partialDisableNotification: false,
            partialDisableEmail: false,
            partialDisableNews: false,
            partialDisableAlarm: false,
            readFullDisable: false,
            writeFullDisable: false,


        }
        this.onSelectProjectWritePartialAccess = this.onSelectProjectWritePartialAccess.bind(this);
        this.onSelectProjectReadPartialAccess = this.onSelectProjectReadPartialAccess.bind(this);
        this.onSelectNotificationReadPartialAccess = this.onSelectNotificationReadPartialAccess.bind(this);
        this.onSelectNotificationWritePartialAccess = this.onSelectNotificationWritePartialAccess.bind(this);
        this.onSelectEmailReadPartialAccess = this.onSelectEmailReadPartialAccess.bind(this);
        this.onSelectEmailWritePartialAccess = this.onSelectEmailWritePartialAccess.bind(this);
        this.onSelectNewsReadPartialAccess = this.onSelectNewsReadPartialAccess.bind(this);
        this.onSelectNewsWritePartialAccess = this.onSelectNewsWritePartialAccess.bind(this);
        this.onSelectAlarmReadPartialAccess = this.onSelectAlarmReadPartialAccess.bind(this);
        this.onSelectAlarmWritePartialAccess = this.onSelectAlarmWritePartialAccess.bind(this);
        this.onRemoveProjectWritePartialAccess = this.onRemoveProjectWritePartialAccess.bind(this);
        this.onRemoveProjectReadPartialAccess = this.onRemoveProjectReadPartialAccess.bind(this);
        this.onRemoveNotificationReadPartialAccess = this.onRemoveNotificationReadPartialAccess.bind(this);
        this.onRemoveNotificationWritePartialAccess = this.onRemoveNotificationWritePartialAccess.bind(this);
        this.onRemoveEmailReadPartialAccess = this.onRemoveEmailReadPartialAccess.bind(this);
        this.onRemoveEmailWritePartialAccess = this.onRemoveEmailWritePartialAccess.bind(this);
        this.onRemoveNewsReadPartialAccess = this.onRemoveNewsReadPartialAccess.bind(this);
        this.onRemoveNewsWritePartialAccess = this.onRemoveNewsWritePartialAccess.bind(this);
        this.onRemoveAlarmReadPartialAccess = this.onRemoveAlarmReadPartialAccess.bind(this);
        this.onRemoveAlarmWritePartialAccess = this.onRemoveAlarmWritePartialAccess.bind(this);
    }

    onChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })

    }
    resetState = () => {
        this.setState({
            role_name: "",
            isLoading: false,
            disable: false,
            projectReadPartialAccess: [],
            projectWritePartialAccess: [],
            notificationReadPartialAccess: [],
            notificationWritePartialAccess: [],
            emailReadPartialAccess: [],
            emailWritePartialAccess: [],

            newsReadPartialAccess: [],
            newsWritePartialAccess: [],

            alarmReadPartialAccess: [],
            alarmWritePartialAccess: [],


            displayProjectList1: "",
            displayProjectList2: "",
            displayProjectList3: "",
            displayProjectList4: "",
            displayProjectList5: "",
            displayProjectList6: "",
            displayProjectList7: "",
            displayProjectList8: "",
            displayProjectList9: "",

            displayProjectList10: "",
            displayProjectList11: "",
            displayProjectList12: "",


            displayProjectList13: "",
            displayProjectList14: "",
            displayProjectList15: "",

            showProject: true,
            showNotification: false,
            showEmail: false,
            isError: false,
            message: "",
            partialDisableNotification: false,
            partialDisableEmail: false,
            partialDisableNews: false,
            partialDisableAlarm: false,
            readFullDisable: false,
            writeFullDisable: false,
        })
    }


    onSelectProjectReadPartialAccess(selectedList, selectedItem) {
        // console.log("selectedList", selectedList)


        this.setState({
            projectReadPartialAccess: selectedList
        })
    }

    onSelectProjectWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            projectWritePartialAccess: selectedList
        })
    }

    onSelectNotificationReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            notificationReadPartialAccess: selectedList
        })
    }
    onSelectNotificationWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            notificationWritePartialAccess: selectedList
        })
    }

    onSelectEmailReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            emailReadPartialAccess: selectedList
        })
    }
    onSelectEmailWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            emailWritePartialAccess: selectedList
        })
    }


    onSelectNewsReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            newsReadPartialAccess: selectedList
        })
    }
    onSelectNewsWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            newsWritePartialAccess: selectedList
        })
    }

    onSelectAlarmReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            alarmReadPartialAccess: selectedList
        })
    }
    onSelectAlarmWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            alarmWritePartialAccess: selectedList
        })
    }








    onRemoveProjectReadPartialAccess(selectedList, selectedItem) {
        // console.log("selectedList", selectedList)

        this.setState({
            projectReadPartialAccess: selectedList
        })
    }

    onRemoveProjectWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            projectWritePartialAccess: selectedList
        })
    }

    onRemoveNotificationReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            notificationReadPartialAccess: selectedList
        })
    }
    onRemoveNotificationWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            notificationWritePartialAccess: selectedList
        })
    }

    onRemoveEmailReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            emailReadPartialAccess: selectedList
        })
    }
    onRemoveEmailWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            emailWritePartialAccess: selectedList
        })
    }

    onRemoveNewsReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            newsReadPartialAccess: selectedList
        })
    }
    onRemoveNewsWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            newsWritePartialAccess: selectedList
        })
    }

    onRemoveAlarmReadPartialAccess(selectedList, selectedItem) {

        this.setState({
            alarmReadPartialAccess: selectedList
        })
    }
    onRemoveAlarmWritePartialAccess(selectedList, selectedItem) {
        this.setState({
            alarmWritePartialAccess: selectedList
        })
    }

    async componentDidMount() {
        this.props.hideProjectListComponent(true)
    }

    componentWillUnmount() {
        this.props.hideProjectListComponent(false)

    }
    submitRolePermission = async (e) => {

        e.preventDefault()
        let projectObject, notificationObject, emailObject, newsLetterObject, alarmLetterObject, finalObject

        if (isEmpty(this.state.role_name)) {
            this.setState({
                isError: true,
                message: "Please provide the user to whom you want to grant permission"
            })
            return
        }


        if (isEmpty(this.state.displayProjectList1)) {
            this.setState({
                isError: true,
                message: "Please select atleast one option in project permission"
            })

            return
        }

        if (this.state.displayProjectList1 === "PARTIAL_ACCESS") {

            if (this.state.displayProjectList2 !== "FULL_ACCESS" && this.state.displayProjectList3 !== "FULL_ACCESS" && isEmpty(this.state.projectReadPartialAccess) && isEmpty(this.state.projectWritePartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please select at least some permission either read or write or both in case of project partial permission"
                })
                return
            }

        }


        if (this.state.displayProjectList2 === "PARTIAL_ACCESS") {
            if (isEmpty(this.state.projectReadPartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please select project from drop down for read project permission"
                })


                return
            }
        }

        if (this.state.displayProjectList3 === "PARTIAL_ACCESS") {

            if (isEmpty(this.state.projectWritePartialAccess)) {

                this.setState({
                    isError: true,
                    message: "Please select project from drop down for write project permission"
                })
                return
            }
        }






        if (isEmpty(this.state.displayProjectList4)) {
            this.setState({
                isError: true,
                message: "Please select atleast one option in notification permission"
            })
            return
        }

        if (this.state.displayProjectList4 === "PARTIAL_ACCESS") {

            if (this.state.displayProjectList5 !== "FULL_ACCESS" && this.state.displayProjectList6 !== "FULL_ACCESS" && isEmpty(this.state.notificationReadPartialAccess) && isEmpty(this.state.notificationWritePartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please provide at least some permission either read or write or both in case of notification partial permission"
                })
                return
            }

        }

        if (this.state.displayProjectList5 === "PARTIAL_ACCESS") {
            if (isEmpty(this.state.notificationReadPartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please select project from drop down for read notification permission"
                })

                return
            }
        }
        if (this.state.displayProjectList6 === "PARTIAL_ACCESS") {
            if (isEmpty(this.state.notificationWritePartialAccess)) {

                this.setState({
                    isError: true,
                    message: "Please select project from drop down for write notification permission"
                })


                return
            }
        }

        if (isEmpty(this.state.displayProjectList7)) {

            this.setState({
                isError: true,
                message: "Please select atleast one option in email permission"
            })


            return
        }
        if (this.state.displayProjectList7 === "PARTIAL_ACCESS") {

            if (this.state.displayProjectList8 !== "FULL_ACCESS" && this.state.displayProjectList9 !== "FULL_ACCESS" && isEmpty(this.state.emailReadPartialAccess) && isEmpty(this.state.emailWritePartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please provide at least some permission either read or write or both in case of notification partial permission"
                })
                return
            }

        }
        if (this.state.displayProjectList8 === "PARTIAL_ACCESS") {

            if (isEmpty(this.state.emailReadPartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please select project from drop down for read email permission"
                })


                return
            }
        }
        if (this.state.displayProjectList9 === "PARTIAL_ACCESS") {
            if (isEmpty(this.state.emailWritePartialAccess)) {

                this.setState({
                    isError: true,
                    message: "Please select project from drop down for write email  permission"
                })


                return
            }
        }



        if (isEmpty(this.state.displayProjectList10)) {

            this.setState({
                isError: true,
                message: "Please select atleast one option in new letter permission"
            })


            return
        }

        if (this.state.displayProjectList10 === "PARTIAL_ACCESS") {

            if (this.state.displayProjectList11 !== "FULL_ACCESS" && this.state.displayProjectList12 !== "FULL_ACCESS" && isEmpty(this.state.newsReadPartialAccess) && isEmpty(this.state.newsWritePartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please provide at least some permission either read or write or both in case of news letter partial permission"
                })
                return
            }

        }
        if (this.state.displayProjectList11 === "PARTIAL_ACCESS") {

            if (isEmpty(this.state.newsReadPartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please select project from drop down for read news letter permission"
                })


                return
            }
        }
        if (this.state.displayProjectList12 === "PARTIAL_ACCESS") {
            if (isEmpty(this.state.newsWritePartialAccess)) {

                this.setState({
                    isError: true,
                    message: "Please select project from drop down for write news letter  permission"
                })


                return
            }
        }


        if (isEmpty(this.state.displayProjectList13)) {

            this.setState({
                isError: true,
                message: "Please select atleast one option in alarm permission"
            })


            return
        }


        if (this.state.displayProjectList13 === "PARTIAL_ACCESS") {

            if (this.state.displayProjectList14 !== "FULL_ACCESS" && this.state.displayProjectList15 !== "FULL_ACCESS" && isEmpty(this.state.alarmReadPartialAccess) && isEmpty(this.state.alarmWritePartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please provide at least some permission either read or write or both in case of alarm partial permission"
                })
                return
            }

        }
        if (this.state.displayProjectList14 === "PARTIAL_ACCESS") {

            if (isEmpty(this.state.alarmReadPartialAccess)) {
                this.setState({
                    isError: true,
                    message: "Please select project from drop down for read alarm permission"
                })


                return
            }
        }
        if (this.state.displayProjectList15 === "PARTIAL_ACCESS") {
            if (isEmpty(this.state.alarmWritePartialAccess)) {

                this.setState({
                    isError: true,
                    message: "Please select project from drop down for write alarm  permission"
                })


                return
            }
        }





        let projectReadPermissionIDS = [], projectWritePermissionIDS = []
        let notificationReadPermissionIDS = [], notificationWritePermissionIDS = []
        let emailReadPermissionIDS = [], emailWritePermissionIDS = []

        let newsReadPermissionIDS = [], newsWritePermissionIDS = []

        let alarmReadPermissionIDS = [], alarmWritePermissionIDS = []



        if (this.state.projectReadPartialAccess.length > 0) {
            for (var i = 0; i < this.state.projectReadPartialAccess.length; i++) {
                projectReadPermissionIDS.push(this.state.projectReadPartialAccess[i].platform_subscriber_id)
            }
        }
        if (this.state.projectWritePartialAccess.length > 0) {
            for (var i = 0; i < this.state.projectWritePartialAccess.length; i++) {
                projectWritePermissionIDS.push(this.state.projectWritePartialAccess[i].platform_subscriber_id)
            }
        }
        if (this.state.notificationReadPartialAccess.length > 0) {
            for (var i = 0; i < this.state.notificationReadPartialAccess.length; i++) {
                notificationReadPermissionIDS.push(this.state.notificationReadPartialAccess[i].platform_subscriber_id)
            }
        }
        if (this.state.notificationWritePartialAccess.length > 0) {
            for (var i = 0; i < this.state.notificationWritePartialAccess.length; i++) {
                notificationWritePermissionIDS.push(this.state.notificationWritePartialAccess[i].platform_subscriber_id)
            }
        }

        if (this.state.emailReadPartialAccess.length > 0) {
            for (var i = 0; i < this.state.emailReadPartialAccess.length; i++) {
                emailReadPermissionIDS.push(this.state.emailReadPartialAccess[i].platform_subscriber_id)
            }
        }
        if (this.state.emailWritePartialAccess.length > 0) {
            for (var i = 0; i < this.state.emailWritePartialAccess.length; i++) {
                emailWritePermissionIDS.push(this.state.emailWritePartialAccess[i].platform_subscriber_id)
            }
        }

        if (this.state.newsReadPartialAccess.length > 0) {
            for (var i = 0; i < this.state.newsReadPartialAccess.length; i++) {
                newsReadPermissionIDS.push(this.state.newsReadPartialAccess[i].platform_subscriber_id)
            }
        }
        if (this.state.newsWritePartialAccess.length > 0) {
            for (var i = 0; i < this.state.newsWritePartialAccess.length; i++) {
                newsWritePermissionIDS.push(this.state.newsWritePartialAccess[i].platform_subscriber_id)
            }
        }

        if (this.state.alarmReadPartialAccess.length > 0) {
            for (var i = 0; i < this.state.alarmReadPartialAccess.length; i++) {
                alarmReadPermissionIDS.push(this.state.alarmReadPartialAccess[i].platform_subscriber_id)
            }
        }
        if (this.state.alarmWritePartialAccess.length > 0) {
            for (var i = 0; i < this.state.alarmWritePartialAccess.length; i++) {
                alarmWritePermissionIDS.push(this.state.alarmWritePartialAccess[i].platform_subscriber_id)
            }
        }


        // permission project object
        projectObject = {
            read: {
                params: projectReadPermissionIDS,
                access_level: this.state.displayProjectList2
            },
            write: {
                params: projectWritePermissionIDS,
                access_level: this.state.displayProjectList3
            },
            access_level: this.state.displayProjectList1
        }
        // permission notification object
        notificationObject = {
            read: {
                params: notificationReadPermissionIDS,
                access_level: this.state.displayProjectList5
            },
            write: {
                params: notificationWritePermissionIDS,
                access_level: this.state.displayProjectList6
            },
            access_level: this.state.displayProjectList4
        }

        // permission email object
        emailObject = {
            read: {
                params: emailReadPermissionIDS,
                access_level: this.state.displayProjectList8
            },
            write: {
                params: emailWritePermissionIDS,
                access_level: this.state.displayProjectList9
            },
            access_level: this.state.displayProjectList7
        }

        newsLetterObject = {
            read: {
                params: newsReadPermissionIDS,
                access_level: this.state.displayProjectList11
            },
            write: {
                params: newsWritePermissionIDS,
                access_level: this.state.displayProjectList12
            },
            access_level: this.state.displayProjectList10
        }


        alarmLetterObject = {
            read: {
                params: alarmReadPermissionIDS,
                access_level: this.state.displayProjectList14
            },
            write: {
                params: alarmWritePermissionIDS,
                access_level: this.state.displayProjectList15
            },
            access_level: this.state.displayProjectList13
        }

        finalObject =
        {
            name: this.state.role_name,
            platform_subscriber_id: localStorage.getItem("key"),
            permissions: {
                project: projectObject,
                notification: notificationObject,
                email: emailObject,
                newsletter: newsLetterObject,
                alarm: alarmLetterObject
            }
        }



        try {



            this.setState({ isLoading: true, disable: true, message: "", isError: false })
            console.log("Final Object", finalObject)
             await grantPermission(finalObject)
            this.setState({ disable: false, isLoading: false, });
            toastMessageSuccess("Created Role successfully")
            projectReadPermissionIDS = []
            projectWritePermissionIDS = []
            notificationReadPermissionIDS = []
            notificationWritePermissionIDS = []
            emailReadPermissionIDS = []
            emailWritePermissionIDS = []
            newsReadPermissionIDS = []
            newsWritePermissionIDS = []
            alarmReadPermissionIDS = []
            alarmWritePermissionIDS = []
            this.resetState()

        }
        catch (err) {
            this.setState({ disable: false, isLoading: false, });
            let message = err?.response?.data ? err.response.data[Object.keys(err.response.data)[0]] : "We are facing some issues to create staff user"
            toastMessageFailure(message)
        }
    }



    render() {
        console.log("darara =>", this.props.allProjects)

        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Create Role (This is our Create Role Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 col-xl-8">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Admin !</h5>
                                                <p>Create Role for User.</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">

                                    <div className="p-2">
                                        <form className="form-horizontal row">
                                            <div className="row mb-4">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">Role *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.onChange} id="notificationname" name="role_name" type="text" value={this.state.role_name} className="form-control" placeholder="Role Name" />
                                                </div>
                                            </div>
                                            <div style={{ pointerEvents: this.state.showProject == false ? "none" : "", background: this.state.showProject == false ? "#cccccc" : "white", opacity: this.state.showProject == false ? 0.4 : 1 }} className="row">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">Projects *</label>
                                                <div className="col-lg-9">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <input type="radio" name="displayProjectList1" onChange={(e) => {

                                                                this.setState({
                                                                    displayProjectList1: e.target.value,
                                                                    projectReadPartialAccess: this.props.allProjects,
                                                                    projectWritePartialAccess: this.props.allProjects,
                                                                    fullDisableEmail: false,
                                                                    fullDisableNotification: false,
                                                                    fullDisableNews: false,
                                                                    fullDisableAlarm: false,
                                                                    partialDisableEmail: false,
                                                                    partialDisableNotification: false,
                                                                    partialDisableNews: false,
                                                                    partialDisableAlarm: false,

                                                                }, () => {

                                                                })
                                                            }} checked={this.state.displayProjectList1 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                            <p className="mb-0 ml-2">Full Access</p>
                                                        </div>
                                                        <div className="d-flex align-items-center ml-5">
                                                            <input type="radio" name="displayProjectList1" onChange={(e) => {

                                                                this.setState({
                                                                    displayProjectList1: e.target.value,
                                                                    projectReadPartialAccess: [],
                                                                    projectWritePartialAccess: [],
                                                                    partialDisableEmail: false,
                                                                    partialDisableNotification: false,
                                                                    partialDisableNews: false,
                                                                    partialDisableAlarm: false,
                                                                    fullDisableEmail: true,
                                                                    fullDisableNotification: true,
                                                                    fullDisableNews: true,
                                                                    fullDisableAlarm: true


                                                                }, () => { })
                                                            }} checked={this.state.displayProjectList1 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.displayProjectList1 === "PARTIAL_ACCESS"
                                                            ?
                                                            <div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">read *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input type="radio" name="displayProjectList2" onChange={(e) => {

                                                                                this.setState({ displayProjectList2: e.target.value, readFullDisable: false }, () => {

                                                                                    this.setState({
                                                                                        projectReadPartialAccess: this.props.allProjects,

                                                                                    }, () => {
                                                                                        console.log("state data", this.state.projectReadPartialAccess)
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList2 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList2" onChange={(e) => {

                                                                                this.setState({ displayProjectList2: e.target.value, projectReadPartialAccess: [], readFullDisable: true }, () => { })
                                                                            }} checked={this.state.displayProjectList2 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList2 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"
                                                                                options={this.props.allProjects}
                                                                                selectedValues={this.state.projectReadPartialAccess}
                                                                                onSelect={this.onSelectProjectReadPartialAccess}
                                                                                onRemove={this.onRemoveProjectReadPartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }

                                                                </div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">write *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input type="radio" name="displayProjectList3" onChange={(e) => {

                                                                                this.setState({ displayProjectList3: e.target.value, writeFullDisable: false }, () => {
                                                                                    this.setState({
                                                                                        projectWritePartialAccess: this.props.allProjects
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList3 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList3" onChange={(e) => {

                                                                                this.setState({ displayProjectList3: e.target.value, projectWritePartialAccess: [], writeFullDisable: true }, () => { })
                                                                            }} checked={this.state.displayProjectList3 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList3 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"
                                                                                options={this.props.allProjects}
                                                                                selectedValues={this.state.projectWritePartialAccess}
                                                                                onSelect={this.onSelectProjectWritePartialAccess}
                                                                                onRemove={this.onRemoveProjectWritePartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div style={{ background: this.state.showProject == false ? "#cccccc" : "white", opacity: this.state.showProject == false ? 0.4 : 1 }} className="row">
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">Notification *</label>
                                                <div className="col-lg-9">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <input disabled={this.state.fullDisableNotification} type="radio" name="displayProjectList4" onChange={(e) => {

                                                                this.setState({ displayProjectList4: e.target.value, displayProjectList5: e.target.value, displayProjectList6: e.target.value, notificationReadPartialAccess: this.state.projectReadPartialAccess, notificationWritePartialAccess: this.state.projectWritePartialAccess }, () => {

                                                                })
                                                            }} checked={this.state.displayProjectList4 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                            <p className="mb-0 ml-2">Full Access</p>
                                                        </div>
                                                        <div className="d-flex align-items-center ml-5">
                                                            <input disabled={this.state.partialDisableNotification} type="radio" name="displayProjectList4" onChange={(e) => {

                                                                this.setState({ displayProjectList4: e.target.value }, () => {
                                                                    this.setState({
                                                                        notificationReadPartialAccess: [],
                                                                        notificationWritePartialAccess: []
                                                                    })
                                                                })
                                                            }} checked={this.state.displayProjectList4 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.displayProjectList4 === "PARTIAL_ACCESS"
                                                            ?
                                                            <div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">read *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.readFullDisable} type="radio" name="displayProjectList5" onChange={(e) => {

                                                                                this.setState({ displayProjectList5: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        notificationReadPartialAccess: this.state.projectReadPartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList5 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList5" onChange={(e) => {

                                                                                this.setState({ displayProjectList5: e.target.value, notificationReadPartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList5 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList5 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"
                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectReadPartialAccess}
                                                                                selectedValues={this.state.notificationReadPartialAccess}
                                                                                onSelect={this.onSelectNotificationReadPartialAccess}
                                                                                onRemove={this.onRemoveNotificationReadPartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">write *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.writeFullDisable} type="radio" name="displayProjectList6" onChange={(e) => {

                                                                                this.setState({ displayProjectList6: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        notificationWritePartialAccess: this.state.projectWritePartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList6 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList6" onChange={(e) => {
                                                                                this.setState({ displayProjectList6: e.target.value, newsWritePartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList6 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList6 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"
                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectWritePartialAccess}
                                                                                selectedValues={this.state.notificationWritePartialAccess}
                                                                                onSelect={this.onSelectNotificationWritePartialAccess}
                                                                                onRemove={this.onRemoveNotificationWritePartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>


                                            <div className="row">
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                            </div>




                                            <div className="row mt-4">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">Emails *</label>
                                                <div className="col-lg-9">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <input disabled={this.state.fullDisableEmail} type="radio" name="displayProjectList7" onChange={(e) => {

                                                                this.setState({ displayProjectList7: e.target.value, displayProjectList8: e.target.value, displayProjectList9: e.target.value, emailReadPartialAccess: this.state.projectReadPartialAccess, emailWritePartialAccess: this.state.projectWritePartialAccess }, () => {


                                                                })
                                                            }} checked={this.state.displayProjectList7 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                            <p className="mb-0 ml-2">Full Access</p>
                                                        </div>
                                                        <div className="d-flex align-items-center ml-5">
                                                            <input disabled={this.state.partialDisableEmail} type="radio" name="displayProjectList7" onChange={(e) => {

                                                                this.setState({ displayProjectList7: e.target.value }, () => {


                                                                    this.setState({
                                                                        emailReadPartialAccess: [],
                                                                        emailWritePartialAccess: []
                                                                    })


                                                                })
                                                            }} checked={this.state.displayProjectList7 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.displayProjectList7 === "PARTIAL_ACCESS"
                                                            ?
                                                            <div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">read *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.readFullDisable} type="radio" name="displayProjectList8" onChange={(e) => {

                                                                                this.setState({ displayProjectList8: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        emailReadPartialAccess: this.state.projectReadPartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList8 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList8" onChange={(e) => {

                                                                                this.setState({ displayProjectList8: e.target.value, emailReadPartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList8 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList8 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"

                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectReadPartialAccess}
                                                                                selectedValues={this.state.emailReadPartialAccess}
                                                                                onSelect={this.onSelectEmailReadPartialAccess}
                                                                                onRemove={this.onRemoveEmailReadPartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">write *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.writeFullDisable} type="radio" name="displayProjectList9" onChange={(e) => {

                                                                                this.setState({ displayProjectList9: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        emailWritePartialAccess: this.state.projectWritePartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList9 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList9" onChange={(e) => {

                                                                                this.setState({ displayProjectList9: e.target.value, emailWritePartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList9 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList9 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"

                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectWritePartialAccess}
                                                                                selectedValues={this.state.emailWritePartialAccess}
                                                                                onSelect={this.onSelectEmailWritePartialAccess}
                                                                                onRemove={this.onRemoveEmailWritePartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>






                                            <div className="row">
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                            </div>



                                            <div className="row mt-4">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">News Letter *</label>
                                                <div className="col-lg-9">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <input disabled={this.state.fullDisableNews} type="radio" name="displayProjectList10" onChange={(e) => {


                                                                this.setState({ displayProjectList10: e.target.value, displayProjectList11: e.target.value, displayProjectList12: e.target.value, newsReadPartialAccess: this.state.projectReadPartialAccess, newsWritePartialAccess: this.state.projectWritePartialAccess }, () => {


                                                                })
                                                            }} checked={this.state.displayProjectList10 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                            <p className="mb-0 ml-2">Full Access</p>
                                                        </div>
                                                        <div className="d-flex align-items-center ml-5">
                                                            <input disabled={this.state.partialDisableNews} type="radio" name="displayProjectList10" onChange={(e) => {

                                                                this.setState({ displayProjectList10: e.target.value, newsReadPartialAccess: [], newsWritePartialAccess: [] }, () => {

                                                                })
                                                            }} checked={this.state.displayProjectList10 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.displayProjectList10 === "PARTIAL_ACCESS"
                                                            ?
                                                            <div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">read *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.readFullDisable} type="radio" name="displayProjectList11" onChange={(e) => {

                                                                                this.setState({ displayProjectList11: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        newsReadPartialAccess: this.state.projectReadPartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList11 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList11" onChange={(e) => {

                                                                                this.setState({ displayProjectList11: e.target.value, newsReadPartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList11 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList11 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"

                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectReadPartialAccess}
                                                                                selectedValues={this.state.newsReadPartialAccess}
                                                                                onSelect={this.onSelectNewsReadPartialAccess}
                                                                                onRemove={this.onRemoveNewsReadPartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">write *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.writeFullDisable} type="radio" name="displayProjectList12" onChange={(e) => {

                                                                                this.setState({ displayProjectList12: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        newsWritePartialAccess: this.state.projectWritePartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList12 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList12" onChange={(e) => {

                                                                                this.setState({ displayProjectList12: e.target.value, newsWritePartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList12 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList12 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"

                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectWritePartialAccess}
                                                                                selectedValues={this.state.newsWritePartialAccess}
                                                                                onSelect={this.onSelectNewsWritePartialAccess}
                                                                                onRemove={this.onRemoveNewsWritePartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>











                                            <div className="row">
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                            </div>







                                            <div className="row mt-4">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">Alarm *</label>
                                                <div className="col-lg-9">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <input disabled={this.state.fullDisableAlarm} type="radio" name="displayProjectList13" onChange={(e) => {
                                                                console.log("this.state.projectReadPartialAccess", this.state.projectReadPartialAccess)

                                                                this.setState({ displayProjectList13: e.target.value, displayProjectList14: e.target.value, displayProjectList15: e.target.value, alarmReadPartialAccess: this.state.projectReadPartialAccess, alarmWritePartialAccess: this.state.projectWritePartialAccess }, () => {


                                                                })
                                                            }} checked={this.state.displayProjectList13 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                            <p className="mb-0 ml-2">Full Access</p>
                                                        </div>
                                                        <div className="d-flex align-items-center ml-5">
                                                            <input disabled={this.state.partialDisableAlarm} type="radio" name="displayProjectList10" onChange={(e) => {

                                                                this.setState({ displayProjectList13: e.target.value, alarmReadPartialAccess: [], alarmWritePartialAccess: [] }, () => {

                                                                })
                                                            }} checked={this.state.displayProjectList13 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.displayProjectList13 === "PARTIAL_ACCESS"
                                                            ?
                                                            <div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">read *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.readFullDisable} type="radio" name="displayProjectList14" onChange={(e) => {

                                                                                this.setState({ displayProjectList14: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        alarmReadPartialAccess: this.state.projectReadPartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList14 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList14" onChange={(e) => {

                                                                                this.setState({ displayProjectList14: e.target.value, alarmReadPartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList14 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList14 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"

                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectReadPartialAccess}
                                                                                selectedValues={this.state.alarmReadPartialAccess}
                                                                                onSelect={this.onSelectAlarmReadPartialAccess}
                                                                                onRemove={this.onRemoveAlarmReadPartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>

                                                                <label htmlFor="notificationname" className="col-form-label col-lg-3">write *</label>
                                                                <div className="col-lg-9">
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <input disabled={this.state.writeFullDisable} type="radio" name="displayProjectList15" onChange={(e) => {

                                                                                this.setState({ displayProjectList15: e.target.value }, () => {
                                                                                    this.setState({
                                                                                        alarmWritePartialAccess: this.state.projectWritePartialAccess
                                                                                    })
                                                                                })
                                                                            }} checked={this.state.displayProjectList15 === "FULL_ACCESS"} value="FULL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Full Access</p>
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-5">
                                                                            <input type="radio" name="displayProjectList15" onChange={(e) => {

                                                                                this.setState({ displayProjectList15: e.target.value, alarmWritePartialAccess: [] }, () => {

                                                                                })
                                                                            }} checked={this.state.displayProjectList15 === "PARTIAL_ACCESS"} value="PARTIAL_ACCESS" />
                                                                            <p className="mb-0 ml-2">Partial Access</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.displayProjectList15 === "PARTIAL_ACCESS"
                                                                            ?

                                                                            <Multiselect
                                                                                placeholder="Select Projects"

                                                                                options={this.state.displayProjectList1 === "FULL_ACCESS" ? this.props.allProjects : this.state.projectWritePartialAccess}
                                                                                selectedValues={this.state.alarmWritePartialAccess}
                                                                                onSelect={this.onSelectAlarmWritePartialAccess}
                                                                                onRemove={this.onRemoveAlarmWritePartialAccess}
                                                                                displayValue="platform_subscriber_entity"
                                                                            />
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>









                                            {
                                                this.state.isError ?
                                                    <div style={{ display: "flex", marginLeft: 4 }} className="row mb-4">
                                                        <label htmlFor="notificationname" className="col-form-label col-lg-12"></label>
                                                        <div className="col-lg-12 alert alert-info">
                                                            {this.state.message}

                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                <div className="mt-3 text-center">
                                                    <button disabled={this.state.disable} onClick={this.submitRolePermission} style={{ marginRight: '10px' }} className="btn btn-primary waves-effect waves-light w-30" type="button">
                                                        {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                        Create Role</button>
                                                </div>

                                                <div className="mt-3 text-center">
                                                    <button onClick={this.resetState} style={{ marginRight: '10px' }} className="btn btn-primary waves-effect waves-light w-30" type="button">

                                                        Reset</button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    allProjects: state.projectReducer.allProjects,
})




export default connect(mapStateToProps, { hideProjectListComponent })(CreateRole)

