import React, { Component } from "react";
import { fetchNotificationPreferenceByID, fetchNotificationDetailkByID } from "../../api/task/task";
import ReactHtmlParser from 'react-html-parser';
import LoadingOverlay from "../../Loader";

class userRecepientNotification extends Component {

    state = {
        notificationData: [],
        notificationDetail: "",
        page: 1,
        isLoading: false,
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetchNotificationDetailkByID(this.props.match.params.notification_id).then(({ data: { data } }) => {
            this.setState((state) => ({ ...state, notificationDetail: data }));
            this.setState({ isLoading: false });
        }).catch(e => {
            this.setState({ isLoading: false });
        });

        fetchNotificationPreferenceByID(this.props.match.params.notification_id).then(({ data: { data } }) => {
            this.setState((state) => ({ ...state, notificationData: data }));
            this.setState({ isLoading: false });
        }).catch(e => {
            this.setState({ isLoading: false });
        });
    }

    nextPage = () => {
        this.setState((state) => ({ ...state, isLoading: true }));
        console.log("abc" , this.state)
        fetchNotificationPreferenceByID(this.props.match.params.notification_id, this.state.page + 1).then(({ data: { data } }) => {
            this.setState((state) => ({ ...state, notificationData: data , page: this.state.page + 1, isLoading: false }));
        }).catch(e => {
            this.setState((state) => ({ ...state, isLoading: false }));
        });
    }

    render() {
        return (
            <>
                {this.state.isLoading === true ? <LoadingOverlay /> : null}
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">This is User Notification Detail</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Sender</th>
                                                        <th>Title</th>
                                                        <th style={{ width: '350px' }}>Message</th>
                                                        <th>Category</th>
                                                        <th>Is Web Push</th>
                                                        <th>Is All User</th>
                                                        <th>Read Duration</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">{this.state.notificationDetail.id}</th>
                                                        <td>{this.state.notificationDetail.sender}</td>
                                                        <td>{this.state.notificationDetail.title}</td>
                                                        <td style={{ width: "100px" }}>{ReactHtmlParser(this.state.notificationDetail.message)}</td>
                                                        <td>{this.state.notificationDetail.category}</td>
                                                        <td>{this.state.notificationDetail.is_web_push ? <i className="bx bx-check-circle text-success font-size-24" /> : <i className="bx bx bx-x-circle text-danger font-size-24" />}</td>
                                                        <td>{this.state.notificationDetail.is_all_user ? <i className="bx bx-check-circle text-success font-size-24" /> : <i className="bx bx bx-x-circle text-danger font-size-24" />}</td>
                                                        <td>{this.state.notificationDetail.read_ttl_duration}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">This is User Notification Prefernces</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Receiver</th>
                                                        <th>User Prefrences Id</th>
                                                        <th>Is Read</th>
                                                        <th>Is Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notificationData?.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <th scope="row">{item.id}</th>
                                                            <td>{item.receiver}</td>
                                                            <td>{item.userpreferences.id}</td>
                                                            <td>{item.userpreferences.is_read ? <i className="bx bx-check-circle text-success font-size-24" /> : <i className="bx bx bx-x-circle text-danger font-size-24" />}</td>
                                                            <td>{item.userpreferences.is_remove ? <i className="bx bx-check-circle text-success font-size-24" /> : <i className="bx bx bx-x-circle text-danger font-size-24" />}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="d-flex justify-content-end">
                                                <span onClick={this.nextPage} className="pointer">Next</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default userRecepientNotification;


