const STAGE = process.env.REACT_APP_STAGE || 'development';
console.log('REACT_APP_STAGE:', STAGE);

let config: any = {
  limit: 20
};

switch (STAGE) {
  case 'development':
    config = {
      ...config,
      //Notification api url
      //notificationUrl: 'http://13.54.106.161:8000',
      // notificationUrl: 'https://dev.notificationapi.nucoin.net.au',
      // // notificationUrl: 'https://notificationapi.nucoin.com.au',
      // socketConnectionUrl: "wss://dev.notificationapi.nucoin.net.au/ws/project",
      // projectId: "334aad815e96893bf25e5d66886e0409"

      notificationUrl: 'https://dev.notificationapi.nucoin.net.au',
      // notificationUrl: 'http://localhost:8000',
      socketConnectionUrl: "wss://dev.notificationapi.nucoin.net.au/ws/project",
      projectId: "2833057052d376adea690da1de7b779f",

    };
    break;
  case 'stage':
    config = {
      ...config,
      //Notification api url
      //notificationUrl: 'http://13.54.106.161:8000',
      notificationUrl: 'https://dev.notificationapi.nucoin.net.au',
      projectID: "92f3838ec2b63d6a91a49efad2cac860"

    };
    break;

  case 'production':
    config = {
      ...config,
      //Notification api url
      notificationUrl: 'https://notificationapi.nucoin.com.au',
      socketConnectionUrl: "wss://notificationapi.nucoin.com.au/ws/project",
      projectID: "330d3b094c81a4dcb195281ed7cd8ac7"
    };
    break;
}
export default config;



