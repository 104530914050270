import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment"
import isEmpty from "../../../utils/isEmpty"
import LoadingOverlay from "../../../Loader";
import { setEmailHost } from "../../../actions/email"
import { emailHostListApi, } from "../../../api/email/email"
import config from "../../../config/index";
import YesOrNoModal from "../../Layout/YesOrNoModal";
import EditRole from "./Modal/EditRole";
import { removeEmailHostAPI } from "../../../api/general/general";
import { getRoleList, deleteRole } from "../../../api/permission/permission"
import { hideProjectListComponent } from "../../../actions/auth"
import { toastMessageFailure, toastMessageInfo, toastMessageSuccess } from "../../../utils/toastMessage";

class RoleList extends Component {

    state = {
        roleID: "",
        isLoading: "",
        disable: "",
        limit: config.limit,
        pageNumber: 1,
        EmailListModalShow: false,
        EditRoleModalShow: false,
        totalPages: 0,
        yesOrNoModalShow: false,
        renderParams: {},
        isLoading: false,
        roleList: [],
        role:null,
        isDataLoading: false
    }

    getRoleList = async () => {
        try {
            this.props.hideProjectListComponent(true)
            this.setState({ isDataLoading: true })
            const { data } = await getRoleList()
            this.setState({ roleList: data.data, isDataLoading: false })
        }
        catch (e) {
            this.setState({ isDataLoading: false })
            toastMessageFailure("Fail to get role list")

        }
    }
    componentDidMount = async () => {
        this.getRoleList()

    }

    componentWillReceiveProps(nextProps) {

        if (this.props.projectChange !== nextProps.projectChange) {

            this.getRoleList()
        }
    }

    deleteRole = async (roleID) => {

        try {
            this.setState({ isLoading: true, disable: true });
            const { data } = await deleteRole(roleID);
            this.setState({
                roleList: this.state.roleList.filter(item => item.id !== roleID)
            });
            toastMessageSuccess("Deleted role successfully")
            this.setState({ isLoading: false, disable: "", yesOrNoModalShow: false });

        }
        catch (err) {

            this.setState({ isLoading: false, disable: "" });
            if (err?.response?.status === 404) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            else {
                toastMessageFailure("Facing some issue to delete role list")
            }
        }

    }

    componentWillUnmount() {
        this.props.hideProjectListComponent(true)

    }

    render() {
        // const { emailHosts } = this.props;
        console.log("data", this.state.roleList)

        return (
            <>
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Role List (This is our Role List Page)</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {!isEmpty(this.state.roleList) ? (
                                this.state.roleList.map((item) => (
                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="font-size-16 mt-2">Role ID : {item.id}</h5>
                                                <p className="text-muted">{item.email}</p>
                                                <div className="text-muted mt-2 row">
                                                    <div className="col-3">
                                                        <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" /> Role Name</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">{item.name}</p>
                                                    </div>
                                                    <div className="col-3">
                                                        {/* <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" />Port</p> */}
                                                    </div>
                                                    <div className="col-9">
                                                        {/* <p className="mb-1">{item.port}</p> */}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4 col-6">
                                                        <div className="mt-3">
                                                            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary" /> Creation Date</h5>
                                                            <p className="text-muted mb-0">{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button style={{ bottom: "20px", right: "20px" }}
                                                    onClick={() => {
                                                        this.setState({ ...this.state, yesOrNoModalShow: true, roleID: item.id });
                                                    }}
                                                    type="button" class={`btn pt-3 position-absolute btn-danger`}>
                                                    <i className="bx bx-trash-alt text-white font-size-24" />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        this.setState({ ...this.state, EditRoleModalShow: true,role:item });
                                                    }}
                                                    style={{ bottom: "20px", right: "80px" }}
                                                    type="button"
                                                    class={`btn pt-3 position-absolute btn-success`}>
                                                    <i className="bx bxs-edit text-white font-size-24" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : this.state.isDataLoading === true ?


                                <LoadingOverlay></LoadingOverlay>
                                : <label className="text-center text-secondary">No Role found!</label>}

                        </div>
                    </div>
                </div>
                <YesOrNoModal loading={this.state.isLoading} disable={this.state.disable} title="Delete Role " msg={'Are you sure you want to remove this role?'} showYesOrNoModal={this.state.yesOrNoModalShow}
                    onCloseYesOrNoModal={(value) => {
                        if (value === true) {

                            this.deleteRole(this.state.roleID)

                        } else {
                            this.setState({ yesOrNoModalShow: false })
                        }
                    }}
                >
                </YesOrNoModal>

                <EditRole
                    showEditRoleModal={this.state.EditRoleModalShow}
                    role={this.state.role}
                    onCloseEditRoleModal={() => {
                        this.setState({
                            EditRoleModalShow: false
                        })
                    }}
                    callback={this.getRoleList}
                >

                </EditRole>


                {/* <AddRole
                    showAddRoleModal={this.state.AddRoleModalShow}
                    user={this.state.itemIndex !== -1 ? this.state.userList[this.state.itemIndex] : {}}
                    onCloseAddRoleModal={() => {
                        this.setState({
                            AddRoleModalShow: false
                        })
                    }}>
                </AddRole> */}


            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    emailHosts: state.emailReducer.emailHosts,
    waitingFor: state.commonReducer.waitingFor,
    projectChange: state.projectReducer.projectChange
})

export default connect(mapStateToProps, { setEmailHost, hideProjectListComponent })(RoleList)
