import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

class LeftSiderBar extends Component {

    render() {
        console.log("props suth",this.props.auth.user)
        return (
            
            
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title" key="t-menu">Menu</li>
                            <li>
                                <Link to="/" className="waves-effect">
                                    <i className="bx bx-home-circle" />
                                    <span key="t-dashboards">Dashboards</span>
                                </Link>
                            </li>
                            <li>
                                <a href="javascript: void(0);" className="has-arrow waves-effect">
                                    <i className="bx bx-layout" />
                                    <span key="t-layouts">Notifications</span>
                                </a>
                                <ul className="sub-menu" aria-expanded="true">
                                    <li>
                                        <Link to="/notification/create-notification" key="t-horizontal">Send Notification</Link>
                                        <Link to="/notification/project-task"  key="t-horizontal">Notification List</Link>
                                    </li>
                                </ul>
                            </li>
                            {
                                    this.props.auth.user.is_admin && 
                            <li>
                                <a href="javascript: void(0);" className="has-arrow waves-effect">
                                    <i className="bx bx-layout" />
                                    <span key="t-layouts">Subscribers</span>
                                </a>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/create-project" key="t-vertical">Register</Link>
                                    </li>
                                    <li>
                                        {/* <Link to="/project-task" key="t-horizontal">Subscriber Notification List</Link> */}
                                    </li>
                                </ul>
                            </li>
                            }
                            <li>
                                <a href="javascript: void(0);" className="has-arrow waves-effect">
                                    <i className="bx bx-task" />
                                    <span key="t-tasks">Emails</span>
                                </a>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/send-email" key="t-notification">Send Email</Link></li>
                                    <li><Link to="/email/register/host" key="t-notification">New Email Host</Link></li>
                                    <li><Link to="/email/host" key="t-notification">Email Host Listing</Link></li>
                                    <li><Link to="/create-email-template" key="t-notification">New Email Template</Link></li>
                                    <li><Link to="/template-list" key="t-notification">Template Listing</Link></li>
                                    <li><Link to="/email-list" key="t-notification">Email Listing</Link></li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript: void(0);" className="has-arrow waves-effect">
                                    <i className="bx bx-task" />
                                    <span key="t-tasks">News Letter</span>
                                </a>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/news-letter/create" key="t-notification">Add News Letter</Link></li>
                                    <li><Link to="/news-letter/newsLetterEmail-list" key="t-notification">News Letter Listing</Link></li>
                                    <li><Link to="/news-letter/subscriber-list" key="t-notification">Subscribers</Link></li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript: void(0);" className="has-arrow waves-effect">
                                    <i className="bx bx-task" />
                                    <span key="t-tasks">Alarms</span>
                                </a>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/alarm/alarm-configuration" key="t-notification">Configure</Link></li>
                                    <li><Link to="/alarm/configure_user-list-for-recevier-alarm" key="t-notification">Configuration Listing</Link></li>
                                    <li><Link to="/alarm/alarm-list" key="t-notification">Historian</Link></li>
                                </ul>
                            </li>
                            {
                                    this.props.auth.user.is_admin && 
                            <li>
                                <a href="javascript: void(0);" className="has-arrow waves-effect">
                                    <i className="bx bx-task" />
                                    <span key="t-tasks">Permission Configuration</span>
                                </a>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/permission/role" key="t-notification">New Role</Link></li>

                                </ul>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/permission/staff-user" key="t-notification">Reg. New User</Link></li>

                                </ul>
                                 <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/permission/role-list" key="t-notification">Role Listing</Link></li>
                                </ul>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/accounts/list" key="t-notification">PLatform Users List</Link></li>
                                </ul>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}




const mapStateToProps = (state) => ({

    auth: state.authReducer,
   
})

export default connect(mapStateToProps, {})(LeftSiderBar)
