import { Component } from "react";
import { fetchAlarmList } from "../../api/task/task";
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import moment from "moment"
import LoadingOverlay from "../../Loader";
import { connect } from "react-redux";
class AlarmList extends Component {

    state = {
        alarmList: [],
        isLoading: false,
        projectID: "",
    }

    getAlarmList = async () => {
        const value = queryString.parse(this.props.location.search)
        this.setState({ ...this.state, isLoading: true, projectID: value.projectKey })
        fetchAlarmList(value.projectKey).then(({ data: { data } }) => {
            console.log("Data", data)
            this.setState({ isLoading: false })
            this.setState((state) => ({ ...state, alarmList: data }));
        }).catch(e => {
            this.setState({ isLoading: false })
        });
        console.log("Bilal", value, this.props)
    }

    componentDidMount() {
        this.getAlarmList()

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.projectChange !== nextProps.projectChange) {
            this.getAlarmList()
        }
    }

    render() {
        console.log("alarmList", this.state.alarmList)
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">This is Alarm List Page</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title</th>
                                                    <th>Message</th>
                                                    <th>Receiver</th>
                                                    <th>Subscriber Id</th>
                                                    <th>Create Time</th>

                                                    {/* <th>Receiver</th> */}
                                                    {/* <th>Sender</th>
                                                        <th>Message</th>
                                                        <th>Category</th>
                                                        <th>Receiver</th>
                                                        <th>Subscriber Id</th>
                                                        <th>Create Time</th>  */}
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {!isEmpty(this.state.alarmList) ? (
                                                    this.state.alarmList.map((item) =>
                                                        <tr key={item.id}>
                                                            <td scope="row">{item.id}</td>
                                                            <td>{item.title}</td>
                                                            <td style={{ maxWidth: "175px" }}>{item.message}</td>
                                                            <td> {item.email_recipient.emails}</td>
                                                            <td>{item.platform_subscriber}</td>
                                                            <td>{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                        </tr>
                                                    ))
                                                    : this.state.isLoading ? (
                                                        <LoadingOverlay></LoadingOverlay>
                                                    ) : (
                                                        <tr className="h4 font-weight-300 text-center">
                                                            <td colspan="5">No data found</td>
                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
   
    projectChange: state.projectReducer.projectChange

})
export default connect(mapStateToProps)(AlarmList)
// export default AlarmConfigList;