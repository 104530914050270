import { GET_ALL_TEMPLATE,SET_LOADER, GET_ALL_EMAIL, TOTAL_EMAIL_COUNT, SET_EMAIL_HOST,GET_ALL_SUBSCRIBER,SET_ALL_SUBSCRIBER,SET_ALL_NEWSLIST } from '../types/email'
import { isEmpty } from 'lodash'
import config from "../config/index"

const initialState = {
    allTemplate: [],
    allEmail: [],
    allSubscriber:[],
    emailHosts: [],
    totalEmail: 0,
    totalPages: 0,
    allNewsList:[],
    reduxLoader:false
}

export const emailReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_TEMPLATE:
            return {
                ...state,
                allTemplate: action.payload,
            }
        case GET_ALL_EMAIL:
            return {
                ...state,
                allEmail: action.payload,

            }
        case SET_EMAIL_HOST:
            return {
                ...state,
                emailHosts: action.payload,
            }
        case TOTAL_EMAIL_COUNT:
            return {
                ...state,
                totalEmail: action.payload,
                totalPages: Math.ceil(action.payload / config.limit)
            }

        case SET_ALL_SUBSCRIBER:
            console.log("payload allSubscriber",action.payload)
            return {
                ...state,
                allSubscriber: action.payload,

            }
            case SET_ALL_NEWSLIST:
                console.log("payload action.payload",action.payload)
                return {
                    ...state,
                    allNewsList: action.payload,
    
                }

                case SET_LOADER:
               
                return {
                    ...state,
                    reduxLoader: action.payload,
    
                }
               
        default:
            return state
    }
}

