import React, { Component } from "react";
import { fetchAlarmConfigList, removeAlarmUser } from "../../api/alarm/alarm";
import { isEmpty } from 'lodash'
import moment from "moment";
import { connect } from "react-redux";
import LoadingOverlay from "../../Loader";
import { toastMessageSuccess, toastMessageFailure } from "../../utils/toastMessage";
import AlarmConfigurationModal from "../Layout/Modal/AlarmConfigurationModal/AlarmConfigurationModal";
class AlarmConfigList extends Component {

    state = {
        data: {},
        alarmConfigList: [],
        isLoading: false,
        isDeleteLoading: false,
        disable: false,
        key: ""
    }
    removeUser = async (id) => {
        this.setState({ isDeleteLoading: true, disable: true })
        try {
            await removeAlarmUser(id)
            this.setState({
                isDeleteLoading: false, disable: false
            })
            this.setState({
                alarmConfigList: this.state.alarmConfigList.filter((item) => item.id !== id),
            })
            toastMessageSuccess("User remove successfully ")
        }
        catch (e) {
            this.setState({
                isDeleteLoading: false, disable: false
            })
            toastMessageFailure("Fail to remove user")
        }
    }

    onChangePublisher = (e) => {
        // debugger
        this.setState({
            [e.target.name]: e.target.value,
        })
        this.fetchList()
        console.log("PublisherId", e.target.value)
    }

    fetchList = () => {
        //debugger
        this.setState({ isLoading: true })
        fetchAlarmConfigList(this.props.match.params.notification_id, this.props.allProjects.platform_subscriber_id).then(({ data: { data } }) => {

            this.setState({ isLoading: false })
            this.setState((state) => ({ ...state, alarmConfigList: data }));
        }).catch(e => {

            this.setState({ isLoading: false })
        });
    }


    componentDidMount() {
        this.fetchList()

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.projectChange !== nextProps.projectChange) {
            this.fetchList()
        }
    }

    render() {

        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">This is Alarm List Page</h4>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Create New Notification</h4>
                                    <form>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Publisher *</label>
                                            <div className="col-lg-10">

                                                <select disabled={this.state.fieldDisable} value={this.state.key} onChange={this.onChangePublisher} name="key" className="form-control">
                                                    <option>Select Publisher</option>
                                                    {
                                                        this?.props?.allProjects?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Publisher</th>
                                                    <th>Email</th>
                                                    <th>Email Template</th>
                                                    <th>Create Time</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {!isEmpty(this.state.alarmConfigList) ? (
                                                    this.state.alarmConfigList.map((item) =>
                                                        <tr key={item.id}>
                                                            <td scope="row">{item.id}</td>
                                                            <td>{item.platform_subscriber_entity}</td>
                                                            <td style={{ maxWidth: "175px" }}>{item.email_host}</td>
                                                            <td style={{ maxWidth: "175px" }}>{item.html_template}</td>
                                                            <td>{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                            <td>
                                                                <span class="badge badge-dot mr-4">
                                                                    <span class="status">
                                                                        <button disabled={this.state.disable} onClick={() => {
                                                                            this.setState({ ...this.state, data: item }, () => {
                                                                                document.getElementById("alarm-config-btn").click();
                                                                            });
                                                                        }} className="btn btn-primary waves-effect waves-light">
                                                                            {this.state.isDeleteLoading === true ? <LoadingOverlay></LoadingOverlay> : null}
                                                                            View
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    : this.state.isLoading ? (
                                                        <LoadingOverlay></LoadingOverlay>
                                                    ) : (
                                                        <tr className="h4 font-weight-300 text-center">
                                                            <td colspan="5">No data Found</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                        <AlarmConfigurationModal data={this.state.data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    allProjects: state.projectReducer.allProjects,
    projectChange: state.projectReducer.projectChange

})
export default connect(mapStateToProps)(AlarmConfigList)
// export default AlarmConfigList;