import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastMessageSuccess } from "../../utils/toastMessage";
import { getLoginUserDetail } from "../../api/authentication/authentication"
import { setEmailTemplate } from "../../actions/email"
import { fetchEmailTemplate, emailHostListApi, sendNewsLetter } from "../../api/email/email"
import { setEmailHost } from "../../actions/email"
import timeAgo from 'time-ago';
import moment from "moment";
import Select from 'react-select'
import { toast } from "react-toastify";
import { createStaffUser, getRoleList } from "../../api/permission/permission"
import { hideProjectListComponent } from "../../actions/auth"


class CreateStaffUser extends Component {
    state = {
        username: "",
        email: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        password: "",
        re_password: "",
        password_type: true,
        re_password_type: true,
        roleID: "",
        isError: false,
        message: "",
        isLoading: false,
        disable: false,
        roleList: []
    }

    resetState = ()=>{
        this.setState({
           
            roleID: "",
            isError: false,
            message: "",
            isLoading: false,
            disable: false
        })
           

    }
    onChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })

    }
    getRoleList = async () => {
        try {
            this.props.hideProjectListComponent(true)
            const { data } = await getRoleList()

            this.setState({ roleList: data.data })
        }
        catch (e) {

        }

    }
    async componentDidMount() {
        this.getRoleList()

    }

    createStaffUser = async (e) => {

        e.preventDefault()

        if (this.state.username === '' || this.state.username === null) {
            return this.setState({ isError: true, message: "Username is required" });
        }
        if (this.state.first_name === '' || this.state.first_name === null) {
            return this.setState({ isError: true, message: "First name is required" });
        }
        if (this.state.last_name === '' || this.state.last_name === null) {
            return this.setState({ isError: true, message: "Last name is required" });
        }
        if (this.state.email === '' || this.state.email === null) {
            return this.setState({ isError: true, message: "Email is required" });
        }
        if (this.state.password === '' || this.state.password === null) {
            return this.setState({ isError: true, message: "Password is required" });
        }
        if (this.state.re_password !== this.state.password) {
            return this.setState({ isError: true, message: "Password and confirm password not matched" });
        }

        let dataObject =
        {
            role: this.state.roleID,
            username: this.state.username,
            email: this.state.email,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            password: this.state.password,
            re_password: this.state.re_password
        }
       
        try {
            this.setState({ isError: null, message: "", isLoading: true, disable: true });
            const { data } = await createStaffUser(dataObject);
            this.setState({ isError: false, isLoading: false, disable: false, });
            toastMessageSuccess("You successfully created a staff user")
            // this.props.history.push("/permission/grant", { data: data })

        }
        catch (err) {
            this.setState({ isError: true, disable: false, isLoading: false, message: err?.response?.data ? err.response.data[Object.keys(err.response.data)[0]] : "We are facing some issues to create staff user" });
        }
    }

    componentWillUnmount() {
        this.props.hideProjectListComponent(true)
       

    }
    componentWillReceiveProps(nextProps){
        if (this.props.projectChange !== nextProps.projectChange) {

            this.getRoleList()
            this.resetState()
        }
    }



    render() {

        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Create Staff User (This is our Create Staff User Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 col-xl-8">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Admin !</h5>
                                                <p>Create Staff User Here.</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="auth-logo">
                                        <a href="" className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height={34} />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                        <form className="form-horizontal row">
                                            <div className="mb-3 col-6">

                                                <label htmlFor="" className="form-label">Role *</label>
                                                {/* <input id="notificationname" name="role" type="text" className="form-control" placeholder="Role" />
                                             */}
                                                <select onChange={this.onChange} name="roleID" id="taskValue" className="form-control">
                                                    <option>Select Role</option>
                                                    {
                                                        this?.state?.roleList?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option key={key.id} value={key.id} >{key.name}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>


                                            </div>
                                            <div className="mb-3 col-6">

                                                <label htmlFor="" className="form-label">Username *</label>
                                                <input onChange={this.onChange} id="notificationname" name="username" type="text" className="form-control" placeholder="Username" />
                                            </div>

                                            <div className="mb-3 col-6">

                                                <label htmlFor="" className="form-label">First Name *</label>
                                                <input onChange={this.onChange} id="notificationname" name="first_name" type="text" className="form-control" placeholder="First Name" />
                                            </div>

                                            <div className="mb-3 col-6">

                                                <label htmlFor="" className="form-label">Last Name *</label>
                                                <input onChange={this.onChange} id="notificationname" name="last_name" type="text" className="form-control" placeholder="Last Name" />
                                            </div>

                                            <div className="mb-3 col-6">

                                                <label htmlFor="" className="form-label">Middle Name (Optional)</label>
                                                <input onChange={this.onChange} id="notificationname" name="middle_name" type="text" className="form-control" placeholder="Middle Name" />
                                            </div>

                                            <div className="mb-3 col-6">

                                                <label htmlFor="" className="form-label">Email *</label>
                                                <input onChange={this.onChange} id="notificationname" name="email" type="text" className="form-control" placeholder="Email" />
                                            </div>

                                            <div className="mb-3 col-6">
                                                <label className="form-label">Password</label>
                                                <div className="input-group auth-pass-inputgroup">
                                                    <input onChange={this.onChange}
                                                        name="password" type={this.state.password_type ? "password" : "text"} className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" />
                                                    <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline" onClick={() => this.setState({ password_type: !this.state.password_type })} /></button>
                                                </div>
                                            </div>

                                            <div className="mb-3 col-6">
                                                <label className="form-label">Retype Password</label>
                                                <div className="input-group auth-pass-inputgroup">
                                                    <input onChange={this.onChange}
                                                        name="re_password" type={this.state.re_password_type ? "password" : "text"} className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" />
                                                    <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline" onClick={() => this.setState({ re_password_type: !this.state.re_password_type })} /></button>
                                                </div>
                                            </div>

                                            {
                                                this.state.isError ?
                                                    <div style={{ display: "flex", marginLeft: 4 }} className="row mb-4">
                                                        <label htmlFor="notificationname" className="col-form-label col-lg-12"></label>
                                                        <div className="col-lg-12 alert alert-danger">
                                                            {this.state.message}

                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <div className="mt-3 text-center col-12">
                                                <button disabled={this.state.disable} onClick={this.createStaffUser} style={{ marginRight: '10px' }} className="btn btn-primary waves-effect waves-light w-30" type="button">
                                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                    Create Staff User</button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    projectChange: state.projectReducer.projectChange
})

export default connect(mapStateToProps, { hideProjectListComponent })(CreateStaffUser)
