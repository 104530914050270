import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment"
import isEmpty from "../../../utils/isEmpty"
import LoadingOverlay from "../../../Loader";
import { setEmailHost } from "../../../actions/email"

import { emailHostListApi } from "../../../api/email/email";
import config from "../../../config/index";
import YesOrNoModal from "../../Layout/YesOrNoModal";
import { removeEmailHostAPI } from "../../../api/general/general";
import { toastMessageFailure, toastMessageSuccess } from "../../../utils/toastMessage";


class EmailList extends Component {

    state = {
        emailHostID: "",
        isLoading: "",
        disable: false,
        limit: config.limit,
        pageNumber: 1,
        EmailListModalShow: false,
        totalPages: 0,
        yesOrNoModalShow: false,
        renderParams: {},
        isLoading: false,
        isDataLoading: false
    }

     componentDidMount = async () => {
         try {
             const { data } = await emailHostListApi()
             
             this.props.setEmailHost(data.data)
         }
         catch (e) {
             toastMessageFailure("Fail to get email host")
         }


     }


    deleteEmailHost = async (hostID) => {
        try {
            this.setState({ isLoading: true, disable: true });
            const { data } = await removeEmailHostAPI(hostID);
            this.props.setEmailHost(this.props.emailHosts.filter((item) => item.id !== hostID));
            toastMessageSuccess("Email host deleted successfully")
            this.setState({ isLoading: false, disable: false, yesOrNoModalShow: false });
        }
        catch (err) {

            if (err?.response?.status === 404) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
                return
            }
            toastMessageFailure("Fail to delete email host")
            this.setState({ isLoading: false, disable: false });
        }
    }
    // componentWillReceiveProps(nextProps) {

    //     if (nextProps.emailHosts) {

    //         this.setState({ isDataLoading: false })
    //     }


    // }


    render() {
      
        const { emailHosts } = this.props;
        console.log("data -->", emailHosts)

        return (
            <>
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Email Host (This is our Email Host List Page)</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {!isEmpty(emailHosts) ? (
                                emailHosts.map((item) => (
                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="font-size-16 mt-2">Email :</h5>
                                                <p className="text-muted">{item.email}</p>
                                                <div className="text-muted mt-2 row">
                                                    <div className="col-3">
                                                        <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" /> Host</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">{item.host}</p>
                                                    </div>
                                                    <div className="col-3">
                                                        <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" />Port</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">{item.port}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4 col-6">
                                                        <div className="mt-3">
                                                            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary" /> Creation Date</h5>
                                                            <p className="text-muted mb-0">{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button style={{ bottom: "20px", right: "20px" }}
                                                    onClick={() => {
                                                        this.setState({ ...this.state, yesOrNoModalShow: true, notificationId: item.id });
                                                    }}
                                                    type="button" class={`btn pt-3 position-absolute btn-danger`}>
                                                    <i className="bx bx-trash-alt text-white font-size-24" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) :


                                this.props.reduxLoader === true ? <LoadingOverlay></LoadingOverlay> :
                                    <label className="text-center text-secondary">No email hosts found!</label>


                            }





                        </div>
                    </div>
                </div>
                <YesOrNoModal title="Delete Email Host" msg={'Are you sure you want to remove this email host?'} loading={this.state.isLoading} disable={this.state.disable} showYesOrNoModal={this.state.yesOrNoModalShow}
                    onCloseYesOrNoModal={(value) => {
                        if (value === true) {

                            this.deleteEmailHost(this.state.notificationId)

                        } else {
                            this.setState({ yesOrNoModalShow: false })
                        }
                    }}
                >
                </YesOrNoModal>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    emailHosts: state.emailReducer.emailHosts,
    reduxLoader: state.emailReducer.reduxLoader,
    waitingFor: state.commonReducer.waitingFor
})

export default connect(mapStateToProps, { setEmailHost })(EmailList)






