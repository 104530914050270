import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';
import config from '../../config';


export const signup = (data) => {

    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'auth/users/',
        data: data
    });
}

export const login = (data) => {
    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'auth/jwt/create/',
        data: data
    });
}

export const getLoginUserDetail = (data) => {
    //data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: 'v2/accounts/me/',
        headers: {
            Authorization: "JWT" + " " + data
        }
    });
}
