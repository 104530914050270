import { SET_CURRENT_USER, HOME_SCREEN, TEAM_SCREEN,SHOW_PROJCT_LIST_COMPONENT,SET_PLATFORM_USER_LIST } from '../types/auth'
import { isEmpty } from 'lodash'

const initialState = {
    isAuthenticated: false,
    isScreenHome: false,
    isTeamScreen: false,
    user: {},
    isShowProjectListComponent:true,
    plaformUserList:[]
}
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                //#TODO: //To update the Logic for Signout
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            }
        case HOME_SCREEN:
            return {
                ...state,
                isScreenHome: action.payload,

            }
        case TEAM_SCREEN:
            return {
                ...state,
                isTeamScreen: action.payload,

            }
        case SHOW_PROJCT_LIST_COMPONENT:
            return {
                ...state,
                isShowProjectListComponent: action.payload,

            }
        
        case SET_PLATFORM_USER_LIST:
            return {
                ...state,
                plaformUserList: !isEmpty(action.payload),
            }
            

        default:
            return state
    }
}
