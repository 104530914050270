import { SET_CURRENT_USER,SHOW_PROJCT_LIST_COMPONENT,SET_PLATFORM_USER_LIST } from '../types/auth'

//For Login
import jwt_decode from 'jwt-decode'
import { isEmpty } from 'lodash'
import { toastMessageFailure, toastMessageSuccess } from "../utils/toastMessage"
/**
 * Signin User
 * @param userData
 * @returns
 */
// set universal header for token and set current user
export const setUpUser = (accessToken) => async (dispatch) => {
    //setAuthToken(accessToken)
    const decodedUser = jwt_decode(accessToken)
    //Set current User
    dispatch(setCurrentUser(decodedUser))

}
export const hideProjectListComponent = (value) => dispatch => {
    dispatch({
        type    :   SHOW_PROJCT_LIST_COMPONENT,
        payload :   value
    })
}


export const setPlatformUserList = (value) => dispatch => {
    dispatch({
        type    :   SET_PLATFORM_USER_LIST,
        payload :   value
    })
}




export const setCurrentUser = (decodedUser) => {
    //LOGOUT Case
    if (isEmpty(decodedUser)) {
        return {
            type: SET_CURRENT_USER,
            payload: decodedUser,
        }
    }
    //LOGIN Case
    if (!isEmpty(decodedUser)) {
        return {
            type: SET_CURRENT_USER,
            payload: decodedUser,
        }
    }
}
