import React, { Component } from "react";
import Chart from "react-apexcharts";
import { fetchNotificationLog } from "../../api/task/task";
import LoadingOverlay from "../../Loader";

class DonutChartEmail extends Component {



    constructor(props) {
        super(props);

        this.state = {
            emails: [],
            
            options: {
                emailshost: [],
                emailsCount: [],
                chart: {
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }

    componentDidMount() {
        // Fetch Notification Log
        this.setState({ isLoading: true });
        fetchNotificationLog().then(({ data: { data } }) => {
            const emailshost = data.emails.map((obj) => {
                return obj.email_host;
            })
            const emailsCount = data.emails.map((obj) => {
                return obj.email_count;
            })
            this.setState({ ...this.state, options: { ...this.state.options, labels: emailshost, emailsCount } })
            this.setState({ isLoading: false });
        }).catch(e => {
            this.setState({ isLoading: false });
        });
    }



    render() {
        return (
            <>
            {this.state.isLoading === true ? <LoadingOverlay /> : null}
                {/* <span>Email Host : {this.state.options.emailshost}</span><br />
                <span>Email Count : {this.state.options.emailsCount}</span> */}
                <div id="chart">
                    {
                        this.state.options.emailsCount == 0 ? <label style={{ marginTop: '25%', marginLeft: '33%' }} className="text-secondary text-center">No email analytics found!</label> : (
                            <Chart options={this.state.options} series={this.state.options.emailsCount} width={this.state.options.chart.width} height={450} type="donut" />
                        )
                    }
                </div>
            </>
        );
    }
}

export default DonutChartEmail;