import React, { Component } from "react";
import { connect, connectAdvanced } from 'react-redux';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DonutChart from "../Charts/DonutChart";
import DonutChartEmail from "../Charts/DonutChartEmail";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
class Home extends Component {

    render() {
        return (
            // page-content
            <div className="mb-5">
                <div className="container-fluid">
                    {/* start page title */}
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Notification Dashboard</h4>
                                <div className="page-title-right">
                                    {/* <BreadCrumbs /> */}
                                    {/* <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">LogIn</a></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4>Notification Projects Details</h4>
                                    <DonutChart></DonutChart>
                                    {/* <Tabs className="TabsList">
                                        <TabList className="px-3 border-0">
                                            <Tab title={"NuCoin"}>NuCoin</Tab>
                                            <Tab title={"NftCity"}>NftCity</Tab>
                                        </TabList>
                                        <hr />
                                        <TabPanel>
                                            <DonutChart></DonutChart>
                                        </TabPanel>
                                        <TabPanel>
                                            Two Content
                                        </TabPanel>
                                    </Tabs> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card" style={{ minHeight: "530px" }}>
                                <div className="card-body">
                                    <h4>Notification Email Details</h4>
                                    <DonutChartEmail></DonutChartEmail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}



const mapStateToProps = (state) => ({
    auth: state.authReducer,
    allProjects: state.projectReducer.allProjects,
    projectTask: state.projectReducer.projectTask,

})

export default connect(mapStateToProps)(Home)