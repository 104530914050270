import { GET_ALL_PROJECTS, ADD_NEW_PROJECT, GET_PROJECT_NOTIFICATIONS, GET_PROJECT_TASK, PORJECT_CHANGE,SET_ROLE_LIST } from '../types/project'
import { isEmpty } from 'lodash'

const initialState = {
    allProjects: [],
    projectNotifications: [],
    projectTask: [],
    projectChange: "",
    roleList:[]


}
export const projectReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_PROJECTS:
            return {
                ...state,
                allProjects: action.payload,

            }
        case ADD_NEW_PROJECT:
            return {
                ...state,
                allProjects: [...state.allProjects, action.payload],

            }
        case GET_PROJECT_NOTIFICATIONS:
            console.log("reducer data", action.payload)
            return {
                ...state,
                projectNotifications: action.payload,

            }

        case GET_PROJECT_TASK:
            return {
                ...state,
                projectTask: action.payload,

            }
        case PORJECT_CHANGE:
            return {
                ...state,
                projectChange: action.payload,

            }
        case SET_ROLE_LIST:
            return {
                ...state,
                roleList: action.payload,

            }



        default:
            return state
    }
}

