export const GET_ALL_TEMPLATE = 'GET_ALL_TEMPLATE'
export const GET_ALL_EMAIL = "GET_ALL_EMAIL"
export const SET_EMAIL_HOST = "SET_EMAIL_HOST"
export const TOTAL_EMAIL_COUNT = "TOTAL_EMAIL_COUNT"

export const TOTAL_SUBSCRIBER_COUNT = "TOTAL_SUBSCRIBER_COUNT"

export const SET_ALL_NEWSLIST = "SET_ALL_NEWSLIST"
export const SET_ALL_SUBSCRIBER = "SET_ALL_SUBSCRIBER"
export const SET_LOADER = "SET_LOADER"



