import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastMessageSuccess } from "../../utils/toastMessage";
import { getLoginUserDetail } from "../../api/authentication/authentication"
import { setEmailTemplate } from "../../actions/email"
import { isEmpty } from 'lodash'
import { sendEmail, sendEmailToAllUser, fetchEmailTemplate, emailHostListApi } from "../../api/email/email"
import { setEmailHost } from "../../actions/email"


class SendEmail extends Component {
    state = {
        data: {
            email_host: "",
            to_email: "",
            subject: "",
            html_template_id: "",
            is_all_user: false
        },


        sendToAllUsers: false,
        selectedTemplate: "",
        renderParams: [],

        message: "",
        isError: false,
        isLoading: false,
        disable: false,
        fieldDisable: false,
        resetDisable: false,
        repeatDisable: true
    }


    onChange = (e) => {

        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });


    }

    onChangeTemplate = (e) => {

        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });


        if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined && e.target.value !== "Select Email Template") {

            const user = this?.props?.allTemplate.find(u => u.id == e.target.value);

            this.setState({
                selectedTemplate: user
            }, () => {
                this.setState({ renderParams: this.state.selectedTemplate.render_param }, () => {
                    console.log("user", this.state.renderParams)
                })

            });

        }
        else {
            this.setState({ selectedTemplate: "", renderParams: [] })

        }

    }

    componentWillReceiveProps(nextProps) {


        if (this.props.emailHosts.length !== nextProps.emailHosts.length) {
            if (nextProps.emailHosts.length !== 0) this.setState({ ...this.state, data: { ...this.state.data, email_host: nextProps.emailHosts[0].id } });
        }

    }
    setUpAgain = () => {
        this.setState({
            data: {
                to_email: "",
                subject: "",
                html_template_id: "",
                is_all_user: false
            },


            sendToAllUsers: false,
            selectedTemplate: "",
            renderParams: [],

            message: "",
            isError: false,
            isLoading: false,
            disable: false,
            fieldDisable: false,
            resetDisable: false,
            repeatDisable: true
        })
    }

    createNotification = async () => {

        if (this.props.emailHosts.length === 0) {
            return this.setState({ isError: true, message: 'From email field is required!' });
        }

        if ((this.state.data.to_email === '' || this.state.data.to_email === null || this.state.data.to_email === undefined) && this.state.data.is_all_user === false) {

            return this.setState({ isError: true, message: 'To email field is required. Write to email or select all users!' });
        }
        else if (this.state.data.subject === '' || this.state.data.subject === null || this.state.data.subject === undefined) {
            return this.setState({ isError: true, message: 'Subject field is required!' });
        }

        else if (this.state.data.html_template_id === '' || this.state.data.html_template_id === null || this.state.data.html_template_id === undefined || this.state.data.html_template_id === "Select Email Template") {
            return this.setState({ isError: true, message: 'Select email template!' });
        }

        let receiver
        const multilpleReceiver = this.state.data.to_email.includes(",")

        if (multilpleReceiver) {
            receiver = await Promise.all(this.tate.data.to_email.replaceAll('\n', '').split(",").map(_ => _.trim()));
        }
        else if (this.state.data.is_all_user) {

            receiver = "*"
        }
        else {
            receiver = this.state.data.to_email
        }


        this.setState({
            ...this.state, data: {
                ...this.state.data, to_email: receiver, email_host: isEmpty(this.state.data.email_host) ? this.props.emailHosts[0].id : this.state.data.email_host
            }
        }, async () => {
            this.setState({ isError: null, message: null, isLoading: true, disable: true, resetDisable: true });



            try {

                console.log("Email Data is", this.state.data)

                await sendEmail(this.state.data);

                this.setState({ isError: false, isLoading: false, repeatDisable: false, resetDisable: false, message: "", fieldDisable: true }, () => {
                    toastMessageSuccess("Email send successfully")

                });


            }
            catch (err) {
                console.log("eroror", err)

                if (err?.response?.status === 401) {
                    localStorage.removeItem("jwtToken")
                    window.location.replace("/login")
                } else {
                    this.setState({ isError: true, isLoading: false, disable: false, repeatDisable: false, message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });
                }
            }
        });
    }

    render() {
        console.log("emailHosts", this.props.emailHosts)
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Send Email (This is our Send Email Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 col-xl-8">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Admin !</h5>
                                                <p>Compose your Email here.</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="auth-logo">
                                        <a href="" className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height={34} />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                        <form className="form-horizontal row">
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">From Email *</label>
                                                <select className="form-control" name="email_host" value={this.state.data.email_host} onChange={this.onChange}>
                                                    {
                                                        this.props.emailHosts.map(_ => <option key={_.id} value={_.id}>{_.email}</option>)

                                                    }
                                                </select>
                                                {/* <input disabled  value={'no-reply@nucoin.com.au'} onChange={this.onChange} id="notificationname" name="from_email" type="text" className="form-control" /> */}
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">To Email *</label>
                                                <textarea rows={3} disabled={this.state.data.is_all_user || this.state.fieldDisable} onChange={this.onChange} value={this.state.data.to_email} id="notificationname" name="to_email" type="text" className="form-control" placeholder="Enter Receiver Email" />
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">Subject *</label>
                                                <input disabled={this.state.fieldDisable} value={this.state.data.subject} onChange={this.onChange} id="notificationname" name="subject" type="text" className="form-control" placeholder="Enter Email Subject" />
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">Select Template *</label>

                                                <select disabled={this.state.fieldDisable} value={this.state.data.html_template_id} onChange={this.onChangeTemplate.bind(this)} name="html_template_id" id="taskValue" className="form-control">
                                                    <option>Select Email Template</option>
                                                    {
                                                        this?.props?.allTemplate?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option key={key.id} value={key.id} >{key.name}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-check col-12 pl-5 mb-3">
                                                <input className="form-check-input" value={this.state.sendToAllUsers} disabled={this.state.data.to_email || this.state.fieldDisable} type="checkbox" checked={this.state.data.is_all_user} onClick={() => {
                                                    this.setState({ ...this.state, data: { ...this.state.data, is_all_user: !this.state.data.is_all_user } })
                                                }} name="sendToAllUsers" />
                                                <label className="form-check-label" htmlFor="remember-check">
                                                    Send to all users
                                                </label>
                                            </div>
                                            {
                                                this?.state?.renderParams?.map((item, idx) =>

                                                    <div className="mb-3 col-6">
                                                        <label htmlFor="notificationname">{item}</label>
                                                        <input disabled={this.state.fieldDisable} value={this.state.item} onChange={this.onChange} id="notificationname" name={item} type="text" className="form-control" />
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.isError ?
                                                    <div className="row mb-4">
                                                        <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                                        <div className="col-lg-10 alert alert-danger">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <div className="mt-3 text-center col-12">
                                                <button disabled={this.state.disable} onClick={this.createNotification} style={{ marginRight: '10px' }} className="btn btn-primary waves-effect waves-light w-30" type="button">

                                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                    Send Email</button>
                                                <button style={{ marginRight: '10px' }} disabled={this.state.resetDisable} className="btn btn-primary waves-effect waves-light w-30" type="button" onClick={this.setUpAgain}>Reset</button>
                                                <button disabled={this.state.repeatDisable} className="btn btn-primary waves-effect waves-light w-30" type="button" onClick={() => {
                                                    this.setState({
                                                        fieldDisable: false,
                                                        disable: false,
                                                        repeatDisable: true
                                                    })
                                                }}
                                                >Repeat</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}




const mapStateToProps = (state) => ({

    auth: state.authReducer,
    allTemplate: state.emailReducer.allTemplate,
    waitingFor: state.commonReducer.waitingFor,
    emailHosts: state.emailReducer.emailHosts,
})

export default connect(mapStateToProps, { setEmailTemplate, setEmailHost })(SendEmail)
