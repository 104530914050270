import React, { Component } from "react";
import { setProjectTaskByID } from "../../../actions/project";
import { fetchProjectTaskByID } from "../../../api/task/task";
import { connect } from "react-redux";
import isEmpty from "../../../utils/isEmpty"
import LoadingOverlay from "../../../Loader";
import queryString from 'query-string'
import { toastMessageFailure, toastMessageSuccess } from "../../../utils/toastMessage";
import { removeNotificationAPI } from "../../../api/general/general";
import YesOrNoModal from "../../Layout/YesOrNoModal";
import ReactHtmlParser from 'react-html-parser';
import Loader from "../../../layout/Loader/Loader";
import DonutChartProjectTask from "../../Charts/DonutChartProjectTask";

class ProjectTask extends Component {

    constructor(props) {
        super(props);



        this.state = {
            projectID: "",
            isLoading: false,
            disable: "",
            yesOrNoModalShow: false,
            notificationId: 0,
            filterType: 'all',
            notifications: {
                data: [],
                total_page: 1
            },
            page: 1
        }
    }

    componentDidMount() {

        // const value = queryString.parse(this.props.location.search);
        this.getProjectNotification()

    }

    getProjectNotification = () => {
        const value = localStorage.getItem("key")
        this.setState({ ...this.state, isLoading: true, projectID: value.projectKey });

        fetchProjectTaskByID(value.projectKey, this.state.filterType).then(({ data }) => {
            console.log("------------------>", data)
            this.setState({ ...this.state, notifications: data, isLoading: false });
        }).catch(e => {
            toastMessageFailure("Facing some issue to get latest notification")
            this.setState({ ...this.state, isLoading: false });
        });
        console.log("---0", value, this.props)
    }

    nextPage = () => {
        this.setState({ ...this.state, isLoading: true });
        const value = queryString.parse(this.props.location.search);

        if (parseInt(this.state.notifications.data.length / 20) > this.state.page) return this.setState({ ...this.state, page: (this.state.page + 1) });

        fetchProjectTaskByID(value.projectKey, this.state.filterType, this.state.page + 1).then(({ data: { data, total_page } }) => {
            this.setState({ ...this.state, page: this.state.page + 1, isLoading: false, notifications: { data: [...this.state.notifications.data, ...data], total_page } });
        }).catch(e => {
            this.setState({ ...this.state, isLoading: false });
        });
    }

    prevPage = () => {
        // this.setState({ ...this.state, isLoading: true });
        // const value = queryString.parse(this.props.location.search);
        // fetchProjectTaskByID(value.projectKey, this.state.filterType, parseInt(this.props.projectTask.length / 20) + 1).then(({ data: { data } }) => {
        //     this.props.setProjectTaskByID([...this.props.projectTask, ...data]);
        if (this.state.page > 1) this.setState({ ...this.state, isLoading: false, page: this.state.page - 1 });
        // }).catch(e => {
        //     this.setState({ ...this.state, isLoading: false });
        // });
    }

    deleteProjectTask = async (taskID) => {

        try {
            this.setState({ ...this.state, isLoading: taskID, disable: "none" });
            const { data } = await removeNotificationAPI(taskID);
            this.setState({ ...this.state, isLoading: false, disable: "" });
            //#TODO:later we will directly update the redux instead of calling on delete
            fetchProjectTaskByID(this.state.projectID, this.state.filterType).then(({ data: { data } }) => {

                this.props.setProjectTaskByID(data);
            }).catch(e => {
            });

            toastMessageSuccess("Notification deleted!")
        }
        catch (err) {
            if (err?.response?.status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            this.setState({ isLoading: false, disable: "" });
            toastMessageFailure("Unable to delete notification!")
        }
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.projectChange !== nextProps.projectChange) {
          this.getProjectNotification()
        }
    }


    render() {

        const { notifications } = this.state;
        console.log("this.props.location?.state?.projectInfo", this.props.allProjects)

        return (
            <>
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Project Notification List (Contains list of notifications under project)</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <DonutChartProjectTask projectInfo={
                                            this.props.allProjects.length > 0 ?

                                                this.props.allProjects.filter(item => item.platform_subscriber_id === localStorage.getItem("key"))[0] : ""} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="row mt-4">
                                        <div className="col-8"></div>
                                        <div className="col-4">
                                            <div className="row">
                                                <div className="col-6">
                                                    {
                                                        this.state.isLoading && <Loader />
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <select style={{ cursor: 'pointer' }} onChange={e => {
                                                        this.setState({ ...this.state, filterType: e.target.value }, () => {
                                                            this.getProjectNotification();
                                                        })
                                                    }} className="form-control pull-right">
                                                        <option value="all">ALL</option>
                                                        <option value="standard">STANDARD</option>
                                                        <option value="header">HEADER</option>
                                                        <option value="removed">DELETE</option>
                                                        <option value="ttl_absolute">TTL ABSOLUTE</option>
                                                        <option value="ttl_relative">TTL RELATIVE</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Message</th>
                                                        <th>Category</th>
                                                        <th>Web Push</th>
                                                        <th>TO ALL USERS</th>
                                                        <th>Send Time</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!isEmpty(notifications.data) ? (
                                                        notifications.data.slice((this.state.page - 1) * 20, this.state.page * 20).map((item, index) => (
                                                            <tr className={item.is_remove ? 'alert-danger' : ""}>
                                                                <th scope="row">{(index + ((this.state.page - 1) * 20)) + 1}</th>
                                                                <td>{item.title}</td>
                                                                <td style={{ width: "350px" }}>{ReactHtmlParser(item.message)}</td>
                                                                <td>{item.category.toUpperCase()}</td>
                                                                <td>{item.is_web_push ? 'YES' : 'NO'}</td>
                                                                <td>{item.is_all_user ? 'YES' : 'NO'}</td>
                                                                <td>{new Date(item.create_time).toLocaleDateString()} {new Date(item.create_time).toLocaleTimeString()}</td>
                                                                <td>
                                                                    <div className="d-flex ">
                                                                        <button type="button" className="btn btn-primary pt-3" onClick={() => { this.props.history.push(`recipient-list/${item.id}`) }}><i className="bx bx-show text-white font-size-24" /></button>
                                                                        <button style={{ pointerEvents: this.state.disable }} onClick={() => {
                                                                            this.setState({ ...this.state, yesOrNoModalShow: true, notificationId: item.id });
                                                                        }} type="button" className={`btn pt-3 btn-danger ml-2 ${item?.is_remove ? 'disabled' : ''}`}>
                                                                            {this.state.isLoading === item.id ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}

                                                                            <i className="bx bx-trash-alt text-white font-size-24" /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <div style={{ textAlign: 'center' }}>
                                                            {this.props.waitingFor.includes('fetch-task-loader') ? (
                                                                <LoadingOverlay></LoadingOverlay>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </tbody>
                                            </table>
                                            {
                                                notifications.total_page > 1 && (
                                                    <div className="d-flex justify-content-end">
                                                        {
                                                            this.state.page != 1 && (
                                                                <span onClick={this.prevPage} className="mr-5 pointer">Previous</span>
                                                            )
                                                        }
                                                        {
                                                            notifications.total_page !== this.state.page && (
                                                                <span onClick={this.nextPage} className="pointer">Next</span>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {!this.state.isLoading && notifications.data.length === 0 ? <div style={{ textAlign: 'center' }}><h4 className="mt-4">This project currently has no notification</h4></div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <YesOrNoModal msg={'Are you sure you want to remove this notification?'} showYesOrNoModal={this.state.yesOrNoModalShow}
                    onCloseYesOrNoModal={(value) => {
                        if (value === true) {
                            this.setState({ ...this.state, yesOrNoModalShow: false }, () => { this.deleteProjectTask(this.state.notificationId) });
                        } else {
                            this.setState({ ...this.state, yesOrNoModalShow: false })
                        }
                    }}
                >
                </YesOrNoModal>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    projectTask: state.projectReducer.projectTask,
    projectChange: state.projectReducer.projectChange,

    waitingFor: state.commonReducer.waitingFor,
    allProjects: state.projectReducer.allProjects,
});

export default connect(mapStateToProps, { setProjectTaskByID })(ProjectTask);


