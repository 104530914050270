

import invoke from '../../utils/invoke';
import config from '../../config';


export const getAlarmSubscribers = (subscriberId) => {
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: 'v2/alarms/recipient/list/subs/',
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': subscriberId
        }
    });
}

export const createAlarm = (data) => {
    console.log("Bilal" ,data)
    // data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: 'v2/alarms/create/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}

export const alarmConfiguratiom = (data) => {
  
  return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: 'v2/alarms/config/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
}

export const alarmUserRecipient = (data) => {
    return invoke({
          method: 'POST',
          baseURL: config.notificationUrl,
          //baseURL: contactUs,
          route: 'v2/alarms/recipient/',
          data: data,
          headers: {
              Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
          }
      });
  }

export const getAlarmConfigurationsApi = (data) => {
  
  return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: 'v2/alarms/config/list/',
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}

export const fetchAlarmConfigList = ( projectId => {
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/alarms/config/list/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            'platform-subscriber-id': localStorage.getItem("key")
        }
    });
})


export const removeAlarmUser = (id) => {
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        route: `v2/alarms/config/rm/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
        }
    });
}