import React, { Component } from "react";
import { setProjectNotificationsByID } from "../../../actions/project";
import { fetchProjectNotificationsByID } from "../../../api/task/task";

import { connect } from "react-redux";
import isEmpty from "../../../utils/isEmpty"
import LoadingOverlay from "../../../Loader";
import queryString from 'query-string';
import NotificationListModal from "../Modal/NotificationListModal";
import ReactHtmlParser from 'react-html-parser';
import moment from "moment";

class ProjectNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationInitial: [],
            notfication: [],
            name: "readStatus",
            NotificationListModalShow: false,
            text: ""
        }
    }

    componentDidMount() {
        const value = queryString.parse(this.props.location.search);
        fetchProjectNotificationsByID(value.taskKey).then(({ data:{ tasks } }) => {
            this.props.setProjectNotificationsByID(tasks);
       }).catch(e => {
    });
    }


    onChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value }, () => {
            if (e.target.value === "Read") {
                this.setState({ ...this.state, notfication: this.state.notificationInitial }, () => {
                    let filterRead = this.state.notfication.filter(function (item) {
                        return item.is_read === true;
                    })
                    this.setState({ ...this.state, notfication: filterRead });
                })
            } else if (e.target.value === "Unread") {
                this.setState({ ...this.state, notfication: this.state.notificationInitial }, () => {
                    let filterRead = this.state.notfication.filter(function (item) {
                        return item.is_read === false;
                    })
                    this.setState({ ...this.state, notfication: filterRead });
                })
            } else if (e.target.value === "Both") {
                this.setState({ ...this.state, notfication: this.state.notificationInitial });
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({ notfication: nextProps?.projectNotifications, notificationInitial: nextProps?.projectNotifications })
        }
    }

    render() {

        return (
            <>
                <NotificationListModal
                    NotificationListshowModal={this.state.NotificationListModalShow}
                    text={this.state.text}
                    onCloseNotificationListModal={() => {
                            this.setState({ ...this.state, NotificationListModalShow: false, text: "" });
                        }
                    }
                >
                </NotificationListModal>
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0 mb-2">
                                    <div>
                                        <h4 className="mb-sm-0 font-size-18">Notification List (This is our Notification List Page)</h4>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <select onChange={this.onChange} name="readStatus" className="form-control">
                                                    <option value="Both">Sort By Both</option>
                                                    <option value="Read">Read</option>
                                                    <option value="Unread">Unread</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">

                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Sender Name</th>
                                                        <th>Receiver Name</th>
                                                        <th>Message</th>
                                                        <th>Read Status</th>
                                                        <th>Remove Status</th>
                                                        <th>Action</th>
                                                        <th>TTL</th>
                                                        <th>Notification Read Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!isEmpty(this?.state?.notfication) ? (
                                                        this?.state?.notfication.map((item, index) => (
                                                            <tr>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{item.sender}</td>
                                                                <td>{item.receiver}</td>
                                                                {item.message.length < 200 ? <td>{ReactHtmlParser(item.message)}</td> : <td>{ReactHtmlParser(item.message.substring(0, 200))} <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                    this.setState({
                                                                        text: item.message,
                                                                        NotificationListModalShow: true
                                                                    })
                                                                }}>... More</span></td>

                                                                }
                                                                <td>{item.is_read === false ? "Unread" : "Read"}</td>
                                                                <td>{item.is_remove === false ? "False" : "True"}</td>
                                                                <td>{item.action ? item.action : "NULL"}</td>
                                                                <td>{item.ttl ? moment.utc(item.ttl).format("MMM Do YY") : "NULL"}</td>
                                                                <td>{item.read_time ? moment(item.read_time).local().format("dddd, MMMM Do YYYY, h:mm:ss a") : "NULL"}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                            <div style={{ textAlign: 'center' }}>
                                                                {this.props.waitingFor.includes('fetch-notification-loader') ? (
                                                                    <LoadingOverlay></LoadingOverlay>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.waitingFor.length === 0 ? this?.props?.projectNotifications.length === 0 ? <div style={{ textAlign: 'center' }}><h4>This project currently has no notification</h4></div> : null : null}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    projectNotifications: state.projectReducer.projectNotifications,
    waitingFor: state.commonReducer.waitingFor
});

export default connect(mapStateToProps, { setProjectNotificationsByID })(ProjectNotification)