

import JSEncrypt from 'jsencrypt';
import { CERTIFICATE_PUB } from '../security/certificate';
const jsEncrypt = new JSEncrypt();
jsEncrypt.setPublicKey(CERTIFICATE_PUB);



export function encrypt(message) {
  if (typeof message === 'object') return { data: jsEncrypt.encrypt(JSON.stringify(message)) };
  else return jsEncrypt.encrypt(message);
}

