
import { connect } from 'react-redux';
import React, { Component, useEffect } from "react";
import { Link } from 'react-router-dom';
import { setAllProjects, selectedProject } from "../../actions/project";
import { setEmailHost, setEmailTemplate, setLoader } from "../../actions/email";
import { fetchAllProjects } from '../../api/task/task';
import { emailHostListApi, fetchEmailTemplate } from '../../api/email/email';

import YesOrNoModal from "../Modal/YesOrNoModal"
class Header extends Component {

    state = {
        key: "",
        yesOrNoModalShow: false
    }
    LogOut = (code) => {
        if (code === 401 || code === 403) {
            //
            localStorage.removeItem("jwtToken")
            localStorage.removeItem("lastSelectWallet")
            localStorage.setItem("logout", code)
            window.location.replace("/login")
        }
        else {
            localStorage.removeItem("jwtToken")
            localStorage.removeItem("lastSelectWallet")
            localStorage.setItem("logout", 200)
            window.location.replace("/login")
        }
    }
    onChangeProject = (e) => {

        localStorage.setItem(e.target.name, e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
        localStorage.getItem("key")
        //window.location.reload()

        this.props.selectedProject(e.target.value)
        this.setEmailHost()

    }
    componentDidMount = async () => {

        this.setState((state) => ({ ...state, key: localStorage.getItem("key") }))
        fetchAllProjects().then(({ data: { projects } }) => {
            if (!localStorage.getItem("key")) {
                localStorage.setItem("key", projects[0].platform_subscriber_id)
            }
            this.setEmailHost()
            this.props.setAllProjects(projects);
        }).catch(e => {
        });
    }

    setEmailHost = () => {

        if (!localStorage.getItem("jwtToken")) return;
        this.props.setLoader(true);
        emailHostListApi().then(({ data: { data } }) => {
            this.props.setEmailHost(data);
            fetchEmailTemplate().then(({ data: { data } }) => {
                this.props.setEmailTemplate(data);
                this.props.setLoader(false);

            }).catch(e => {

            });

        }).catch(e => {

        });
    }

    render() {
        return (
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            {/* <YesOrNoModal msg={"Do you want to add this project as default for all the operation on system ?"}  showYesOrNoModal={this.state.yesOrNoModalShow}
                                onCloseYesOrNoModal={(value) => {
                                    his.setState({ yesOrNoModalShow: false })
                                    if (value === true) {
                                        this.onChangeProject()
                                    }
                                    else {
                                        this.setState({ yesOrNoModalShow: false })
                                    }
                                }}
                            ></YesOrNoModal> */}
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="/assets/images/logo.svg" alt="" height={22} />
                                </span>
                                <span style={{ marginTop: '10px' }} className="logo-lg">
                                    Notification
                                </span>
                            </Link>
                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img style={{ marginTop: '10px' }} src="/assets/images/nugenesisou-logo.png" alt="" height={22} />
                                </span>
                                <span className="logo-lg">
                                    <img src="/assets/images/nugenesisou-logo.png" alt="" style={{ height: '90px', padding: "5px 0px" }} />
                                </span>
                            </Link>
                        </div>
                        {
                            <form style={{ width: "700px" }}>
                                <div className="row mt-4 ml-3">
                                    <label htmlFor="notificationname" className="col-form-label col-lg-2">Project *</label>
                                    
                                    <div className="col-lg-6">
                                        <select style={{ cursor: 'pointer' }} value={this.state.key} onChange={this.onChangeProject} name="key" className="custom-select">
                                            {
                                                this?.props?.allProjects?.map((key, idx) =>
                                                    <React.Fragment key={idx}>
                                                        <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                    </React.Fragment>
                                                )
                                            }
                                        </select>

                                    </div>
                                </div>
                            </form>
                        }

                    </div>
                    <div className="d-flex">
                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <button type="button" className="btn header-item noti-icon waves-effect" data-bs-toggle="fullscreen">
                                <i className="bx bx-fullscreen" />
                            </button>
                        </div>
                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="rounded-circle header-profile-user" src="/assets/images/users/User.png" alt="Header Avatar" />
                                <span className="d-none d-xl-inline-block ms-1" key="t-henry">{this.props.user.username}</span>
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a onClick={this.LogOut} className="dropdown-item text-danger" href="#"><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> <span key="t-logout">Logout</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}




const mapStateToProps = (state) => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    user: state.authReducer.user,
    isScreenHome: state.authReducer.isScreenHome,
    isShowProjectListComponent: state.authReducer.isShowProjectListComponent,
    allProjects: state.projectReducer.allProjects,
    isTeamScreen: state.authReducer.isTeamScreen

})

export default connect(mapStateToProps, { setAllProjects, setEmailHost, setLoader, setEmailTemplate, selectedProject })(Header);
