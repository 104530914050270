import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode'

import { login, getLoginUserDetail } from '../../../api/authentication/authentication';
import { setUpUser } from '../../../actions/auth';
import { toastMessageFailure, toastMessageSuccess } from "../../../utils/toastMessage"


class Login extends Component {
  state = {
    username: "",
    password: "",
    showVerification: false,
    isError: false,
    message: "",
    isLoading: false,
    disable: "",
    emailOnVerification: "",
    phoneOnVerification: "",
    isResendOtpLoading: false,
    // hide and show password
    passwordType: "password",
    hideAndShowLabelText: "Show"
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name.concat('error')]: '',
    })

  }
  // userLogin = async () => {
  //   alert("call")
  //   try {
  //     if (this.state.username === '' || this.state.username === null) {
  //       return this.setState({ isError: true, message: 'Username required!' });
  //     } else if (this.state.password === '' || this.state.password === null) {
  //       return this.setState({ isError: true, message: 'Password required!' });
  //     }
  //     this.setState({ isError: false, message: "", isLoading: true, disable: "none" });
  //     const { data } = await login({ username: this.state.username, password: this.state.password });



  //   }
  //   catch (e) {

  //     this.setState({
  //       isLoading: false, disable: "",

  //     });
  //     toastMessageFailure("Network problem")
  //   }
  // }

  userLogin = async () => {
    if (this.state.username === '' || this.state.username === null) {
      return this.setState({ isError: true, message: 'Username required!' });
    } else if (this.state.password === '' || this.state.password === null) {
      return this.setState({ isError: true, message: 'Password required!' });
    }
    this.setState({ isError: null, message: null, isLoading: true, disable: "none" });

    try {

      const { data } = await login({ username: this.state.username, password: this.state.password });
      const loginUserDetail = await getLoginUserDetail(data.access)


      this.setState({ isError: false, isLoading: false, disable: "", message: "" });
      if (loginUserDetail?.data?.user?.is_admin === true) {

        localStorage.setItem('jwtToken', data.access)
        window.location.href = "/"
        //this.props.history.push("/");


      }
      else if (
        loginUserDetail?.data?.user?.is_staff === true) {
        localStorage.setItem('jwtToken', data.access)
        window.location.href = "/"
        //this.props.history.push("/");


      }
      else {
        this.setState({
          isError: true, isLoading: false, disable: "",
          message: "Your account is not registered as an admin or staff"
        });

      }
    }
    catch (err) {
      this.setState({ isError: true, isLoading: false, disable: "", 
      message: err?.response?.data?.message ? err?.response?.data?.message : 
      "Sorry! We are facing some network issues. Please try again later." });
    }

  }

  submitLogin = async (e) => {
    e.preventDefault();
    this.userLogin()
  };

  onKeyPress = (e) => {
    const enterOrSpace =
      e.key === "Enter" ||
      e.which === 13
    if (enterOrSpace === true) {
      this.userLogin()
    }
  };
  componentDidMount() {
    this.logOut()
  }

  logOut = async () => {
    let logout = localStorage.getItem("logout")
    if (logout) {
      if (logout === "401" || logout === "403") {
        toastMessageFailure("Your session has been expired please login again")
      }
      else {
        toastMessageSuccess("You have successfully logged out of the system.")
      }
      localStorage.removeItem("logout")
    }
  }

  render() {
    return (
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back!</h5>
                        <p>Sign in to continue to Text Here.</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="/assets/images/logo-light.svg" alt="" className="rounded-circle" height={34} />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height={34} />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form className="form-horizontal" action="https://themesbrand.com/skote-django/layouts/index.html">
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">Username</label>
                        <input
                          onChange={this.onChange}

                          //onKeyPress={this.onKeyPress}
                          name="username" type="text" className="form-control" id="username" placeholder="Enter username" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <div className="input-group auth-pass-inputgroup">
                          <input
                            onChange={this.onChange}
                            //onKeyPress={this.onKeyPress} 

                            name="password" type="password" className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" />
                          <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline" /></button>
                        </div>
                      </div>
                      {/* <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="remember-check" />
                        <label className="form-check-label" htmlFor="remember-check">
                          Remember me
                      </label>
                      </div> */}

                      {
                        this.state.isError ? <div style={{ marginTop: '10px' }} className="alert alert-danger" role="alert">{this.state.message}</div> : null
                      }
                      <div className="mt-3 d-grid">
                        <button style={{ pointerEvents: this.state.disable }} onClick={(event) => this.submitLogin(event)} className="btn btn-primary waves-effect waves-light" type="submit">
                          {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                          Log In</button>
                      </div>
                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a href="javascript::void()" className="social-list-item bg-primary text-white border-primary">
                              <i className="mdi mdi-facebook" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript::void()" className="social-list-item bg-info text-white border-info">
                              <i className="mdi mdi-twitter" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript::void()" className="social-list-item bg-danger text-white border-danger">
                              <i className="mdi mdi-google" />
                            </a>
                          </li>
                        </ul>
                      </div> */}
                      {/* <div className="mt-4 text-center">
                        <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1" /> Forgot your password?</a>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <div>
                  {/* <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Signup now </Link> </p> */}
                  <p>©  Nugenesis. Crafted with <i className="mdi mdi-heart text-danger" /> by Nugenesis</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }

}

export default connect(null, { setUpUser })(Login)
