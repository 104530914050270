// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'


export default class YesOrNoModal extends React.Component {

    closeModal = (value) => {
        this.props.onCloseYesOrNoModal(value)
    }


    render() {
        return (
            <Modal dialogClassName="InfoSuccess text-center"
                show={this.props.showYesOrNoModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/* <DeleteIcon /> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    wordWrap: 'break-word',
                    paddingLeft: '2px',
                    paddingRight: '2px'
                }}>

                    <p>{this.props.msg}</p>
                    <button disabled={this.props.disable} type="button" class="btn btn-secondary Cancel" onClick={() => { this.closeModal(true) }} style={{ marginRight: '10px' }}>
                        {/* {this.props.isLoading === true ? <SpinnerLoader /> : "Yes"} */}
                        Yes

                    </button>
                    <button disabled={this.props.disable} type="button" class="btn btn-secondary Submit " onClick={() => { this.closeModal(false) }}>No</button>
                </Modal.Body>
            </Modal >
        )
    }


}