import { alarmConfiguratiom } from "../../api/alarm/alarm";
import React, { Component } from "react";
import Select from 'react-select';
import { connect } from "react-redux";
import { setEmailTemplate } from "../../actions/email"
import { sendEmail, sendEmailToAllUser, fetchEmailTemplate, emailHostListApi } from "../../api/email/email"
import { setEmailHost } from "../../actions/email"
import { getLoginUserDetail } from "../../api/authentication/authentication"
import { toastMessageSuccess } from "../../utils/toastMessage";

class AlarmConfiguration extends Component {

    state = {
        key: "",
        emailHost: -1,
        emailTemplate: -1,
        message: "",
        isError: false,
        isLoading: false,
        disable: false,
    }

    clearForm = () => {
        this.setState({
            key: "",
            emailHost: -1,
            emailTemplate: -1,
            message: "",
            isError: false,
            isLoading: false,
            disable: false,
        })

    }
    onChangePublisher = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        });
    }


    componentDidMount = async () => {
        try {
            const { data } = await getLoginUserDetail(localStorage.getItem("jwtToken"))
            this.setState({ ...this.state, data: { from_email: data?.user?.email } });
        }
        catch (e) {
        }
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.projectChange !== nextProps.projectChange) {
            this.clearForm()
        }
    }


    addAlarmReceiverUser = async () => {
        // debugger
        // if (this.state.key === '' || this.state.key === null || this.state.key === "Select Publisher") {
        //     return this.setState({ ...this.state, isError: true, message: 'Publisher key required!' });
        // } 

        if (this.state.emailHost == -1) {
            return this.setState({ ...this.state, isError: true, message: "Email is required!" });
        } else if (this.state.emailTemplate == -1) {
            return this.setState({ ...this.state, isError: true, message: "Email Template is required!" });
        }


        try {

            this.setState({ isError: false, isLoading: true, disable: true });

            // const data = {
            //     "platform_subscriber": localStorage.getItem("key"),
            //     "email_host": this.state.emailHost,
            //     "html_template": this.state.emailTemplate
            // }
            // console.log("Mine Data", data)

            const { data } = await alarmConfiguratiom({
                "platform_subscriber": localStorage.getItem("key"),
                "email_host": this.state.emailHost,
                "html_template": this.state.emailTemplate
            });


            this.setState({ isError: false, isLoading: false, disable: false });
            this.clearForm()
            toastMessageSuccess("Configuration add successfully")
        }
        catch (err) {
            // console.log("errror", err.response)
            if (err?.response?.status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            else {
                this.setState({ isError: true, isLoading: false, disable: false, message: "Sorry! We are facing some issues to add configuration. Please try again later." });
            }
        }
    }


    render() {
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Add Alarm Receiver User (This is our Alarm Reciver Addition Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 col-xl-8">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Setup alarm for your projects!</h5>
                                                <p>Configure email host & template against your project</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="auth-logo">
                                        <a href="" className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height={34} />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                        <form className="form-horizontal row">
                                            {/* <div className="mb-3 col-12">
                                                <label htmlFor="notificationname" className="col-form-label">Project*</label>
                                                <select disabled={this.state.fieldDisable} value={this.state.key} onChange={this.onChangePublisher} name="key" className="form-control">
                                                    <option>Select Project</option>
                                                    {
                                                        this?.props?.allProjects?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div> */}
                                            <div className="mb-3 col-12">
                                                <label htmlFor="notificationname" className="col-form-label">Email Host*</label>
                                                <select disabled={this.state.fieldDisable} value={this.state.emailHost} onChange={this.onChange} name="emailHost" className="form-control">
                                                    <option>Select Email</option>
                                                    {
                                                        this?.props?.emailHosts?.map((email) =>
                                                            <React.Fragment key={email.id}>
                                                                <option key={email.id} value={email.id}>{email.email}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3 col-12">
                                                <label htmlFor="notificationname" className="col-form-label">Email Template*</label>
                                                <select disabled={this.state.fieldDisable} value={this.state.emailTemplate} onChange={this.onChange} name="emailTemplate" className="form-control">
                                                    <option>Select Email</option>
                                                    {
                                                        this?.props?.allTemplate?.map((template) =>
                                                            <React.Fragment key={template.id}>
                                                                <option key={template.id} value={template.id}>{template.name}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            {
                                                this.state.isError ?
                                                    <div>
                                                        <div className="mb-3 col-12 alert alert-danger">
                                                            {this.state.message}
                                                        </div>
                                                    </div> : null
                                            }
                                        </form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button disabled={this.state.disable} onClick={this.addAlarmReceiverUser} type="submit" className="btn btn-primary">
                                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                    Configure Project Alarm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allProjects: state.projectReducer.allProjects,
    allTemplate: state.emailReducer.allTemplate,
    emailHosts: state.emailReducer.emailHosts,
    projectChange: state.projectReducer.projectChange

})
export default connect(mapStateToProps, { setEmailTemplate, setEmailHost })(AlarmConfiguration)