import { Component } from "react";
import { fetchPlatformUserList, deactivateUser, changePasswordByAdmin } from "../../../api/task/task";
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import moment from "moment"
import LoadingOverlay from "../../../Loader";
import YesOrNoModal from "../../Layout/YesOrNoModal";
import ChangePass from "./Modal/ChangePass";
import AddRole from "./Modal/AddRole";
import { connect } from "react-redux";
import { setPlatformUserList } from "../../../actions/auth";
import { getRoleList } from "../../../api/permission/permission";
import { toastMessageFailure } from "../../../utils/toastMessage";
import { setRoleList } from "../../../actions/project";



class PlatformUserList extends Component {

    state = {
        userList: [],
        isLoading: false,
        users: [],
        yesOrNoModalShow: false,
        ChangePassModalShow: false,
        AddRoleModalShow: false,
        password: null,
        userId: null,
        itemIndex: -1
    }

    getPlatformUserList = async () => {
        this.setState({ ...this.state, isLoading: true })
        fetchPlatformUserList().then(({ data: { data } }) => {
            console.log("Data", data)
            this.setState({ isLoading: false })
            this.setState((state) => ({ ...state, userList: data }));
        }).catch(e => {
            this.setState({ isLoading: false })
        });
        console.log("Bilal", this.state.userList)
    }


    getRoleList = async ()=>{
        try {
            // this.props.hideProjectListComponent(true)
            this.setState({isDataLoading:true})
            const { data } = await getRoleList()
            this.setState({ roleList: data.data, isDataLoading:false })
            this.props.setRoleList(data.data)
        }
        catch (e) {
            console.log('tester ', e)
            this.setState({isDataLoading:false})
            toastMessageFailure("Fail to get role list")
    
        }
       }


    componentDidMount() {
        this.getPlatformUserList()
        this.getRoleList()
    }

    



    // addForm(){
    //     this.state.users.push({ username: null });
    //     this.setState({ users: [...this.state.users] });
    // }

    render() {
        // console.log("here ->",this.state.users)
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">This is User List Page</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            {/* <button onClick={()=> this.addForm()}>
                        Add Form
                    </button>

                    {
                        this.state.users.map((_, i) => (
                            <div key={i}>
                                <input onChange={e => {
                                    e.preventDefault();
                                    this.state.users[i].username = e.target.value;
                                }} id="title" name="title" type="text" className="form-control" placeholder="Enter UserName" />
                            </div>
                        ))
                    } */}

                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Status</th>
                                                    <th>Create Time</th>
                                                    <th>Role Name</th>

                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {!isEmpty(this.state.userList) ? (
                                                    this.state.userList.map((item, index) =>
                                                        <tr key={item.id}>
                                                            <td scope="row">{item.id}</td>
                                                            <td>{item.username}</td>
                                                            <td style={{ maxWidth: "175px" }}>{item.email}</td>
                                                            <td className="text-uppercase" style={item.is_active ?{ maxWidth: "175px",color:"green"}:{ maxWidth: "175px",color:"red"}}>{item.is_active ? 'active' : 'Inactive'}</td>
                                                            <td>{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                            <td>{item.role?.name || 'N/A'}</td>

                                                            <td>
                                                                <span class="badge badge-dot mr-4">
                                                                    <span class="status">
                                                                        {/* <button disabled={this.state.disable} onClick={() => {
                                                                        this.setState({ ...this.state, data: item }, () => {
                                                                            document.getElementById("alarm-config-btn").click();
                                                                        });
                                                                    }} className="btn btn-primary waves-effect waves-light">
                                                                        {this.state.isDeleteLoading === true ? <LoadingOverlay></LoadingOverlay> : null}
                                                                        View
                                                                    </button> */}
                                                                        <button className="btn btn-primary waves-effect waves-light mr-2"
                                                                            // onClick={()=> this.deactivateUserFunc(item.id)}
                                                                            onClick={() => {
                                                                                this.setState({ ...this.state, yesOrNoModalShow: true, userId: item.id });
                                                                            }}
                                                                        >
                                                                            Deactivate User
                                                                        </button>
                                                                        <button className="btn btn-warning waves-effect waves-light mr-2" style={{ color: "#fff" }}
                                                                            // onClick={() => this.changePasswordByAdmin(item.id, this.state.password)}
                                                                            onClick={() => {
                                                                                this.setState({ ...this.state, ChangePassModalShow: true, userId: item.id });
                                                                            }}
                                                                        >
                                                                            change Password
                                                                        </button>
                                                                        <button className="btn btn-info waves-effect waves-light" style={{ color: "#fff" }}
                                                                            disabled={item?.role===null? true:false}
                                                                            onClick={() => {
                                                                                this.setState({ ...this.state, userId: item.id, itemIndex: index, AddRoleModalShow: true });
                                                                            }}
                                                                        >
                                                                            Edit Role
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            {/* <td>
                                                            <span class="badge badge-dot mr-4">
                                                                <span class="status">
                                                                    <button disabled={this.state.disable} onClick={() => {
                                                                        this.setState({ ...this.state, data: item }, () => {
                                                                            document.getElementById("alarm-config-btn").click();
                                                                        });
                                                                    }} className="btn btn-primary waves-effect waves-light">
                                                                        {this.state.isDeleteLoading === true ? <LoadingOverlay></LoadingOverlay> : null}
                                                                        View
                                                                    </button>
                                                                    <input type={"text"} value={this.state.password} name={item.id} onChange={(e)=> this.setState({password:e.target.value}) }/>
                                                                    <button onClick={()=> this.changePasswordByAdmin(item.id,this.state.password)}>
                                                                        change Password
                                                                    </button>
                                                                </span>
                                                            </span>
                                                        </td> */}
                                                        </tr>
                                                    ))
                                                    : this.state.isLoading ? (
                                                        <LoadingOverlay></LoadingOverlay>
                                                    ) : (
                                                        <tr className="h4 font-weight-300 text-center">
                                                            <td colspan="5">No data Found</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <YesOrNoModal title="Deactivate Active Users" loading={this.state.isLoading} disable={this.state.disable} msg={'Are you sure you want to DeActivate this User?'} showYesOrNoModal={this.state.yesOrNoModalShow}
                                userId={this.state.userId}
                                onCloseYesOrNoModal={() => {
                                    this.setState({
                                        yesOrNoModalShow: false
                                    })
                                }}
                                callback={this.getPlatformUserList}
                            >
                            </YesOrNoModal>



                            <ChangePass
                                showChangePassModal={this.state.ChangePassModalShow}
                                userId={this.state.userId}
                                
                                onCloseChangePassModal={() => {
                                    this.setState({
                                        ChangePassModalShow: false
                                    })
                                }}>
                            </ChangePass>

                            <AddRole
                                showAddRoleModal={this.state.AddRoleModalShow}
                                user={this.state.itemIndex !== -1 ? this.state.userList[this.state.itemIndex] : {}}
                                callback={this.getPlatformUserList}
                                onCloseAddRoleModal={() => {
                                    this.setState({
                                        AddRoleModalShow: false
                                    })
                                }}>
                            </AddRole>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}




export default connect(null,{
    setRoleList
})(PlatformUserList)