
import { GET_ALL_TEMPLATE,SET_LOADER, GET_ALL_EMAIL, TOTAL_EMAIL_COUNT, SET_EMAIL_HOST,SET_ALL_NEWSLIST,SET_ALL_SUBSCRIBER } from '../types/email'


export const setEmailTemplate = templates => dispatch => {
    dispatch({
        type    :   GET_ALL_TEMPLATE,
        payload :   templates
    })
}

export const setAllEmail = emails => dispatch => {
    dispatch({
        type    :   GET_ALL_EMAIL,
        payload :   emails
    })
}

export const setEmailHost = emails => dispatch => {
    dispatch({
        type    :   SET_EMAIL_HOST,
        payload :   emails
    })
}
export const setLoader = value => dispatch => {
    dispatch({
        type    :   SET_LOADER,
        payload :   value
    })
}


export const setEmailsCounts = count => dispatch => {
    dispatch({
        type    :   TOTAL_EMAIL_COUNT,
        payload :   count
    })
}


export const setAllSubsciber = subscriber => dispatch => {
    dispatch({
        type    :   SET_ALL_SUBSCRIBER,
        payload :   subscriber
    })
}


export const setAllNewsList = newsList => dispatch => {
    dispatch({
        type    :   SET_ALL_NEWSLIST,
        payload :   newsList
    })
}







// export const setEmailTemplate = () => async (dispatch) => {
//     dispatch({
//         type: ADD_LOADER,
//         payload: 'fetch-email-template-loader',
//     })


//     try {
//         await axios.get(`${config.notificationUrl}/api/emails/templates/`, { headers: { "Authorization": "JWT" + " " + localStorage.getItem("jwtToken") } })
//             .then((response) => {


//                 //Clear the Loader
//                 dispatch({
//                     type: REMOVE_LOADER,
//                     payload: 'fetch-email-template-loader',
//                 })
//                 // make template object

                

//                 dispatch({
//                     type: GET_ALL_TEMPLATE,
//                     payload: response?.data.data
//                 })

//             })
//     } catch (err) {

//         if (err?.response?.status === 401) {

//             //
//             localStorage.removeItem("jwtToken")
//             window.location.replace("/login")
//         }

//         dispatch({
//             type: REMOVE_LOADER,
//             payload: 'fetch-email-template-loader',
//         })
//         dispatch({
//             type: GET_ALL_TEMPLATE,
//             payload: [],
//         })
//     }
// }



// export const setAllEmail = (limit, page) => async (dispatch) => {
//     dispatch({
//         type: ADD_LOADER,
//         payload: 'fetch-email-loader',
//     })


//     try {
//         await axios.get(`${config.notificationUrl}/api/emails/${limit}/?page=${page}`, { headers: { "Authorization": "JWT" + " " + localStorage.getItem("jwtToken") } })
//             .then((response) => {


//                 //Clear the Loader
//                 dispatch({
//                     type: REMOVE_LOADER,
//                     payload: 'fetch-email-loader',
//                 })

//                 dispatch({
//                     type: GET_ALL_EMAIL,
//                     payload: response?.data.data
//                 })

//                 dispatch({
//                     type: TOTAL_EMAIL_COUNT,
//                     payload: response?.data.total_count
//                 })


//             })
//     } catch (err) {

//         if (err?.response?.status === 401) {

//             //
//             localStorage.removeItem("jwtToken")
//             window.location.replace("/login")
//         }
//         dispatch({
//             type: REMOVE_LOADER,
//             payload: 'fetch-email-loader',
//         })
//         dispatch({
//             type: GET_ALL_EMAIL,
//             payload: [],
//         })
//     }
// }



