import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastMessageSuccess } from "../../utils/toastMessage";
import { getLoginUserDetail } from "../../api/authentication/authentication"
import { setEmailTemplate } from "../../actions/email"
import { fetchEmailTemplate, sendNewsLetter } from "../../api/email/email"
import { setEmailHost } from "../../actions/email"
import { isEmpty } from 'lodash'
import timeAgo from 'time-ago';
import moment from "moment";
import Select from 'react-select'

class CreateNewsLetter extends Component {
    state = {
        data: {
            email_host: "",
            to_email: "",
            subject: "",
            html_template_id: "",
            is_all_user: false,
            wts: null,
            platform_subcriber_id: []
        },
        sendToAllUsers: false,
        selectedTemplate: "",
        renderParams: [],
        message: "",
        isError: false,
        isLoading: false,
        disable: false,
        fieldDisable: false,
        resetDisable: false,
        repeatDisable: true,
        selectedFile: null,
        timeRequired: "",
    }


    onChange = (e) => {

        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } }
            , () => {
                if (e.target.name === "wts") {


                    var localDate = new Date();
                    var currentTime = moment.utc(localDate).format()

                    let ttlTime = new Date(this.state.data.wts)
                    var ttlExpireTime = moment.utc(ttlTime).format()

                    var diff = moment.duration(moment(ttlExpireTime).diff(moment(currentTime)));

                    if (diff.get("years") > 0) {
                        this.setState({ timeRequired: `${diff.get("years")} years ${diff.get("months")} months ${diff.get("days")} days ${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                    } else if (diff.get("months") > 0) {
                        this.setState({ timeRequired: `${diff.get("months")} months ${diff.get("days")} days ${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                    } else if (diff.get("days") > 0) {
                        this.setState({ timeRequired: `${diff.get("days")} days ${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                    } else if (diff.get("hours") > 0) {
                        this.setState({ timeRequired: `${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                    } else if (diff.get("minutes") > 0) {
                        this.setState({ timeRequired: `${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                    } else if (diff.get("seconds") > 0) {
                        this.setState({ timeRequired: `${diff.get("seconds")} seconds from now ` })
                    } else {
                        const selectDateAndTime = timeAgo.ago(this.state.data.wts)

                        if (selectDateAndTime === "NaN year ago") {
                            this.setState({ ...this.state, timeRequired: "" })

                        }
                        else {
                            this.setState({ ...this.state, timeRequired: timeAgo.ago(this.state.data.wts) })
                        }
                    }
                }
            }



        );


    }

    onChangeTemplate = (e) => {

        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });


        if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined && e.target.value !== "Select Email Template") {

            const user = this?.props?.allTemplate.find(u => u.id == e.target.value);

            this.setState({
                selectedTemplate: user
            }, () => {
                this.setState({ renderParams: this.state.selectedTemplate.render_param }, () => {
                    console.log("user", this.state.renderParams)
                })

            });

        }
        else {
            this.setState({ selectedTemplate: "", renderParams: [] })

        }

    }

    componentDidMount = async () => {
        try {
            const { data } = await getLoginUserDetail(localStorage.getItem("jwtToken"))
            this.setState({ ...this.state, data: { ...this.state.data, from_email: data?.user?.email } });
        } catch (e) {
        }
    }

    setUpAgain = async () => {
        this.setState({
            data: {
                email_host: "",
                to_email: "",
                subject: "",
                html_template_id: "",
                is_all_user: false,
                wts: null,
                platform_subcriber_id: []
            },
            sendToAllUsers: false,
            selectedTemplate: "",
            renderParams: [],
            message: "",
            isError: false,
            isLoading: false,
            disable: false,
            fieldDisable: false,
            resetDisable: false,
            repeatDisable: true,
            selectedFile: null,
            timeRequired: "",
        })
    }

    onFileChange = event => {
        this.setState({ ...this.state, selectedFile: event.target.files[0] });
    };
    componentWillReceiveProps(nextProps) {
        if (this.props.emailHosts.length !== nextProps.emailHosts.length) {
            if (nextProps.emailHosts.length !== 0) this.setState({ ...this.state, data: { ...this.state.data, email_host: nextProps.emailHosts[0].id } });
        }
    }


    createNewsLetter = async () => {
        console.log('tester ', this.state.data)
        if (this.props.emailHosts.length < 0) {
            return this.setState({ ...this.state, isError: true, message: 'From email field is required!' });
        }

        else if (this.state.data.subject === '' || this.state.data.subject === null || this.state.data.subject === undefined) {
            return this.setState({ ...this.state, isError: true, message: 'Subject field is required!' });
        }

        else if (this.state.selectedFile === '' || this.state.selectedFile === null || this.state.selectedFile === undefined) {
            return this.setState({ ...this.state, isError: true, message: 'Select email template!' });
        }
        else if (this.state.data.wts === '' || this.state.data.wts === null || this.state.data.wts === undefined) {
            return this.setState({ ...this.state, isError: true, message: 'Time of send is required!' });
        }
        else if (this.state.data.platform_subcriber_id.length === 0) {
            return this.setState({ ...this.state, isError: true, message: 'Project selection is required!' });
        }

        var localDate = new Date();
        var currentTime = moment.utc(localDate).format()

        let ttlTime = new Date(this.state.data.wts)

        var ttlExpireTime = moment.utc(ttlTime).format()

        let isValid = moment(ttlExpireTime).isBefore(currentTime);
        if (isValid) {
            return this.setState({ isError: true, message: 'Please provide a future date!' });

        }


        let receiver = "*"

        const formData = new FormData();

        var wtsTime = moment.utc(this.state.data.wts).format()

        formData.append("html_template", this.state.selectedFile);

        formData.append("email_host", isEmpty(this.state.data.email_host) ? this.props.emailHosts[0].id : this.state.data.email_host);
        formData.append("to_email", receiver);
        formData.append("subject", this.state.data.subject);
        formData.append("wts", wtsTime);
        formData.append("platform_subscriber_id", JSON.stringify(this.state.data.platform_subcriber_id));


        try {
            this.setState({ isError: null, message: null, isLoading: true, disable: true, resetDisable: true });
            await sendNewsLetter(formData);

            this.setState({ isError: false, isLoading: false, repeatDisable: false, resetDisable: false, message: "", fieldDisable: true }, () => {
                toastMessageSuccess("Email send successfully")

            });


        }
        catch (err) {

            if (err?.response?.status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            } else {
                this.setState({ isError: true, isLoading: false, disable: false, repeatDisable: false, message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });
            }
        }

    }

    render() {
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Send News Letter Email (This is our Send News Letter Email Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 col-xl-8">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Admin !</h5>
                                                <p>Compose your Email here.</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="auth-logo">
                                        <a href="" className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height={34} />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                        <form className="form-horizontal row">
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">From Email *</label>
                                                <select disabled={this.state.fieldDisable} value={this.state.data.email_host} className="form-control" name="email_host" onChange={this.onChange}>
                                                    {
                                                        this.props.emailHosts.map(_ => <option key={_.id} value={_.id}>{_.email}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">Select Project(s) *</label>
                                                <Select
                                                    isMulti={true}
                                                    onChange={(selectedValues) => {
                                                        this.setState({ ...this.state, data: { ...this.state.data, platform_subcriber_id: [...selectedValues.map(({ value }) => value)] } });
                                                    }}
                                                    options={this.props.projects.map(({ platform_subscriber_entity, platform_subscriber_id }) => ({ label: platform_subscriber_entity, value: platform_subscriber_id }))}
                                                />
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">Subject *</label>
                                                <input disabled={this.state.fieldDisable} value={this.state.data.subject} onChange={this.onChange} id="notificationname" name="subject" type="text" className="form-control" placeholder="Enter Email Subject" />
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">Select Template *</label>
                                                <input disabled={this.state.fieldDisable} required type="file" accept=".html" className="form-control" onChange={this.onFileChange} />
                                            </div>

                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">When To Send</label>

                                                <div className="col-lg-12" style={{ padding: 0, margin: 0 }}>
                                                    <input disabled={this.state.fieldDisable} value={this.state.wts} onChange={this.onChange} id="notificationname" name="wts" type="datetime-local" className="form-control" />
                                                </div>
                                                <div className="col-lg-12" style={{ display: "flex", alignItems: 'center', marginTop: 10, paddingLeft: 0, marginLeft: 0 }}> <span style={{ fontWeight: 'bold' }}>{this.state.timeRequired}</span></div>
                                            </div>
                                            {
                                                this?.state?.renderParams?.map((item, idx) =>

                                                    <div className="mb-3 col-6">
                                                        <label htmlFor="notificationname">{item}</label>

                                                        <input disabled={this.state.fieldDisable} value={this.state.item} onChange={this.onChange} id="notificationname" name={item} type="text" className="form-control" />

                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.isError ?
                                                    <div className="row mb-4">
                                                        <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                                        <div className="col-lg-10 alert alert-danger">
                                                            {this.state.message}

                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <div className="mt-3 text-center col-12">
                                                <button disabled={this.state.disable} onClick={this.createNewsLetter} style={{ marginRight: '10px' }} className="btn btn-primary waves-effect waves-light w-30" type="button">
                                                    {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                    Schedule News Letter</button>
                                                {/* <button style={{ marginRight: '10px' }} disabled={this.state.resetDisable} className="btn btn-primary waves-effect waves-light w-30" type="button" onClick={this.setUpAgain}>Reset</button> */}
                                                <button disabled={this.state.repeatDisable} className="btn btn-primary waves-effect waves-light w-30" type="button" onClick={() => {
                                                    this.setState({
                                                        fieldDisable: false,
                                                        disable: false,
                                                        repeatDisable: true
                                                    })
                                                }}
                                                >Repeat</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}




const mapStateToProps = (state) => ({

    auth: state.authReducer,
    allTemplate: state.emailReducer.allTemplate,
    waitingFor: state.commonReducer.waitingFor,
    emailHosts: state.emailReducer.emailHosts,
    projects: state.projectReducer.allProjects
})

export default connect(mapStateToProps, { setEmailTemplate, setEmailHost })(CreateNewsLetter)
