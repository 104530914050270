// import { Modal } from "bootstrap";
import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CheckCircle from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import {deactivateUser} from "../../api/task/task"


export default class YesOrNoModal extends React.Component {

    closeModal = (value) => {
        
        this.props.onCloseYesOrNoModal(value)
    }

    deactivateUserFunc = async () => {
        this.setState({ ...this.state, isLoading: true })
        deactivateUser(this.props.userId).then(({ data: { data } }) => {
            this.setState({ isLoading: false })
            // this.setState((state) => ({ ...state, userList: data }));
            this.setState({ isLoading: false, disable: false, ChangePassModalShow: false });
            this.props.callback()
        }).catch(e => {
            this.setState({ isLoading: false })
        });
        this.closeModal(false) 
    }



    render() {
        return (
            <Modal dialogClassName="ErrorAlert text-center"
                show={this.props.showYesOrNoModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className='justify-content-center'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <DeleteIcon />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    wordWrap: 'break-word',
                    paddingLeft: '2px',
                    paddingRight: '2px'
                }}>
                    <h4>{this.props.title || 'Delete Notification'}</h4>
                    <p className='m-0'>{this.props.msg}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={this.props.disable} type="button" class="btn btn-success waves-effect waves-light" onClick={() => { this.deactivateUserFunc()}} style={{ marginRight: '10px' }}>
                        <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
                        {
                            // this.props.isLoading === true ? <SpinnerLoader /> : "Yes"
                            this.props.loading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : "yes"
                        }


                    </button>
                    <button disabled={this.props.disable} type="button" class="btn btn-primary waves-effect waves-light" onClick={() => { this.closeModal(false) }}>
                        <i class="bx bx-smile font-size-16 align-middle me-2"></i> No
                    </button>
                </Modal.Footer>
            </Modal >
        )
    }


}