import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const path = {
  '': [
    { name: 'Home', path: '/' }
  ],
  'notification': [
    { name: 'Home', path: '/' },
    { name: 'CreateNotification', path: '/notification/create-notification', isActive: true },
    { name: 'NotificationList', path: 'notification/project-task' }
  ]

}

export default (props) => {

  // const _location = useLocation();
  // console.log('tester ', _location)

  const [ location, setLocation ] = useState(window.location.pathname.split('/')[1]);

  useEffect(() => {
    
    setLocation(window.location.pathname.split('/')[1]);
  }, [ props?.location?.pathname ])

  return (
    <div className="page-content main-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <ol className="breadcrumb m-0">
              {
                path[location].map((_path, index) => <li key={index} className={`breadcrumb-item ${_path.isActive && 'active'}`}>{_path.isActive ? `${_path.name}` : <Link to={_path.path}>{_path.name}</Link>}</li>)
              }
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}