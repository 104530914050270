import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment"
import isEmpty from "../../utils/isEmpty"
import LoadingOverlay from "../../Loader";
import { setAllEmail, setEmailsCounts } from "../../actions/email";
import { setAllProjects } from "../../actions/project";
import { fetchAllEmails } from "../../api/email/email"
import { toastMessageFailure, toastMessageSuccess } from "../../utils/toastMessage";
import config from "../../config/index";
import EmailListModal from "./Modal/EmailListModal";


class EmailList extends Component {

    state = {
        isLoading: true,
        disable: "",
        limit: config.limit,
        pageNumber: 1,
        EmailListModalShow: false,
        totalPages: 0,
        renderParams: {},
        ProjectId: localStorage.getItem("key"),
        isDataLoading: false
    }


    componentDidMount = async () => {
       this.getEmailList()

      
    }
     getEmailList = async ()=>{
        try {
            this.setState({ isDataLoading: true });
            const { data } = await fetchAllEmails(this.state.limit, this.state.pageNumber)
            this.props.setAllEmail(data.data);
            this.props.setEmailsCounts(data.total_count);
            this.setState({ isDataLoading: false });
        }
        catch (e) {
            if (e?.response?.status === 404) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
                return
            }
            toastMessageFailure("Fail to get email ist")
            this.setState({ isDataLoading: false });
        }
    }

    onNextBtnClick = () => {

        this.setState({
            pageNumber: this.state.pageNumber + 1
        }, () => {
            fetchAllEmails(this.state.limit, this.state.pageNumber).then(({ data }) => {
                this.props.setAllEmail(data.data);
                this.props.setEmailsCounts(data.total_count)
            }).catch(e => {
            });
        })
    }

    onPreviousBtnClick = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        }, () => {
            fetchAllEmails(this.state.limit, this.state.pageNumber).then(({ data }) => {
                this.props.setAllEmail(data.data);
            }).catch(e => {
            });
        })
    }

    componentWillReceiveProps(nextProps) {
       

        if (this.props.projectChange !== nextProps.projectChange) {
          this.getEmailList()
        }
    }



    render() {
        console.log("this.props.allEmail",this.props.allEmail)

        return (
            <>
                <EmailListModal
                    EmailListshowModal={this.state.EmailListModalShow}
                    renderParams={this.state.renderParams}
                    onCloseEmailListModal={() => {
                        this.setState({
                            EmailListModalShow: false
                        })
                    }
                    }
                >
                </EmailListModal>
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Email List (This is our Email List Page)</h4>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Create New Notification</h4>
                                        <form>
                                            <div className="row mb-4">
                                                <label htmlFor="notificationname" className="col-form-label col-lg-2">Publisher *</label>
                                                <div className="col-lg-10">

                                                    <select disabled={this.state.fieldDisable} value={this.state.key} onChange={this.fetchEmail} name="key" className="form-control">
                                                        <option>Select Publisher</option>
                                                        {
                                                            this?.props?.allProjects?.map((key, idx) =>
                                                                <React.Fragment key={idx}>
                                                                    <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            {!isEmpty(this.props.allEmail) ? (
                                this.props.allEmail.map((item) => (
                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm mb-3 mt-1 me-4">
                                                        <span class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">{item.email_host}</span>
                                                    </div>
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="text-truncate font-size-20">{item.email_host}</h5>
                                                    </div>
                                                </div>
                                                <h5 className="font-size-16 mt-2">Subject :</h5>
                                                <p className="text-muted">{item.subject}</p>
                                                <div className="text-muted mt-2 row">
                                                    <div className="col-3">
                                                        <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" /> To Email</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">{item?.render_param?.email}</p>
                                                    </div>
                                                    <div className="col-3">
                                                        <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" /> Time</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</p>
                                                    </div>
                                                </div>
                                                <div className="row task-dates">
                                                    <div className="col-sm-4 col-6">
                                                        <div className="mt-3">
                                                            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary" /> Creation Date</h5>
                                                            <p className="text-muted mb-0">{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-6">
                                                        <div className="mt-4">
                                                            <button onClick={() => {
                                                                window.open(`${config.notificationUrl}/v2/emails/template/html/${item.html_template_id}/`)

                                                            }} class="btn btn-outline-primary waves-effect waves-light">Preview</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : this.state.isDataLoading === true ?


                                <LoadingOverlay></LoadingOverlay>
                                : <label className="text-center text-secondary">No Email found!</label>}

                            {
                                this.state.totalPages > 1 && (
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        {
                                            this.state.pageNumber !== 1 && (
                                                <button style={{ marginRight: '20px' }} disabled={this.state.pageNumber === 1} type="button" className="btn btn-primary Submit mb-2" onClick={this.onPreviousBtnClick} >Previous</button>
                                            )
                                        }
                                        <button disabled={this.props.totalPages !== 0 ? (this.props.totalPages - this.state.pageNumber) === 0 : false} type="button" class="btn btn-secondary Cancel mb-2" onClick={this.onNextBtnClick}>Next</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    projectChange: state.projectReducer.projectChange,
    auth: state.authReducer,
    allEmail: state.emailReducer.allEmail,
    totalEmail: state.emailReducer.totalEmail,
    totalPages: state.emailReducer.totalPages,
    waitingFor: state.commonReducer.waitingFor,
    allProjects: state.projectReducer.allProjects
})

export default connect(mapStateToProps, { setAllProjects, setAllEmail, setEmailsCounts })(EmailList)






