import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { getRoleList, updateUserRoleDataApi } from '../../../../api/permission/permission';
import { toastMessageFailure } from '../../../../utils/toastMessage';


class EditRole extends React.Component {


    state = {
        name: null,


    }

    closeModal = (value) => {
        this.props.onCloseEditRoleModal(value)
    }


    changeroleFunc = async () => {
        var data = {
            "name":this.state.name
        }
        updateUserRoleDataApi(this.props.role?.id,data ).then(({ data: { data } }) => {
            this.setState({ isLoading: false })
            this.setState({ isLoading: false, disable: false, changePasswordByAdmin: false });
            this.props.callback();
        }).catch(e => {
            this.setState({ isLoading: false })
        });
    }
    render() {
        return (
            // <Modal dialogClassName="InfoSuccess"
            <Modal
                show={this.props.showEditRoleModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit User Role
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row mb-4">
                        <label for="" class="col-form-label col-lg-3">Role *</label>
                        <div class="col-lg-9">
                            <input type="text" className='form-control' value={this.state.name} placeholder={this.props.role?.name} onChange={e => {
                                e.preventDefault();
                                this.setState({ name: e.target.value });
                            }} />
                        </div>
                    </div>
                    <div className='text-center'>
                        <button type="button" class="btn btn-success Cancel"
                        onClick={() => { 
                            this.changeroleFunc();
                            this.closeModal(true);
                            }}
                            style={{ marginRight: '10px' }}>
                            <i class="bx bx-check-double font-size-16 align-middle me-2"
                            ></i>
                            Yes
                        </button>
                        <button disabled={this.props.disable} type="button" class="btn btn-primary Submit " onClick={() => { this.closeModal(false) }}>
                            <i class="bx bx-smile font-size-16 align-middle me-2"></i>
                            No
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default EditRole