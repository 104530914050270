import { createNotification } from "../../api/general/general"
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastMessageSuccess, toastMessageFailure } from "../../utils/toastMessage";
import { setAllProjects, setProjectTaskByID } from "../../actions/project";
import { fetchAllProjects, fetchProjectTaskByID } from "../../api/task/task";

import { removeNotificationAPI } from "../../api/general/general"
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import timeAgo from 'time-ago';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor, E } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CreatePermissionModal from "../Layout/CreatePermissionModal";

class CreateNotification extends Component {
    state = {
        title: "",
        key: "",
        sender: "@NUCOIN",
        receiver: "",
        des: "",
        ttl: null,
        task: "",
        editorState: EditorState.createEmpty(),
        message: "",
        isError: false,
        isLoading: false,
        sendToAllUsers: false,
        CreatePermissionModalShow: false,
        open: false,
        taskText: "",
        undoConfirmation: false,
        taskDeleteLoader: "",
        taskID: "",
        fieldDisable: false,
        timeRequired: "",
        announcement: false,
        disable: false,
        resetDisable: false,
        repeatDisable: true,
        header: false,
        notificationType: "",
        labelTTL: "Time To Live",
        news: [{ title: 'First title' }, { title: 'second title' }],
        is_mobile_push: false,
        is_web_push: false

    }


    onChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        }, () => {
            if (e.target.name === "ttl") {

                var localDate = new Date();
                var currentTime = moment.utc(localDate).format()

                let ttlTime = new Date(this.state.ttl)
                var ttlExpireTime = moment.utc(ttlTime).format()

                var diff = moment.duration(moment(ttlExpireTime).diff(moment(currentTime)));

                if (diff.get("years") > 0) {
                    this.setState({ timeRequired: `${diff.get("years")} years ${diff.get("months")} months ${diff.get("days")} days ${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                } else if (diff.get("months") > 0) {
                    this.setState({ timeRequired: `${diff.get("months")} months ${diff.get("days")} days ${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                } else if (diff.get("days") > 0) {
                    this.setState({ timeRequired: `${diff.get("days")} days ${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                } else if (diff.get("hours") > 0) {
                    this.setState({ timeRequired: `${diff.get("hours")} hours ${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                } else if (diff.get("minutes") > 0) {
                    this.setState({ timeRequired: `${diff.get("minutes")} minutes ${diff.get("seconds")} seconds from now ` })
                } else if (diff.get("seconds") > 0) {
                    this.setState({ timeRequired: `${diff.get("seconds")} seconds from now ` })
                } else {
                    const selectDateAndTime = timeAgo.ago(this.state.ttl)

                    if (selectDateAndTime === "NaN year ago") {
                        this.setState({ timeRequired: "" })

                    }
                    else {
                        this.setState({ timeRequired: timeAgo.ago(this.state.ttl) })
                    }
                }
            }

            if (e.target.value === "ttl_relative") {
                this.setState({ labelTTL: "Event Time Occur" })
            } else if (e.target.value === "header" || e.target.value === "announcement" || e.target.value === "ttl_absolute" || e.target.value === "Select Notification Type" || e.target.value === "Standard") {
                this.setState({ labelTTL: "Time To Live" })
            } if (e.target.name === 'notificationType') {
                this.setState({ ...this.state, sendToAllUsers: true })
            } if (e.target.name === 'notificationType' && e.target.value !== 'header') {
                this.setState({ ...this.state, sendToAllUsers: false })
            };
        });

    }

    handleToClose = () => {
        this.setState({ open: false, undoConfirmation: false, taskDeleteLoader: false })
    }
    deleteTaskConfirmation = async () => {
        this.setState({
            undoConfirmation: true
        })
    }

    deleteTaskYes = async () => {
        try {
            this.setState({ taskDeleteLoader: true });
            const { data } = await removeNotificationAPI(this.state.taskID);
            toastMessageSuccess("Notifiction reverted successfully")
            this.setUpAgain()
        }
        catch (err) {
            this.setState({ taskDeleteLoader: false });
            toastMessageFailure("Unable to revert notification!")
        }
    }

    deleteTaskNo = async () => {
        this.setState({
            undoConfirmation: false
        })
    }

    onChangePublisher = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }

    componentDidMount = async () => {
        fetchAllProjects().then(({ data: { projects } }) => {
            this.props.setAllProjects(projects);
        }).catch(e => {
        });
    }

    setUpAgain = async () => {
        this.setState({
            title: "",
            key: "",
            sender: "@NUCOIN",
            receiver: "",
            des: "",
            ttl: null,
            task: "",
            editorState: EditorState.createEmpty(),
            message: "",
            isError: false,
            isLoading: false,
            sendToAllUsers: false,

            open: false,
            taskText: "",
            undoConfirmation: false,
            taskDeleteLoader: "",
            taskID: "",
            fieldDisable: false,
            timeRequired: "",
            announcement: false,
            disable: false,
            resetDisable: false,
            repeatDisable: true,
            header: false,
            notificationType: "",
            labelTTL: "Time To Live",
            news: [{ title: 'First title' }, { title: 'second title' }],
            is_mobile_push: false,
            is_web_push: false

        })
    }

    isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    checkRtl = (string) => {
        const charsToCheck = string?.slice(0, 10)
        var RTL = ['ا', 'ب', 'پ', 'ت', 'س', 'ج', 'چ', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'ژ', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ک', 'گ', 'ل', 'م', 'ن', 'و', 'ه', 'ی'];

        for (let i = 0; i < charsToCheck?.length; i++) {
            if (RTL.indexOf(charsToCheck[i]) > -1)
                return true;
        }
        return false;
    };


    wordCount(str) {
        var m = str.match(/[^\s]+/g)
        return m ? m.length : 0;
    }

    createNotificationModal = async () => {
        if (localStorage.getItem("key") === '' || localStorage.getItem("key") === null || localStorage.getItem("key") === "Select Publisher") {
            return this.setState({ isError: true, message: 'Please select the project from dropdown!' });
        }
        else if (this.state.sender === '' || this.state.sender === null) {
            return this.setState({ isError: true, message: 'Sender name required!' });
        }
        else if (this.state.receiver === '' && this.state.sendToAllUsers === false || this.state.receiver === null && this.state.sendToAllUsers === false) {
            return this.setState({ isError: true, message: 'Receiver name required!' });
        }

        else if (this.state.title === '' || this.state.title === null || this.wordCount(this.state.title) === 0) {
            if (this.state.notificationType !== 'header')
                return this.setState({ isError: true, message: "Title required!" });
        }

        else if (this.state.des === '' || this.state.des === null || this.wordCount(this.state.des) === 0) {
            return this.setState({ isError: true, message: 'Description required!' });
        }
        else if (this.wordCount(this.state.title) > 48) {
            return this.setState({ isError: true, message: 'title is too long.Please reduce it.!' });
        }
        else if (this.wordCount(this.state.des) > 10000) {
            return this.setState({ isError: true, message: 'Message is too long.Please reduce it.!' });
        }

        if (this.state.notificationType === "header" || this.state.notificationType === "announcement" || this.state.notificationType === "ttl_absolute") {
            if (this.state.ttl === '' || this.state.ttl === null) {
                return this.setState({ isError: true, message: `TTL required for ${this.state.notificationType} notifications!` });
            }

        }

        try {
            let receiver
            let category
            const multilpleReceiver = this.state.receiver.includes(",")

            if (multilpleReceiver) {
                receiver = await Promise.all(this.state.receiver.replaceAll('\n', '').split(",").map(_ => _.trim()));
            }
            else if (this.state.sendToAllUsers) {
                receiver = "*"
            }
            else {
                receiver = this.state.receiver
            }
            if (this.state.notificationType === "header" || this.state.notificationType === "announcement" || this.state.notificationType === "ttl_absolute" || this.state.notificationType === "ttl_relative") {

                category = this.state.notificationType
            } else {
                category = "standard"
            }

            // if (this.state.ttl === "" || this.state.ttl === null) {
                this.setState({ CreatePermissionModalShow: true });
            // }
            // else {

            // }

        }
        catch (err) {
            console.log("errror", err)
            if (err?.response?.status === 401) {

                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            else {
                this.setState({ isError: true, isLoading: false, disable: false, resetDisable: false, message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });
            }
        }
    }

    createNotification = async () => {

        if (localStorage.getItem("key") === '' || localStorage.getItem("key") === null || localStorage.getItem("key") === "Select Publisher") {
            return this.setState({ isError: true, message: 'Please select the project from dropdown!' });
        }
        else if (this.state.sender === '' || this.state.sender === null) {
            return this.setState({ isError: true, message: 'Sender name required!' });
        }
        else if (this.state.receiver === '' && this.state.sendToAllUsers === false || this.state.receiver === null && this.state.sendToAllUsers === false) {
            return this.setState({ isError: true, message: 'Receiver name required!' });
        }

        else if (this.state.title === '' || this.state.title === null || this.wordCount(this.state.title) === 0) {
            if (this.state.notificationType !== 'header')
                return this.setState({ isError: true, message: "Title required!" });
        }

        else if (this.state.des === '' || this.state.des === null || this.wordCount(this.state.des) === 0) {
            return this.setState({ isError: true, message: 'Description required!' });
        }
        else if (this.wordCount(this.state.title) > 48) {
            return this.setState({ isError: true, message: 'title is too long.Please reduce it.!' });
        }
        else if (this.wordCount(this.state.des) > 10000) {
            return this.setState({ isError: true, message: 'Message is too long.Please reduce it.!' });
        }

        // || this.state.notificationType === "announcement"
        
        if (this.state.notificationType === "header"  || this.state.notificationType === "ttl_absolute") {
            if (this.state.ttl === '' || this.state.ttl === null) {
                return this.setState({ isError: true, message: `TTL required for ${this.state.notificationType} notifications!` });
            }

        }

        try {
            let receiver
            let category
            const multilpleReceiver = this.state.receiver.includes(",")

            if (multilpleReceiver) {
                receiver = await Promise.all(this.state.receiver.replaceAll('\n', '').split(",").map(_ => _.trim()));
            }
            else if (this.state.sendToAllUsers) {
                receiver = "*"
            }
            else {
                receiver = this.state.receiver
            }
            if (this.state.notificationType === "header" || this.state.notificationType === "announcement" || this.state.notificationType === "ttl_absolute" || this.state.notificationType === "ttl_relative") {

                category = this.state.notificationType
            } else {
                category = "standard"
            }

            if (this.state.ttl === "" || this.state.ttl === null) {

                this.setState({ isError: null, message: null, isLoading: true, disable: true, resetDisable: true, CreatePermissionModalShow: false });
                const { data } = await createNotification({
                    "platform_subscriber_id": localStorage.getItem("key"),
                    "sender": '@NUCOIN',
                    "receiver": receiver,
                    "message": this.state.des,
                    "category": category,
                    "title": this.state.notificationType === 'header' ? 'Header' : this.state.title,
                    "task": this.state.taskText,
                    "ttl": null,
                    is_mobile_push: this.state.is_mobile_push,
                    is_web_push: this.state.is_web_push
                });
                console.log("newwww", data)
                this.setState({ taskID: data.data.id, CreatePermissionModalShow: false });


            }
            else {

                var localDate = new Date();
                var currentTime = moment.utc(localDate).format()

                let ttlTime = new Date(this.state.ttl)
                var ttlExpireTime = moment.utc(ttlTime).format()

                let isValid = moment(ttlExpireTime).isBefore(currentTime);
                if (isValid) {
                    return this.setState({ isError: true, message: 'Please provide a future date!' });

                }

                this.setState({ isError: null, message: null, isLoading: true, disable: true, resetDisable: true });

                const { data } = await createNotification({
                    "platform_subscriber_id": localStorage.getItem("key"),
                    "sender": '@NUCOIN',
                    "receiver": receiver,
                    "message": this.state.des,
                    "category": category,
                    "title": this.state.notificationType === 'header' ? 'Header' : this.state.title,
                    "task": this.state.taskText,
                    "ttl": new Date(this.state.ttl),
                    is_mobile_push: this.state.is_mobile_push

                });
                console.log("newwww", data)
                this.setState({ taskID: data.task })
            }
            this.setState({ isError: false, isLoading: false, message: "", open: true, fieldDisable: true, repeatDisable: false, resetDisable: false, CreatePermissionModalShow: false });
            toastMessageSuccess("Notification send successfully")

        }
        catch (err) {
            console.log("errror", err)
            if (err?.response?.status === 401) {

                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            else {
                this.setState({ isError: true, isLoading: false, disable: false, resetDisable: false, message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });
            }
        }
    }

    render() {
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Create Notification (This is our Create Notification Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Create New Notification</h4>
                                    <form>
                                        <div className="row mb-4">
                                            {/* <label htmlFor="notificationname" className="col-form-label col-lg-2">Publisher *</label>
                                            <div className="col-lg-10">

                                                <select disabled={this.state.fieldDisable} value={this.state.key} onChange={this.onChangePublisher} name="key" className="form-control">
                                                    <option>Select Publisher</option>

                                                    {
                                                        this?.props?.allProjects?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>

                                            </div> */}
                                        </div>
                                        <div className="row mb-4">
                                            <label title="Make Announcement" className="col-form-label col-lg-2">Select Notification Type</label>
                                            <div className="col-lg-10">
                                                <select disabled={this.state.fieldDisable} value={this.state.notificationType} onChange={this.onChange} name="notificationType" className="form-control">
                                                    <option value="Select Notification Type">Select Notification Type</option>
                                                    <option value="standard">Standard</option>
                                                    <option value="announcement">Announcement</option>
                                                    <option value="header">Header</option>
                                                    <option value="ttl_absolute">TTL Absolute</option>
                                                    <option value="ttl_relative">TTL Relative</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Receiver</label>
                                            <div className="col-lg-10">
                                                <textarea value={this.state.receiver} rows={3} disabled={this.state.sendToAllUsers || this.state.fieldDisable || this.state.notificationType === 'header'} onChange={this.onChange} id="notificationname" name="receiver" type="text" className="form-control" placeholder="Enter Receiver Name" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label title="Send To All DB Customer" className="col-form-label col-lg-2">All Customers</label>
                                            <div className="col-lg-10">
                                                <input value={this.state.sendToAllUsers} disabled={this.state.receiver || this.state.fieldDisable || this.state.notificationType === 'header'} type="checkbox" checked={this.state.sendToAllUsers} onClick={() => {
                                                    this.setState({ ...this.state, sendToAllUsers: !this.state.sendToAllUsers })
                                                }} name="sendToAllUsers" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Title *</label>
                                            <div className="col-lg-10">
                                                <input disabled={this.state.fieldDisable || this.state.notificationType === "header"} value={this.state.title} onChange={this.onChange} id="notificationname" name="title" type="text" className="form-control" placeholder="Enter Notification Title" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationdesc" className="col-form-label col-lg-2">Message *</label>
                                            <div className="col-lg-10">
                                                <Editor
                                                    editorState={this.state.editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor border"
                                                    onEditorStateChange={value => {
                                                        this.setState({ ...this.state, editorState: value, des: draftToHtml(convertToRaw(value.getCurrentContent())) })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationdesc" className="col-form-label col-lg-2">Message Preview</label>
                                            <div className="col-lg-10">
                                                <div className={`${this.checkRtl(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))) ? 'arabic' : "english"} `}>
                                                    {ReactHtmlParser(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label title="Send To All DB Customer" className="col-form-label col-lg-2">IS WEB PUSH</label>
                                            <div className="col-lg-10">
                                                <input value={this.state.is_web_push} type="checkbox" checked={this.state.is_web_push} onClick={() => {
                                                    this.setState({ ...this.state, is_web_push: !this.state.is_web_push })
                                                }} name="is_web_push" />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <label title="Send To All DB Customer" className="col-form-label col-lg-2">IS MOBILE PUSH</label>
                                            <div className="col-lg-10">
                                                <input value={this.state.is_mobile_push} type="checkbox" checked={this.state.is_mobile_push} onClick={() => {
                                                    this.setState({ ...this.state, is_mobile_push: !this.state.is_mobile_push })
                                                }} name="is_mobile_push" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label disabled={this.state.fieldDisable} htmlFor="notificationname" className="col-form-label col-lg-2">{this.state.labelTTL} </label>
                                            <div className="col-lg-4">
                                                <input disabled={this.state.fieldDisable} value={this.state.ttl} onChange={this.onChange} id="notificationname" name="ttl" type="datetime-local" className="form-control" />
                                            </div>
                                            <div className="col-lg-4" style={{ display: "flex", alignItems: 'center' }}> <span style={{ fontWeight: 'bold' }}>{this.state.timeRequired}</span></div>
                                        </div>
                                        {
                                            this.state.isError ?
                                                <div className="row mb-4">
                                                    <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                                    <div className="col-lg-10 alert alert-danger">
                                                        {this.state.message}
                                                    </div>
                                                </div> : null
                                        }
                                    </form>
                                    <div className="row justify-content-end">
                                        <div className="col-lg-10">
                                            <button disabled={this.state.disable}
                                                onClick={this.createNotificationModal}
                                                type="submit" className="btn btn-primary">
                                                {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                Create Notification</button>
                                            <button disabled={this.state.resetDisable} style={{ marginLeft: '20px' }} onClick={this.setUpAgain} type="submit" className="btn btn-primary">
                                                Reset</button>
                                            <button disabled={this.state.repeatDisable} style={{ marginLeft: '20px' }} onClick={() => {
                                                this.setState({ fieldDisable: false, disable: false, repeatDisable: true })
                                            }} type="submit" className="btn btn-primary">
                                                Repeat</button>
                                        </div>
                                    </div>
                                    <Snackbar
                                        anchorOrigin={{
                                            horizontal: "right",
                                            vertical: "bottom",
                                        }}
                                        open={this.state.open}
                                        autoHideDuration={500000}
                                        width={2000}
                                        message="Notification send."
                                        onClose={this.handleToClose}
                                        action={
                                            <React.Fragment>
                                                {this.state.undoConfirmation === true ?
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <p style={{ marginTop: '16px', cursor: 'pointer', marginLeft: '450px' }}>
                                                            Sure you want to undo?</p>
                                                        <p onClick={this.deleteTaskYes} style={{ marginTop: '16px', cursor: 'pointer', marginLeft: '10px' }}>
                                                            {this.state.taskDeleteLoader === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                                            Yes</p>
                                                        <p onClick={this.deleteTaskNo} style={{ marginTop: '16px', cursor: 'pointer', marginLeft: '20px' }}>No</p>
                                                    </div> : null
                                                }
                                                <p onClick={this.deleteTaskConfirmation} style={{
                                                    marginTop: '16px', marginLeft: this.state.undoConfirmation === true ? '50px' : '500px',
                                                    marginRight: '20px', cursor: 'pointer'
                                                }}>Undo</p>
                                                <IconButton
                                                    size="small"
                                                    aria-label="close"
                                                    color="inherit"
                                                    onClick={this.handleToClose}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </React.Fragment>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CreatePermissionModal title="Send Notification" HeaderTitle="Send Notification Permission" loading={this.state.isLoading} disable={this.state.disable} msg={'Are you sure you want to send this Notification' + this.props.allProjects.platform_subscriber_entity} showCreatePermissionModal={this.state.CreatePermissionModalShow}
                    onCloseCreatePermissionModal={() => {
                        this.setState({
                            AddRoleModalShow: false
                        })
                    }}
                    callback={this.createNotification}
                >
                </CreatePermissionModal>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    auth: state.authReducer,
    allProjects: state.projectReducer.allProjects,
    projectTask: state.projectReducer.projectTask,

})

export default connect(mapStateToProps, { setAllProjects, setProjectTaskByID })(CreateNotification)