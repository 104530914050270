import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment"
import isEmpty from "../../utils/isEmpty"
import LoadingOverlay from "../../Loader";
import { setAllSubsciber } from "../../actions/email"
import { fetchAllSubscriber } from "../../api/email/email"
import { fetchAllEmails } from "../../api/email/email";
import config from "../../config/index";
import { toastMessageFailure } from "../../utils/toastMessage";

class SubscriberList extends Component {

    state = {
        isLoading: true,
        disable: "",
        limit: config.limit,
        pageNumber: 1,
        EmailListModalShow: false,
        totalPages: 0,
        renderParams: {},
        isDataLoading: true
    }

    getSubscriberList = async () => {
        try {
            this.setState({ isDataLoading: true });
            const { data } = await fetchAllSubscriber()
            this.props.setAllSubsciber(data.data);
            this.setState({ isDataLoading: false });
        }
        catch (e) {
            if (e?.response?.status === 404) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
                return
            }
            toastMessageFailure("Fail to get email ist")
            this.setState({ isDataLoading: false });
        }
    }

    componentDidMount = async () => {
        this.getSubscriberList()

    }

    componentWillReceiveProps(nextProps) {

        if (this.props.projectChange !== nextProps.projectChange) {
          
            this.getSubscriberList()
        }
    }
    onNextBtnClick = () => {
        this.setState({ ...this.state, pageNumber: this.state.pageNumber + 1 }, () => {
            fetchAllEmails(this.state.limit, this.state.pageNumber).then(({ data }) => {
                this.props.setAllEmail(data.data);
                this.props.setEmailsCounts(data.total_count)
            }).catch(e => {
            });
        })
    }

    onPreviousBtnClick = () => {
        this.setState({ ...this.state, pageNumber: this.state.pageNumber - 1 }, () => {
            fetchAllEmails(this.state.limit, this.state.pageNumber).then(({ data }) => {
                this.props.setAllEmail(data.data);
            }).catch(e => {
            });
        })
    }
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.allSubscriber) {
    //         this.setState({ isDataLoading: false })
    //     }


    // }

    render() {

        return (
            <>
                <div className="mb-5">
                    <div className="container-fluid">
                        {/* <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Subscriber User List (This is our NewsLetter Subscriber User List Page)</h4>
                                <select value={this.state.key} name="key" onChange={this.fetchEmail} style={{ maxWidth: "30%" }} className="form-control">
                                    <option>Select Publisher</option>
                                    {
                                        this?.props?.allProjects?.map((key, idx) =>
                                            <React.Fragment key={idx}>
                                                <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                            </React.Fragment>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div> */}
                        <div className="row">
                            {!isEmpty(this.props.allSubscriber) ? (
                                this.props.allSubscriber.map((item) => (
                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="font-size-16 mt-2">Email: {item.email}</h5>

                                                <div className="text-muted mt-2 row">
                                                    <div className="col-3">
                                                        <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" /> Platform ID</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">{item.platform_subscriber_id}</p>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4 col-6">
                                                        <div className="mt-3">
                                                            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary" /> Creation Date</h5>
                                                            <p className="text-muted mb-0">{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : this.state.isDataLoading === true ?


                                <LoadingOverlay></LoadingOverlay>
                                : <label className="text-center text-secondary">No subscriber found!</label>}



                            {/* {
                            this.state.isLoading && (
                                <div style={{ textAlign: 'center' }}>
                                    {this.props.waitingFor.includes('fetch-email-loader') ? (
                                        <LoadingOverlay></LoadingOverlay>
                                    ) : null}
                                </div>
                            )
                        }
                        {
                            <div className="d-flex justify-content-end">
                            {
                                this.state.pageNumber != 1 && (
                                    <span onClick={this.onNextBtnClick} className="mr-5 pointer">Previous</span>
                                )
                            }
                            {
                                this.state.totalPages !== this.state.pageNumber && (
                                    <span onClick={this.onPreviousBtnClick} className="pointer">Next</span>
                                )
                            }
                        </div>
                        } */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    allEmail: state.emailReducer.allEmail,
    allSubscriber: state.emailReducer.allSubscriber,
    allProjects: state.projectReducer.allProjects,
    totalEmail: state.emailReducer.totalEmail,
    totalPages: state.emailReducer.totalPages,
    waitingFor: state.commonReducer.waitingFor,
    projectChange: state.projectReducer.projectChange
})

export default connect(mapStateToProps, { setAllSubsciber })(SubscriberList)






