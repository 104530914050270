import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAlarmSubscribers,alarmUserRecipient } from '../../../../api/alarm/alarm';
import { removeConfigureUserAPI } from '../../../../api/general/general';
import { toastMessageSuccess, toastMessageFailure } from '../../../../utils/toastMessage';
import YesOrNoModal from '../../YesOrNoModal';
import LoadingOverlay from '../../../../Loader';

class AlarmConfigurationModal extends Component {

    state = {
        listSusbcribers: [],
        data: {
            platform_subscriber_entity: null,
            platform_subscriber: -1,
            email_host: -1,
            html_template: -1,
            receiver: "",
        },
        message: null,
        isError: false,
        isLoading: false,
        taskID: "",
        userID: "",
        yesOrNoModalShow: false,
        // isDeleteLoading: false
    }

    onChangeListener = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.id != this.props.data.id) {
            const { platform_subscriber, email_host_id, html_template_id, platform_subscriber_entity } = nextProps.data;
            this.setState({ ...this.state, data: { platform_subscriber_entity, platform_subscriber, email_host: email_host_id, html_template: html_template_id } });
            getAlarmSubscribers(platform_subscriber).then(({ data: { data } }) => {
                this.setState({ ...this.state, listSusbcribers: data });
            }).catch(e => { })
        }
    }

    addAlarmReceiverUserModal = async (e) => {
        e.preventDefault();
        if (this.state.data.platform_subscriber === '' || this.state.data.platform_subscriber === null || this.state.data.platform_subscriber === "Select Publisher") {
            return this.setState({ ...this.state, isError: true, message: 'Publisher key required!' });
        }


        try {
            this.setState({ isError: false, isLoading: true, disable: true });

            const { data } = await alarmUserRecipient({
                "platform_subscriber": this.state.data.platform_subscriber,
                "receiver": this.state.data.receiver
            });
            const { platform_subscriber } = this.state.data;

            getAlarmSubscribers(platform_subscriber).then(({ data: { data } }) => {
                this.setState({ ...this.state, listSusbcribers: data });
            }).catch(e => { })

            // this.setState({ taskID: data.task })
            this.setState({ isError: false, isLoading: false, disable: false });

            
            // this.clearForm()
            toastMessageSuccess("Configuration add successfully")
        }
        catch (err) {
            // console.log("errror", err.response)
            if (err?.response?.status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            else {
                this.setState({ isError: true, isLoading: false, disable: false, message: "Sorry! We are facing some issues to add configuration. Please try again later." });
            }
        }
    }

    deleteConfigureUser = async (userID) => {
        // debugger
        this.setState({ isLoading: true, disable: true })
        try {
            // this.setState({ isLoading: userID, disable: "none" });
            this.setState({ isLoading: true, disable: true })
            const { data } = await removeConfigureUserAPI(userID);
            this.setState({ isLoading: false, disable: "" });

            const { platform_subscriber } = this.state.data;

            getAlarmSubscribers(platform_subscriber).then(({ data: { data } }) => {
                this.setState({ ...this.state, listSusbcribers: data });
            }).catch(e => { })
            toastMessageFailure("Configuration Remove successfully")
        }
        catch (err) {
            if (err?.response?.status === 401) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            this.setState({ isLoading: false, disable: "" });
        }
    }

    render() {
        return (
            <React.Fragment>
                <button style={{ display: 'none' }} id="alarm-config-btn" type="button" className="btn btn-primary" data-toggle="modal" data-target="#alarm-config-modal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="alarm-config-modal" tabindex="-1" role="dialog" aria-labelledby="alarm-config-modal-title" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '50%' }} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{this.state.data.platform_subscriber_entity || 'N/A'} Configuration</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form className="form-horizontal row">
                                    <div className="mb-3 col-6">
                                        <label htmlFor="notificationname" className="col-form-label">Project*</label>
                                        <select disabled value={this.state.data.platform_subscriber} onChange={this.onChangeListener} name="platform_subscriber" className="form-control">
                                            <option>Select Project</option>
                                            {
                                                this?.props?.allProjects?.map((key, idx) =>
                                                    <React.Fragment key={idx}>
                                                        <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                    </React.Fragment>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label htmlFor="notificationname" className="col-form-label">Email Host*</label>
                                        <select value={this.state.data.email_host} onChange={this.onChange} name="email_host" className="form-control">
                                            {
                                                this?.props?.emailHosts?.map((email) =>
                                                    <React.Fragment key={email.id}>
                                                        <option key={email.id} value={email.id}>{email.email}</option>
                                                    </React.Fragment>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label htmlFor="notificationname" className="col-form-label">Email Template*</label>
                                        <select value={this.state.data.html_template} onChange={this.onChange} name="html_template" className="form-control">
                                            <option>Select Email</option>
                                            {
                                                this?.props?.allTemplate?.map((template) =>
                                                    <React.Fragment key={template.id}>
                                                        <option key={template.id} value={template.id}>{template.name}</option>
                                                    </React.Fragment>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label htmlFor="notificationname" className="col-form-label">Add User</label>
                                        <div className='row'>
                                            <div className='col-9'>
                                                {/* <input value={this.state.receiver} onChange={this.onChangeListener} id="notificationname" name="receiver" type="text" className="form-control" placeholder="Add User" /> */}
                                                <input type="text" className="form-control" placeholder='Add User' onChange={this.onChangeListener} value={this.state.data.receiver} name="receiver" />
                                            </div>
                                            <div className='col-3'>
                                                <button onClick={this.addAlarmReceiverUserModal} className="btn btn-primary waves-effect waves-light">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Publisher</th>
                                                    <th>Receiver</th>
                                                    <th>Create Time</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    this.state.listSusbcribers.map((item) =>
                                                        <tr key={item.id}>
                                                            <td scope="row">{item.id}</td>
                                                            <td>{this.state.data.platform_subscriber_entity}</td>
                                                            <td style={{ maxWidth: "175px" }}>{item.receiver}</td>
                                                            <td>{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                            <td>
                                                                <span class="badge badge-dot mr-4">
                                                                    <span class="status">
                                                                        <button onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState({ ...this.state, yesOrNoModalShow: true, userID: item.id });
                                                                        }}
                                                                            className="btn btn-primary waves-effect waves-light">
                                                                            {this.state.isLoading === true ? <LoadingOverlay></LoadingOverlay> : null}
                                                                            Remove
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        this.state.isError ?
                                            <div>
                                                <div className="mb-3 col-12 alert alert-danger">
                                                    {this.state.message}
                                                </div>
                                            </div> : null
                                    }
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <YesOrNoModal title="Delete Configure User" msg={'Are you sure you want to remove this User?'} showYesOrNoModal={this.state.yesOrNoModalShow}
                    onCloseYesOrNoModal={(value) => {
                        if (value === true) {
                            this.setState({ yesOrNoModalShow: false }, () => {
                                this.deleteConfigureUser(this.state.userID)
                            })
                        } else {
                            this.setState({ yesOrNoModalShow: false })
                        }
                    }}
                >
                </YesOrNoModal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    allProjects: state.projectReducer.allProjects,
    allTemplate: state.emailReducer.allTemplate,
    emailHosts: state.emailReducer.emailHosts
})

export default connect(mapStateToProps)(AlarmConfigurationModal);