import { createAlarm } from "../../api/general/general";
import React, { Component } from "react";
import Select from 'react-select';
import { connect } from "react-redux";
import { setEmailTemplate } from "../../actions/email"
import { sendEmail, sendEmailToAllUser, fetchEmailTemplate, emailHostListApi } from "../../api/email/email"
import { setEmailHost } from "../../actions/email"
import { getLoginUserDetail } from "../../api/authentication/authentication"
import { toastMessageSuccess } from "../../utils/toastMessage";

class CreateAlarms extends Component {

    state = {
        key: "",
        receiver: "",
        sender: "@NUCOIN",
        title: "",
        message: "",
        is_mobile_push: false,
        notificationType: "",
        email_host: null,
        to_email: "",
        subject: "",
        html_template_id: null,
        is_all_user: false,
        sendToAllUsers: false,
        isError: false,
        fieldDisable: false,
        alertMessage: "",
    }
    onChangePublisher = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        })
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name.concat('error')]: '',
        });

    }
    onChangeTemplate = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
        if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined && e.target.value !== "Select Email Template") {
            const user = this?.props?.allTemplate.find(u => u.id == e.target.value);
            this.setState({
                selectedTemplate: user
            }, () => {
                this.setState({ renderParams: this.state.selectedTemplate.render_param }, () => {
                    console.log("user", this.state.renderParams)
                })
            });
        }
        else {
            this.setState({ selectedTemplate: "", renderParams: [] })

        }
    }
    componentDidMount = async () => {

        try {
            const { data } = await getLoginUserDetail(localStorage.getItem("jwtToken"))
            this.setState({ ...this.state, data: { from_email: data?.user?.email } });
        }
        catch (e) {
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.emailHosts.length !== nextProps.emailHosts.length) {
            if(nextProps.emailHosts.length !== 0) this.setState({ ...this.state, data: { ...this.state.data, email_host: nextProps.emailHosts[0].id } });
        }
    }

    createAlarm = async () => {
        // debugger
        if (this.state.key === '' || this.state.key === null || this.state.key === "Select Publisher") {
            return this.setState({ isError: true, alertMessage: 'Publisher key required!' });
        }
        else if (this.state.sender === '' || this.state.sender === null) {
            return this.setState({ isError: true, alertMessage: 'Sender name required!' });
        }
        else if (this.state.receiver === '' && this.state.sendToAllUsers === false || this.state.receiver === null && this.state.sendToAllUsers === false) {
            return this.setState({ isError: true, alertMessage: 'Receiver name required!' });
        }
        else if (this.state.title === '' || this.state.title === null) {
            return this.setState({ isError: true, alertMessage: "Title required!" });
        }
        else if (this.state.message === '' || this.state.message === null) {
            return this.setState({ isError: true, alertMessage: 'Message Description required!' });
        }
        // else if ((this.state.email_host === '' || this.state.email_host === null || this.state.email_host === undefined) && this.state.sendToAllUsers === false) {
        //     return this.setState({ isError: true, alertMessage: 'To email field is required. Write to email or select all users!' });
        // }
        else if ((this.state.to_email === '' || this.state.to_email === null || this.state.to_email === undefined) && this.state.sendToAllUsers === false) {
            return this.setState({ isError: true, alertMessage: 'To email field is required. Write to email or select all users!' });
        }
        else if (this.state.subject === '' || this.state.subject === null || this.state.subject === undefined) {
            return this.setState({ isError: true, alertMessage: 'Subject field is required!' });
        }
        else if (this.state.html_template_id === '' || this.state.html_template_id === null || this.state.html_template_id === undefined || this.state.html_template_id === "Select Email Template") {
            return this.setState({ isError: true, alertMessage: 'Select email template!' });
        }
        // else if (this.wordCount(this.state.title) > 48) {
        //     return this.setState({ isError: true, message: 'title is too long.Please reduce it.!' });
        // }
        // else if (this.wordCount(this.state.des) > 10000) {
        //     return this.setState({ isError: true, message: 'Message is too long.Please reduce it.!' });
        // }
        try {
            let receiver
            const multilpleReceiver = this.state.receiver.includes(",")
            if (multilpleReceiver) {
                receiver = await Promise.all(this.state.receiver.replaceAll('\n', '').split(","));
            }
            else if (this.state.sendToAllUsers) {
                receiver = "*"
            }
            else {
                receiver = this.state.receiver
            }
            let emailReceiver
            const multilpleEmailReceiver = this.state.to_email.includes(",")

            if (multilpleEmailReceiver) {
                emailReceiver = await Promise.all(this.state.to_email.replaceAll('\n', '').split(",").map(_ => _.trim()));
            }
            else if (this.state.sendToAllUsers) {

                emailReceiver = "send_to_all_db_customers"
            }
            else {
                emailReceiver = this.state.to_email
            }
            console.log("Object",
            {
                "platform_subscriber": this.state.key,
                "notification_data": {
                    "platform_subscriber_id": this.state.key,
                    "sender": '@NUCOIN',
                    "receiver": receiver,
                    "title": this.state.title,
                    "message": this.state.message,
                    "is_mobile_push": this.state.is_mobile_push,
                    "category": this.state.notificationType,
                },
                "email_data": {
                    "subject": this.state.subject,
                    "is_all_user": this.state.sendToAllUsers,
                    "to_email": emailReceiver,
                    "email_host": parseInt(this.state.email_host),
                    "html_template_id": parseInt(this.state.html_template_id),
                }
            })

            return

            const { data } = await createAlarm({
                "platform_subscriber": this.state.key,
                "notification_data": {
                    "platform_subscriber_id": this.state.key,
                    "sender": '@NUCOIN',
                    "receiver": receiver,
                    "title": this.state.title,
                    "message": this.state.message,
                    "is_mobile_push": this.state.is_mobile_push,
                    "category": this.state.notificationType,
                },
                "email_data": {
                    "subject": this.state.subject,
                    "is_all_user": this.state.sendToAllUsers,
                    "to_email": emailReceiver,
                    "email_host": parseInt(this.state.email_host),
                    "html_template_id": parseInt(this.state.html_template_id),
                }
            });
            // this.setState({ taskID: data.data.id })
            this.setState({ isError: false, isLoading: false, alertMessage: "", open: true, fieldDisable: true, repeatDisable: false, resetDisable: false });
            toastMessageSuccess("Notification send successfully")
        }
        catch (err) {
            // console.log("errror", err.response)
            if (err?.response?.status === 401) {

                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
            }
            else {
                this.setState({ isError: true, isLoading: false, disable: false, resetDisable: false, alertMessage: err?.response?.data?.alertMessage ? err?.response?.data?.alertMessage : "Sorry! We are facing some network issues. Please try again later." });
            }
        }
    }


    render() {
        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Send Alarm Here (This is our Alarm Page)</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 col-xl-8">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Admin !</h5>
                                                <p>Compose your Alarm here.</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="auth-logo">
                                        <a href="" className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="/assets/images/logo.svg" alt="" className="rounded-circle" height={34} />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                        <form className="form-horizontal row">
                                            <div className="mb-3 col-12">
                                                <label htmlFor="notificationname" className="col-form-label">Publisher *</label>
                                                <select disabled={this.state.fieldDisable} value={this.state.key} onChange={this.onChangePublisher} name="key" className="form-control">
                                                    <option>Select Publisher</option>
                                                    {
                                                        this?.props?.allProjects?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option key={key.platform_subscriber_id} value={key.platform_subscriber_id}>{key.platform_subscriber_entity}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="receiver" className="form-label">Receiver *</label>
                                                <textarea disabled={this.state.sendToAllUsers || this.state.fieldDisable} id="receiver" name="receiver" value={this.state.receiver} rows={3} onChange={this.onChange} type="text" className="form-control" placeholder="Enter Receiver Name" />
                                                {/* <input id="receiver" name="receiver" value={this.state.receiver} onChange={this.onChange} type="text" className="form-control" placeholder="Enter Receiver Name" /> */}
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="title" className="form-label">Title *</label>
                                                <input disabled={this.state.fieldDisable} id="title" name="title" value={this.state.title} onChange={this.onChange} type="text" className="form-control" placeholder="Enter Notification Title" />
                                            </div>
                                            <div className="mb-3 col-12">
                                                <label htmlFor="message" className="form-label">Message *</label>
                                                <textarea disabled={this.state.fieldDisable} id="message" name="message" value={this.state.message} rows={3} onChange={this.onChange} type="text" className="form-control" placeholder="Enter Message Here" />
                                            </div>
                                            <div className="mb-3 col-12">
                                                <label title="Send To All DB Customer" className="form-label">Is Mobile Push *</label>
                                                <input value={this.state.is_mobile_push} type="checkbox" checked={this.state.is_mobile_push} onClick={() => {
                                                    this.setState({ ...this.state, is_mobile_push: !this.state.is_mobile_push })
                                                }} name="is_mobile_push" className="d-block" />
                                            </div>
                                            <div className="mb-3 col-12">
                                                <label htmlFor="notificationname" className="col-form-label">Notification Type *</label>
                                                <select value={this.state.notificationType} onChange={this.onChange} name="notificationType" className="form-control">
                                                    <option>Select Notification Type</option>
                                                    <option value="standard">Standard</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-12">
                                                <hr />
                                                <h4 class="card-title mb-4">Email Data</h4>
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">From Email *</label>
                                                <select disabled={this.state.fieldDisable} className="form-control" name="email_host" onChange={this.onChange}>
                                                    <option value="Select">Select Email</option>
                                                    {
                                                        this.props.emailHosts.map(_ => <option key={_.id} value={_.id}>{_.email}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">To Email *</label>
                                                <textarea disabled={this.state.sendToAllUsers || this.state.fieldDisable} rows={3} onChange={this.onChange} value={this.state.to_email} id="notificationname" name="to_email" type="text" className="form-control" placeholder="Enter Receiver Email" />
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">Subject *</label>
                                                <input disabled={this.state.fieldDisable} value={this.state.subject} onChange={this.onChange} id="notificationname" name="subject" type="text" className="form-control" placeholder="Enter Email Subject" />
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="" className="form-label">Select Template *</label>

                                                <select disabled={this.state.fieldDisable} value={this.state.html_template_id} onChange={this.onChangeTemplate.bind(this)} name="html_template_id" id="taskValue" className="form-control">
                                                    <option>Select Email Template</option>
                                                    {
                                                        this?.props?.allTemplate?.map((key, idx) =>
                                                            <React.Fragment key={idx}>
                                                                <option id={key.id} key={key.id} value={key.id} >{key.name}</option>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div style={{ display: 'none' }} className="form-check col-12 mb-3">
                                                <label>
                                                    Send to all users
                                                </label>
                                                <input className="d-block" value={this.state.sendToAllUsers} type="checkbox" checked={this.state.is_all_user} onClick={() => {
                                                    this.setState({ ...this.state, is_all_user: !this.state.is_all_user })
                                                }} name="sendToAllUsers" />
                                            </div>
                                            {
                                                this.state.isError ?
                                                    <div className="row mb-4">
                                                        <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                                        <div className="col-lg-10 alert alert-danger">
                                                            {this.state.alertMessage}
                                                        </div>
                                                    </div> : null
                                            }
                                        </form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button onClick={this.createAlarm} type="submit" className="btn btn-primary">
                                                    Create Alarm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allProjects: state.projectReducer.allProjects,
    allTemplate: state.emailReducer.allTemplate,
    emailHosts: state.emailReducer.emailHosts,

})
export default connect(mapStateToProps, { setEmailTemplate, setEmailHost })(CreateAlarms)