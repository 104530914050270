

import invoke from '../../utils/invoke';
import config from '../../config';

export const addProject = (data) => {
    // data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: 'v2/projects/create/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    }
    );
}

export const removeProject = (projectID) => {
    // data = encrypt(data);
    return invoke({
        method: 'Put',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: `api/projects/remove/${projectID}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    }
    );
}

export const removeNotificationAPI = (notificationId) => {
    // data = encrypt(data);
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: `v2/notifications/rm/${notificationId}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    }
    );
}

export const removeEmailHostAPI = (id) => {
    // data = encrypt(data);
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: `v2/emails/emailhost/rm/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    }
    );
}


export const removeTemplateListAPI = (id) => {
    // data = encrypt(data);
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: `v2/emails/template/rm/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    }
    );
}

export const updateTemplateListAPI = (id) => {
    // data = encrypt(data);
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: `v2/emails/template/update/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    }
    );
}

export const createNotification = (data, Id) => {

    //data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: 'v2/notifications/create/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}

export const createAlarm = (data) => {
    console.log("Bilal", data)
    // data = encrypt(data);
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: 'v2/alarms/create/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key"),
        }
    });
}

export const removeConfigureUserAPI = (id) => {
    // data = encrypt(data);
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        //baseURL: contactUs,
        route: `v2/alarms/recipient/rm/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    }
    );
}