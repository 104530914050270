

import invoke from '../../utils/invoke';
import config from '../../config';


export const registerEmailHost = (data) => {
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'v2/emails/host/create/',
        data: {...data, "platform_subscriber": localStorage.getItem("key")},
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        },
    });
}

export const emailHostListApi = () => {
    //debugger
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: 'v2/emails/host/list/',
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}

export const createEmailTemplate = (data) => {
    console.log("params ->",localStorage.getItem("key"),localStorage.getItem("jwtToken"))
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'v2/emails/template/create/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}

export const updateEmailTemplate = (data,templateID) => {
   
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        route: `v2/emails/template/up/${templateID}/`,
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}

export const sendEmail = (data) => {

    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'v2/emails/send/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}



export const sendEmailToAllUser = (data) => {

    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'api/emails/send/all/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}

export const fetchEmailTemplate = () => {

    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: 'v2/emails/templates/',
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}

export const fetchAllEmails = (limit=20,page=0) => {
// debugger
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/emails/?page=${page}`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}

export const fetchAllSubscriber = () => {

    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        // route: `v2/news-letters/newsletter/list/?page=${page}`,
        route: `v2/news-letters/newsletter/subscribers/list/`,
        headers: {
            "platform-subscriber-id": localStorage.getItem("key"),
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}


export const fetchAllNewsLetterEmail = (limit=20,page=0) => {

    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
       
        route: `v2/news-letters/`,
        headers: {
            "platform-subscriber-id":localStorage.getItem("key"),
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}




export const sendNewsLetter = (data) => {

    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'v2/news-letters/place/mail/newsletter/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id":localStorage.getItem("key"),
        }
    });
}


