import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { changePasswordByAdmin } from '../../../../api/task/task';
export default class ChangePass extends React.Component {

    state = {
        password: null,


    }
    closeModal = (value) => {
        this.props.onCloseChangePassModal(value)
    }


    changePassFunc = async () => {
        var data = {
            "password":this.state.password
        }
        changePasswordByAdmin(this.props.userId,data ).then(({ data: { data } }) => {
            this.setState({ isLoading: false })
            this.setState({ isLoading: false, disable: false, changePasswordByAdmin: false });
            this.props.callback()
        }).catch(e => {
            this.setState({ isLoading: false })
        });
    }

    
    render() {
        return (
            // <Modal dialogClassName="InfoSuccess"
            <Modal
                show={this.props.showChangePassModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Change Your Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input value={this.state.password} onChange={e => {
                        e.preventDefault();
                        this.setState({ password: e.target.value });
                    }} type="password" className='form-control mb-3' placeholder='Change Password' />
                    <div className='text-center'>
                        <button type="button" class="btn btn-success Cancel" onClick={() => { 
                            this.changePassFunc();
                            this.closeModal(true);
                            }} style={{ marginRight: '10px' }}>
                            <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
                            {/* {this.props.isLoading === true ? <SpinnerLoader /> : "Yes"} */}
                            Yes

                        </button>
                        <button disabled={this.props.disable} type="button" class="btn btn-primary Submit " onClick={() => { this.closeModal(false) }}>
                            <i class="bx bx-smile font-size-16 align-middle me-2"></i>
                            No
                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        )
    }


}