

import invoke from '../../utils/invoke';
import config from '../../config';


export const createStaffUser = (data) => {
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: 'v2/accounts/create/',
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}


export const updatePermission = (data, id) => {
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: `v2/permissions/update-permissions/${id}/`,
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}


export const createRole = (data) => {
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: `v2/permissions/role-create/`,
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken")
        }
    });
}


export const getRoleList = () => {
    return invoke({
        method: 'GET',
        baseURL: config.notificationUrl,
        route: `v2/permissions/roles/all/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id":localStorage.getItem("key")
        }
    });
}



export const deleteRole = (id) => {
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        route: `v2/permissions/role-rm/${id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id":localStorage.getItem("key")
        }
    });
}

export const grantPermission = (data) => {
    return invoke({
        method: 'POST',
        baseURL: config.notificationUrl,
        route: `v2/permissions/role-create/`,
        data: data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}


export const updateUserRoleApi = (username,role_id) => {
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        route: `v2/accounts/add-role/${username}/${role_id}/`,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}



export const updateUserRoleDataApi = (role_id,data) => {
    return invoke({
        method: 'PUT',
        baseURL: config.notificationUrl,
        route: `v2/permissions/update-permissions/${role_id}/`,
        data:data,
        headers: {
            Authorization: "JWT" + " " + localStorage.getItem("jwtToken"),
            "platform-subscriber-id": localStorage.getItem("key")
        }
    });
}

