import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux';
import { updateUserRoleApi } from '../../../../api/permission/permission';
import Select from 'react-select';

class AddRole extends React.Component {


    state = {
        user: null,
        roleId: null,
        roleName: null
    }

    closeModal = (value) => {
        this.props.onCloseAddRoleModal(value)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.user.id !== nextProps.user.id) {
            this.setState({ ...this.state, user: nextProps.user, roleId: nextProps.user?.role.id, roleName:nextProps.user?.role.name });
        }
    }

    clickHandler = (value) => {
        this.props.onCloseAddRoleModal(value)

        updateUserRoleApi(this.state.user.username, this.state.roleId).then(({ data: { data } }) => {
            console.log("Data", data)
            this.setState({ isLoading: false })
            this.setState((state) => ({ ...state, userList: data }));
            this.props.callback()
        }).catch(e => {
            this.setState({ isLoading: false })
        });

    }



    render() {
        return (
            // <Modal dialogClassName="InfoSuccess"
            <Modal
                show={this.props.showAddRoleModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit User Role
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>

                    {/* <div class="btn-group mb-2 mr-3" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked="" />
                        <label class="btn btn-secondary" for="btnradio1">Admin</label>
                    </div>
                    <div class="btn-group mb-2" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
                        <label class="btn btn-secondary" for="btnradio2">Editor</label>
                    </div> */}

                    <div className="form-group text-left">
                        <label htmlFor="">Search User Role*</label>
                        <Select 
                            onChange={({ value, label}) => {
                                this.setState({ ...this.state, roleId: value, roleName: label });
                            }}
                        isMulti={false} defaultValue={{ value: this.state.roleId, label: this.state.roleName }} options={this.props.roleList.map(({ id, name }) => ({ value: id, label: name }))} />
                    </div>

                    <hr />

                    <div style={{ display: 'none' }} className='wrapperOptionScroll'>
                        <div class="wrapperOption mb-3">
                            <input type="radio" name="select" id="option-1" />
                            <label for="option-1" class="option">
                                <div class="dot"></div>
                                <span>Admin</span>
                            </label>
                        </div>
                        <div class="wrapperOption mb-3">
                            <input type="radio" name="select" id="option-2" />
                            <label for="option-2" class="option">
                                <div class="dot"></div>
                                <span>Editor</span>
                            </label>





                            {this.props.roleList.map((item) => (

                                <div key={item.id} onClick={() => {
                                    this.setState({ ...this.state, roleId: item.id });
                                }} className="wrapperOption mb-3" >
                                    <input type="radio" name="select" id={item.id} checked={item.id === this.state.roleId} />
                                    <label for={item.id} className="option">
                                        <div className="dot"></div>
                                        <span>{item.name}</span>
                                    </label>
                                </div>



                            )
                            )
                            }
                        </div>
                    </div>

                    <div className='pt-3'>
                        <button type="button" class="btn btn-success Cancel" onClick={() => {
                            this.clickHandler(true);
                        }} style={{ marginRight: '10px' }}>
                            <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
                            Yes

                        </button>
                        <button disabled={this.props.disable} type="button" class="btn btn-primary Submit " onClick={() => { this.closeModal(false) }}>
                            <i class="bx bx-smile font-size-16 align-middle me-2"></i>
                            No
                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        )
    }


}


const mapStateToProps = (state) => ({
    roleList: state.projectReducer.roleList
})


export default connect(mapStateToProps, null)(AddRole)