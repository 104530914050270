import React, { Component } from "react";
import Chart from "react-apexcharts";
import { fetchNotificationLog } from "../../api/task/task";
import LoadingOverlay from "../../Loader";

class DonutChart extends Component {



    constructor(props) {
        super(props);

        this.state = {

            // series: [],
            // labels: [],
            projects: [],
            options: {
                series: [],
                labels: [],
                chart: {
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }

    componentDidMount() {
        // Fetch Notification Log
        this.setState({ isLoading: true });
        fetchNotificationLog().then(({ data: { data } }) => {
            // this.setState((state) => ({...state, Projects: data}));
            const series = data.projects.map((obj) => {
                return obj.notification_count;
            })
            const labels = data.projects.map((obj) => {
                return obj.platform_subscriber_entity;
            })
            this.setState({ ...this.state, options: { ...this.state.options, series, labels } })
            this.setState({ isLoading: false });
        }).catch(e => {
            this.setState({ isLoading: false });
        });
    }



    render() {
        return (
            <>
            {this.state.isLoading === true ? <LoadingOverlay /> : null}
                <div id="chart">
                    <Chart options={this.state.options} series={this.state.options.series} width={588} type="donut" />
                </div>
            </>
        );
    }
}

export default DonutChart;