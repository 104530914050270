import { Switch, BrowserRouter } from 'react-router-dom';
import React from "react";
import { Provider } from 'react-redux';
import PrivateRoute from "./utils/PrivateRoute"
import store from './store';
import jwt_decode from 'jwt-decode'
import { setCurrentUser } from './actions/auth'
import PublicRoute from './utils/PublicRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Login from './components/Auth/Login/Login';
import Home from './components/Home/Home';
import ProjectListing from './components/Notification/Project/ProjectListing';
import CreateProject from './components/Notification/Project/CreateProject';
import CreateNotification from './components/Notification/CreateNotification';
import ProjectNotification from './components/Notification/Project/ProjectNotification';
import CreateTask from "./components/Notification/Task/CreateTask";
import ProjectTask from "./components/Notification/Project/ProjectTask";
import userRecepientNotification from './components/RecepientNotification/userRecepientNotification';
import UpdateTemplate from './components/Email/UpdateTemplate/UpdateTemplate';
import CreatEmailTemplate from "./components/Email/CreatEmailTemplate"
import TemplateList from "./components/Email/TemplateList"
import SendEmail from "./components/Email/SendEmail"
import EmailList from "./components/Email/EmailList"
import AOS from "aos";
import 'fontawesome';
import "aos/dist/aos.css";
import RegisterHost from './components/Email/RegisterHost/RegisterHost';
import EmailHostList from './components/Email/EmailHostList/EmailHostList';
import NewsEmailList from "./components/NewsLetter/NewsEmailList"
import CreateNewsLetter from "./components/NewsLetter/CreateNewsLetter"
import SubscriberList from "./components/NewsLetter/SubscriberList"
import CreateAlarms from './components/Alarms/CreateAlarm';
import AlarmList from './components/Alarms/AlarmList';
import AlarmProjectList from './components/Alarms/AlarmProjectList';
import AlarmConfigList from './components/Alarms/AlarmConfigList';
import AlarmConfiguration from './components/Alarms/AlarmConfiguration';
import Header from './components/Layout/Header';
import LeftSiderBar from './components/Layout/LeftSiderBar';
import Footer from './components/Layout/Footer';
import CreateStaffUser from './components/Permission/CreateStaffUser';
import CreateRole from './components/Permission/CreateRole';
import RoleList from './components/Permission/RoleList/RoleList';
import PlatformUserList from './components/Permission/PlatformUserList/PlatformUserList';
import BreadCrumbs from './components/BreadCrumbs/BreadCrumbs';
import { Route } from 'react-router-dom';

AOS.init();

if (localStorage.jwtToken) {
    const decoded = jwt_decode(localStorage.jwtToken)
    store.dispatch(setCurrentUser(decoded))
}

function App() {

    return (
        <Provider store={store}>

            <BrowserRouter>
                <div id={store.getState().authReducer.isAuthenticated ? "layout-wrapper" : ""}>
                    
                    {
                        store.getState().authReducer.isAuthenticated && (
                            <React.Fragment>
                                <Header></Header>
                                <LeftSiderBar></LeftSiderBar>
                                <BreadCrumbs />
                            </React.Fragment>
                        )
                    }
                    <div className={store.getState().authReducer.isAuthenticated ? "main-content" : ""}>
                        <Switch>
                            <PublicRoute exact path="/login" component={Login} />
                            <PrivateRoute exact path="/" component={Home} />
                            <PrivateRoute exact path="/notification/create-notification" component={CreateNotification} />
                            <PrivateRoute exact path="/notification/project-task" component={ProjectTask} />
                            <PrivateRoute exact path="/project-listing" component={ProjectListing} />
                            <PrivateRoute exact path="/create-project" component={CreateProject} />
                            <PrivateRoute exact path="/project-notification" component={ProjectNotification} />
                            <PrivateRoute exact path="/create-task" component={CreateTask} />
                            <PrivateRoute exact path="/recipient-list/:notification_id" component={userRecepientNotification} />
                            <PrivateRoute exact path="/update-template/:template_id" component={UpdateTemplate} />
                            <PrivateRoute exact path="/create-email-template" component={CreatEmailTemplate} />
                            <PrivateRoute exact path="/template-list" component={TemplateList} />
                            <PrivateRoute exact path="/send-email" component={SendEmail} />
                            <PrivateRoute exact path="/email-list" component={EmailList} />
                            <PrivateRoute exact path="/email/register/host" component={RegisterHost} />
                            <PrivateRoute exact path="/email/host" component={EmailHostList} />
                            <PrivateRoute exact path="/news-letter/newsLetterEmail-list" component={NewsEmailList}></PrivateRoute>
                            <PrivateRoute exact path="/news-letter/create" component={CreateNewsLetter}></PrivateRoute>
                            <PrivateRoute exact path="/news-letter/subscriber-list" component={SubscriberList}></PrivateRoute>
                            <PrivateRoute exact path="/alarm/create" component={CreateAlarms}></PrivateRoute>
                            <PrivateRoute exact path="/alarm/alarm-list" component={AlarmList}></PrivateRoute>
                            <PrivateRoute exact path="/alarm/alarm-project-list" component={AlarmProjectList}></PrivateRoute>
                            <PrivateRoute exact path="/alarm/alarm-configuration" component={AlarmConfiguration}></PrivateRoute>
                            <PrivateRoute exact path="/alarm/configure_user-list-for-recevier-alarm" component={AlarmConfigList}></PrivateRoute>
                            <PrivateRoute exact path="/permission/role" component={CreateRole}></PrivateRoute>
                            <PrivateRoute exact path="/permission/staff-user" component={CreateStaffUser}></PrivateRoute>
                            <PrivateRoute exact path="/permission/role-list" component={RoleList}></PrivateRoute>
                            <PrivateRoute exact path="/accounts/list" component={PlatformUserList}></PrivateRoute>
                        </Switch>
                        <Footer />
                    </div>
                </div>
            </BrowserRouter>
        </Provider>
    );
}

export default App;

