import { combineReducers } from 'redux';
import { authReducer } from './auth';

import { projectReducer } from "./projects"
import { emailReducer } from "./email"
import { commonReducer } from "./commonReducer"

const reducer = combineReducers({
    authReducer: authReducer,
    projectReducer: projectReducer,
    commonReducer: commonReducer,
    emailReducer: emailReducer


});

export default reducer;