import React, { Component } from "react";
import { connect } from 'react-redux';
import { registerEmailHost } from "../../../api/email/email";
import { toastMessageSuccess } from "../../../utils/toastMessage";

class RegisterHost extends Component {

    state = {
        data: {
            email: '',
            password: '',
            host: '',
            port: '',
            use_tls: true,
            use_ssl: false
        },
        isError: false,
        disable:false,
        resetDisable:true
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    onChange = (e) => {
        if(e.target.name === 'use_tls') return this.setState({ ...this.state, data: { ...this.state.data, use_tls: e.target.checked, use_ssl: this.state.data.use_ssl ? false : this.state.data.use_ssl }});
        if(e.target.name === 'use_ssl') return this.setState({ ...this.state, data: { ...this.state.data, use_ssl: e.target.checked, use_tls: this.state.data.use_tls ? false : this.state.data.use_tls }});
        
        this.setState({ ...this.state, data: { ...this.state.data, [e.target.name]: e.target.value } });
    }

    setUpAgain = async () => {
        this.setState({
            ...this.state,
            data: {
                email: '',
                password: '',
                host: '',
                port: '',
                use_tls: true,
                use_ssl: false

            },
            isError: false
        })
    }

    createNotification = async () => {

        if (this.state.data.email === '' ) return this.setState({ isError: true, message: 'Email is required!' });
        if (this.state.data.password === '') return this.setState({ isError: true, message: 'Password is required!' });
        if (this.state.data.host === '') return this.setState({ isError: true, message: 'Host is required!' });
        if (this.state.data.port === '') return this.setState({ isError: true, message: 'Port is required!' });
       
        this.setState({ isError: null, message: null, isLoading: true, disable: true, resetDisable: true });

        try {
            await registerEmailHost(this.state.data);

            this.setState({ isError: false, isLoading: false, message: "",disable:false,resetDisable: false });
            toastMessageSuccess("Email host registered successfully")
        }
        catch (err) {
            this.setState({ ...this.state, isError: true, isLoading: false, disable: false, resetDisable: false, message: err?.response?.data?.message ? err?.response?.data?.message : "Sorry! We are facing some network issues. Please try again later." });
        }
    }

    render() {

        return (
            <div className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Register Email Host</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Register Email Host</h4>
                                    <form>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Email *</label>
                                            <div className="col-lg-10">
                                                <input value={this.state.data.email} onChange={this.onChange} id="notificationname" name="email" type="text" className="form-control" placeholder="Enter email here" />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Password *</label>
                                            <div className="col-lg-10">
                                                <input required value={this.state.data.password} type="password" name="password" placeholder="Enter password here" className="form-control" onChange={this.onChange} />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Host *</label>
                                            <div className="col-lg-10">
                                                <input required value={this.state.data.host} name="host" placeholder="Enter host here" className="form-control" onChange={this.onChange} />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">Port *</label>
                                            <div className="col-lg-10">
                                                <input required value={this.state.data.port} name="port" placeholder="Enter port here" className="form-control" onChange={this.onChange} />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">TLS *</label>
                                            <div className="col-lg-10">
                                                <input required type="checkbox" name="use_tls" checked={this.state.data.use_tls} value={this.state.data.use_tls} onChange={this.onChange} />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="notificationname" className="col-form-label col-lg-2">SSL *</label>
                                            <div className="col-lg-10">
                                                <input required type="checkbox" name="use_ssl" checked={this.state.data.use_ssl} value={this.state.data.use_ssl} onChange={this.onChange} />
                                            </div>
                                        </div>
                                        {
                                            this.state.isError ?
                                                <div className="row mb-4">
                                                    <label htmlFor="notificationname" className="col-form-label col-lg-2"></label>
                                                    <div className="col-lg-10 alert alert-danger">
                                                        {this.state.message}
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </form>

                                    <div className="row justify-content-end">
                                        <div className="col-lg-10">
                                            <button disabled={this.state.disable} onClick={this.createNotification} type="submit" className="btn btn-primary">

                                                {this.state.isLoading === true ? <img alt="loading..." style={{ height: '20px', width: '20px', marginRight: '8px' }} src="/assets/images/spinner3.gif"></img> : null}
                                            Register Host</button>

                                            <button disabled={this.state.resetDisable} style={{ marginLeft: '20px' }} onClick={this.setUpAgain} type="submit" className="btn btn-primary">
                                                Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    allProjects: state.projectReducer.allProjects
})

export default connect(mapStateToProps)(RegisterHost)