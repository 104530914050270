import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment"
import isEmpty from "../../utils/isEmpty"
import LoadingOverlay from "../../Loader";
import { setAllNewsList } from "../../actions/email"
import { fetchAllNewsLetterEmail } from "../../api/email/email"
import config from "../../config/index";
import { toastMessageFailure } from "../../utils/toastMessage";

class NewsEmailList extends Component {

    state = {

        isLoading: true,
        disable: "",
        limit: config.limit,
        pageNumber: 1,
        EmailListModalShow: false,
        totalPages: 0,
        renderParams: {},
        isDataLoading: false

    }


    componentDidMount = async () => {
        this.getNewsletterEmail()
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.projectChange !== nextProps.projectChange) {
            this.getNewsletterEmail()
        }
    }
    getNewsletterEmail = async () => {
        try {
            this.setState({ isDataLoading: true });
            let { data } = await fetchAllNewsLetterEmail()
            this.props.setAllNewsList(data.data);
            this.setState({ isDataLoading: false });
        }
        catch (e) {
            if (e?.response?.status === 404) {
                localStorage.removeItem("jwtToken")
                window.location.replace("/login")
                return
            }
            toastMessageFailure("Fail to get email ist")
            this.setState({ isDataLoading: false });
        }

    }




    render() {

        return (
            <>
                <div className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">News letter Email List (This is our News letter Email List Page)</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {!isEmpty(this.props.allNewsList) ? (
                                this.props.allNewsList.map((item) => (
                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm mb-3 mt-1 me-4">
                                                        <span class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">{item.email_host}</span>
                                                    </div>
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="text-truncate font-size-20">{item.email_host}</h5>
                                                    </div>
                                                </div>
                                                <h5 className="font-size-16 mt-2">Subject: {item.subject}</h5>
                                                <div className="text-muted mt-2 row">
                                                    <div className="col-3">
                                                        <p className="mb-1"><i className="mdi mdi-chevron-right text-primary me-1" />Send Time</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">{moment(item.wts).format("YYYY-MM-DD HH:mm:ss")}</p>
                                                    </div>
                                                </div>
                                                <div className="row task-dates">
                                                    <div className="col-sm-4 col-6">
                                                        <div className="mt-3">
                                                            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary" /> Creation Date</h5>
                                                            <p className="text-muted mb-0">{moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-6">
                                                        <div className="mt-4">
                                                            <button onClick={() => {
                                                                window.open(`${config.notificationUrl}/v2/news-letters/get-html/${item.id}/`)

                                                            }} class="btn btn-outline-primary waves-effect waves-light">Preview</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : this.state.isDataLoading === true ?


                                <LoadingOverlay></LoadingOverlay>
                                : <label className="text-center text-secondary">No subscriber found!</label>}

                            {
                                this.state.totalPages > 1 && (
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        {
                                            this.state.pageNumber !== 1 && (
                                                <button style={{ marginRight: '20px' }} disabled={this.state.pageNumber === 1} type="button" className="btn btn-primary Submit mb-2" onClick={this.onPreviousBtnClick} >Previous</button>
                                            )
                                        }
                                        <button disabled={this.props.totalPages !== 0 ? (this.props.totalPages - this.state.pageNumber) === 0 : false} type="button" class="btn btn-secondary Cancel mb-2" onClick={this.onNextBtnClick}>Next</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => ({

    auth: state.authReducer,
    allEmail: state.emailReducer.allEmail,
    allNewsList: state.emailReducer.allNewsList,
    totalEmail: state.emailReducer.totalEmail,
    totalPages: state.emailReducer.totalPages,
    waitingFor: state.commonReducer.waitingFor,
    projectChange: state.projectReducer.projectChange
})

export default connect(mapStateToProps, { setAllNewsList })(NewsEmailList)






