import React from "react";
import Modal from "react-bootstrap/Modal";

class EmailListModal extends React.Component {
    render() {
        return (
            <Modal dialogClassName="SetPass"
                show={this.props.EmailListshowModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >


                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table project-list-table table-nowrap align-middle table-borderless">
                            <thead>
                                <tr>

                                    <th scope="col" style={{ width: '100px' }}>#</th>
                                    <th scope="col">Receiver</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Time</th>


                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td>Text</td>





                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="w-100 text-right">
                            <button type="button" className="btn btn-primary Submit m-0 mt-2" onClick={this.props.onCloseEmailListModal} >Close</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default EmailListModal;